import { Badge, Button, Hidden, IconButton, Menu, MenuItem } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { ArrowDropDownRounded } from '@material-ui/icons';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { IcBell, IcBrand, IcLogout, IcModalLogout, IcPassColor, IcUsernameColor, IlNoNotif } from '../../assets';
import { logout } from '../../store/actions/authAction';
import { responseInvite } from '../../store/actions/chatGroupAction';
import { acceptMatchFriend } from '../../store/actions/matchAction';
import { getDetailUser, getNotif, readNotif } from '../../store/actions/userAction';
import CardItemNotif from '../Card/CardItemNotif';
import MobileNavigation from '../navigation/MobileNavigation';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100vh'
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        backgroundColor: '#f09856'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    drawerPaper: {
        width: drawerWidth,
        border: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    drawerContainer: {
        overflow: 'auto',
        paddingLeft: 20,
        paddingRight: 20
    },
    content: {
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        paddingTop: 70,
        overflow: 'hidden',
        [theme.breakpoints.up('md')]: {
            paddingTop: '1.5rem !important',
            paddingBottom: '1.5rem !important'
        }
    },
    imgProfile: {
        width: 40,
        height: 40,
        borderRadius: 40,
        objectFit: 'cover',
        background: '#EBEAEA'
    },
    textName: {
        fontSize: 16,
        color: '#262F56'
    },
    textUserName: {
        fontSize: 12,
        color: '#262F56'
    },
    userInfo: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    }
}));

const Sidebar = ({
    children,
    user,
    getDetailUser,
    logout,
    notif,
    getNotif,
    readNotif,
    acceptMatchFriend,
    responseInviteGroup,
    isChat
}) => {
    const classes = useStyles();
    const history = useHistory();
    const path = window.location.pathname;
    const username = localStorage.getItem('username');

    const [openNotif, setOpenNotif] = useState(null);
    const [openMenu, setOpenMenu] = useState(null);

    useEffect(() => {
        getDetailUser();
        getNotif();
    }, [getDetailUser, getNotif]);

    const handleAccept = (matched_code) => {
        acceptMatchFriend({ matched_code, status: 1 }).then(() => getNotif());
    };

    const handleGroup = (group_code, status) => {
        responseInviteGroup({ group_code, status }).then(() => getNotif());
    };

    const handleLogout = () => {
        Swal.fire({
            text: 'Are you sure want to logout?',
            imageUrl: IcModalLogout,
            imageWidth: 92,
            imageHeight: 92,
            imageAlt: 'icon logout',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            confirmButtonColor: '#EBEAEA',
            cancelButtonColor: '#f09856'
        }).then((res) => {
            if (res.isConfirmed) {
                logout().then(() => history.push('/login'));
            }
        });
    };

    return (
        <Fragment>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar
                    position="fixed"
                    className={classes.appBar}
                    elevation={0}
                >
                    <div className="d-flex align-items-center justify-content-between py-2 container">
                        <Link to="/" className="text-decoration-none">
                            <figure className="m-0">
                                <img
                                    src={IcBrand}
                                    alt="icon-brand"
                                    height={55}
                                />
                            </figure>
                        </Link>
                        <Hidden smDown>
                            <div className="container container-menu d-flex align-items-center justify-content-center">
                                <div className="flex-1 text-center">
                                    <Link
                                        className={`px-4 ${
                                            path === '/profile/' + username
                                                ? 'active'
                                                : ''
                                        }`}
                                        to={'/profile/' + username}
                                    >
                                        Profile
                                    </Link>
                                </div>

                                <div className="flex-1 text-center">
                                    <Link
                                        className={`px-4 ${
                                            path === '/' ? 'active' : ''
                                        }`}
                                        to="/"
                                    >
                                        Home
                                    </Link>
                                </div>
                                <div className="flex-1 text-center">
                                    <Link
                                        className={`px-4 ${
                                            path === '/friends' ? 'active' : ''
                                        }`}
                                        to="/friends"
                                    >
                                        Friends
                                    </Link>
                                </div>
                            </div>
                        </Hidden>
                        <div className="d-flex align-items-center justify-content-between">
                            <Hidden smDown>
                                <IconButton
                                    onClick={(e) => {
                                        setOpenNotif(e.currentTarget);
                                        readNotif();
                                    }}
                                >
                                    <Badge
                                        badgeContent={
                                            notif?.isRead ? 0 : notif?.unread
                                        }
                                        color="error"
                                    >
                                        <img alt="notification" src={IcBell} />
                                    </Badge>
                                </IconButton>
                            </Hidden>
                            <Hidden mdUp>
                                <IconButton
                                    onClick={() => {
                                        history.push('/notification');
                                    }}
                                >
                                    <Badge
                                        badgeContent={
                                            notif?.isRead ? 0 : notif?.unread
                                        }
                                        color="error"
                                    >
                                        <img alt="notification" src={IcBell} />
                                    </Badge>
                                </IconButton>
                            </Hidden>
                            <Menu
                                anchorEl={openNotif}
                                keepMounted
                                open={Boolean(openNotif)}
                                onClose={() => setOpenNotif(null)}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                elevation={2}
                                className="modal-notif"
                            >
                                <div className="container-modal-notif pt-3">
                                    <div className="container-content mb-1">
                                        {notif?.unread ? (
                                            <Fragment>
                                                {notif?.data
                                                    .filter((item) => {
                                                        return item.notification_type !==
                                                            2
                                                            ? true
                                                            : item.matched_code !==
                                                                  '-';
                                                    })
                                                    ?.slice(0, notif?.unread)
                                                    .map((item) => (
                                                        <CardItemNotif
                                                            key={
                                                                item.notification_code
                                                            }
                                                            img={item.from_pict}
                                                            notif={
                                                                item.notification_desc
                                                            }
                                                            status={
                                                                item.notification_type
                                                            }
                                                            handleAccept={
                                                                handleAccept
                                                            }
                                                            matched_code={
                                                                item.matched_code
                                                            }
                                                            handleGroup={
                                                                handleGroup
                                                            }
                                                            group_code={
                                                                item.group_code
                                                            }
                                                        />
                                                    ))}
                                            </Fragment>
                                        ) : (
                                            <div className="d-flex align-items-center justify-content-center flex-column px-5">
                                                <img
                                                    src={IlNoNotif}
                                                    alt="icon notif"
                                                    width={100}
                                                />
                                                <h6>No Notification</h6>
                                            </div>
                                        )}
                                    </div>
                                    <button
                                        className="btn-notif py-2"
                                        onClick={() =>
                                            history.push('/notification')
                                        }
                                    >
                                        See More
                                    </button>
                                </div>
                            </Menu>
                            <Menu
                                anchorEl={openNotif}
                                keepMounted
                                open={Boolean(openNotif)}
                                onClose={() => {
                                    setOpenNotif(null);
                                }}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                elevation={2}
                                className="modal-notif"
                            >
                                <div className="container-modal-notif pt-3">
                                    <div className="container-content mb-1">
                                        {notif?.unread ? (
                                            <Fragment>
                                                {notif?.data
                                                    .filter((item) => {
                                                        return item.notification_type !==
                                                            2
                                                            ? true
                                                            : item.matched_code !==
                                                                  '-';
                                                    })
                                                    ?.slice(0, notif?.unread)
                                                    .map((item, idx) => (
                                                        <CardItemNotif
                                                            key={
                                                                item.notification_code
                                                            }
                                                            img={item.from_pict}
                                                            notif={
                                                                item.notification_desc
                                                            }
                                                            status={
                                                                item.notification_type
                                                            }
                                                            handleAccept={
                                                                handleAccept
                                                            }
                                                            matched_code={
                                                                item.matched_code
                                                            }
                                                            handleGroup={
                                                                handleGroup
                                                            }
                                                            group_code={
                                                                item.group_code
                                                            }
                                                        />
                                                    ))}
                                            </Fragment>
                                        ) : (
                                            <div className="d-flex align-items-center justify-content-center flex-column px-5">
                                                <img
                                                    src={IlNoNotif}
                                                    alt="icon notif"
                                                    width={100}
                                                />
                                                <h6>No Notification</h6>
                                            </div>
                                        )}
                                    </div>
                                    <button
                                        className="btn-notif py-2"
                                        onClick={() =>
                                            history.push('/notification')
                                        }
                                    >
                                        See More
                                    </button>
                                </div>
                            </Menu>
                            {/* {user ? (
                                <div
                                    className={'ml-3 mr-2 ' + classes.userInfo}
                                >
                                    <h6
                                        className={
                                            'm-0 font-weight-bold text-right ' +
                                            classes.textName
                                        }
                                    >
                                        {user?.fullname}
                                    </h6>
                                    <p
                                        className={
                                            'm-0 text-right ' +
                                            classes.textUserName
                                        }
                                    >
                                        {user?.username
                                            ? '@' + user?.username
                                            : ''}
                                    </p>
                                </div>
                            ) : (
                                <div className="d-flex flex-column align-items-end ml-3 mr-2">
                                    <Skeleton
                                        animation="wave"
                                        width={150}
                                        height={15}
                                    />
                                    <Skeleton
                                        animation="wave"
                                        width={100}
                                        height={13}
                                    />
                                </div>
                            )} */}
                            <Hidden smDown>
                                <Button
                                    className="btn-profile"
                                    onClick={(e) =>
                                        setOpenMenu(e.currentTarget)
                                    }
                                >
                                    <img
                                        alt={user?.fullname}
                                        src={user?.profile_pict}
                                        className={classes.imgProfile}
                                    />
                                    <ArrowDropDownRounded />
                                </Button>
                            </Hidden>

                            <Menu
                                anchorEl={openMenu}
                                keepMounted
                                open={Boolean(openMenu)}
                                onClose={() => setOpenMenu(null)}
                                getContentAnchorEl={null}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center'
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right'
                                }}
                                elevation={2}
                            >
                                {user && (
                                    <div
                                        className={
                                            'pl-4 pr-5 border-bottom pb-2 mb-2 ' +
                                            classes.userInfo
                                        }
                                    >
                                        <h6
                                            className={
                                                'm-0 font-weight-bold ' +
                                                classes.textName
                                            }
                                        >
                                            {user?.fullname}
                                        </h6>
                                        <p
                                            className={
                                                'm-0 ' + classes.textUserName
                                            }
                                        >
                                            {user?.username
                                                ? '@' + user?.username
                                                : ''}
                                        </p>
                                    </div>
                                )}
                                <MenuItem
                                    onClick={() =>
                                        history.replace(`/edit_profile`)
                                    }
                                    className="pl-4 pr-5"
                                >
                                    <img
                                        src={IcUsernameColor}
                                        alt="icon edit"
                                        className="mr-2"
                                    />
                                    Edit Profile
                                </MenuItem>
                                <MenuItem
                                    onClick={() =>
                                        history.push(`/edit_password`)
                                    }
                                    className="pl-4 pr-5"
                                >
                                    <img
                                        src={IcPassColor}
                                        alt="icon edit"
                                        className="mr-2"
                                    />
                                    Edit Password
                                </MenuItem>
                                <MenuItem
                                    onClick={handleLogout}
                                    className="pl-4 pr-5"
                                >
                                    <img
                                        src={IcLogout}
                                        alt="icon edit"
                                        className="mr-2"
                                    />
                                    Logout
                                </MenuItem>
                            </Menu>
                        </div>
                    </div>
                </AppBar>
                <main className={classes.content + ''}>
                    <Hidden smDown>
                        <Toolbar />
                    </Hidden>
                    {children}
                </main>
            </div>
            {isChat ? '' : <MobileNavigation />}
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        notif: state?.user?.notif
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDetailUser: () => dispatch(getDetailUser()),
        logout: () => dispatch(logout()),
        getNotif: () => dispatch(getNotif()),
        readNotif: () => dispatch(readNotif()),
        acceptMatchFriend: (params) => dispatch(acceptMatchFriend(params)),
        responseInviteGroup: (params) => dispatch(responseInvite(params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
