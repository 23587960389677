import CryptoJS from 'crypto-js';
import AES from 'crypto-js/aes';

const KEY = 'BuddyMed2021AlanCreative';

export const encryptAes = (string) => {
    let result = AES.encrypt(string, KEY).toString();
    return result;
};

export const decryptAes = (string) => {
    let dec = AES.decrypt(string, KEY);
    let result = dec.toString(CryptoJS.enc.Utf8);
    return result;
};
