import { ThemeProvider } from '@material-ui/styles';
import { useEffect } from 'react';
import { Route, BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import history from './history';
import { persist, store } from './store';
import Routes from './routes';
import theme from './theme';
import { API } from './config/Api';

const App = () => {
    useEffect(() => {
        const getIpAddress = async () => {
            const res = await fetch('https://api.ipify.org?format=json');
            const { ip } = await res.json();
            return ip;
        }

        const trackVisitor = async () => {
            const visitorData = {
                ip_address: await getIpAddress(),
                user_agent: navigator.userAgent,
            };

            await API.post('apps/visitor/add', visitorData);
        }

        window.addEventListener('load', trackVisitor);

        return () => {
            window.removeEventListener('load', trackVisitor);
        }
    }, [])

    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persist}>
                <ThemeProvider theme={theme}>
                    <Router>
                        <Route history={history}>
                            <Routes />
                        </Route>
                    </Router>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    );
};

export default App;
