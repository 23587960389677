import { AppBar, Badge, Hidden, IconButton, Menu, Toolbar } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { IcBell, IlNoNotif } from '../../assets';
import { logout } from '../../store/actions/authAction';
import { responseInvite } from '../../store/actions/chatGroupAction';
import { acceptMatchFriend } from '../../store/actions/matchAction';
import { getDetailUser, getNotif, readNotif } from '../../store/actions/userAction';
import CardItemNotif from '../Card/CardItemNotif';

const useStyles = makeStyles((theme) => ({
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        background: '#fff'
    },
    imgProfile: {
        width: 40,
        height: 40,
        borderRadius: 40,
        objectFit: 'cover'
    },
    textName: {
        fontSize: 16,
        color: '#262F56'
    },
    textUserName: {
        fontSize: 12,
        color: '#262F56'
    },
    userInfo: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block'
        }
    }
}));

const Navs = ({
    user,
    logout,
    notif,
    getNotif,
    readNotif,
    acceptMatchFriend,
    getDetailUser,
    responseInviteGroup
}) => {
    const classes = useStyles();
    const history = useHistory();
    const [openNotif, setOpenNotif] = useState(null);

    useEffect(() => {
        getDetailUser();
        getNotif();
    }, [getDetailUser, getNotif]);

    const handleAccept = (matched_code) => {
        acceptMatchFriend({ matched_code, status: 1 }).then(() => getNotif());
    };

    const handleGroup = (group_code, status) => {
        responseInviteGroup({ group_code, status }).then(() => getNotif());
    };

    return (
        <AppBar position="fixed" className={classes.appBar} elevation={0}>
            <Toolbar className="d-flex align-items-center justify-content-between py-2">
                <h4 className="font-weight-bold m-0">BuddyMed</h4>
                <div className="d-flex align-items-center justify-content-between">
                    <Hidden smDown>
                        <IconButton
                            onClick={(e) => {
                                setOpenNotif(e.currentTarget);
                                readNotif();
                            }}
                        >
                            <Badge
                                badgeContent={notif?.isRead ? 0 : notif?.unread}
                                color="error"
                            >
                                <img alt="notification" src={IcBell} />
                            </Badge>
                        </IconButton>
                    </Hidden>
                    <Hidden mdUp>
                        <IconButton
                            onClick={() => {
                                history.push('/notification');
                            }}
                        >
                            <Badge
                                badgeContent={notif?.isRead ? 0 : notif?.unread}
                                color="error"
                            >
                                <img alt="notification" src={IcBell} />
                            </Badge>
                        </IconButton>
                    </Hidden>
                    <Menu
                        anchorEl={openNotif}
                        keepMounted
                        open={Boolean(openNotif)}
                        onClose={() => setOpenNotif(null)}
                        getContentAnchorEl={null}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        elevation={2}
                        className="modal-notif"
                    >
                        <div className="container-modal-notif pt-3">
                            <div className="container-content mb-1">
                                {notif?.unread ? (
                                    <Fragment>
                                        {notif?.data
                                            .filter((item) => {
                                                return item.notification_type !==
                                                    2
                                                    ? true
                                                    : item.matched_code !== '-';
                                            })
                                            ?.slice(0, notif?.unread)
                                            .map((item) => (
                                                <CardItemNotif
                                                    key={item.notification_code}
                                                    img={item.from_pict}
                                                    notif={
                                                        item.notification_desc
                                                    }
                                                    status={
                                                        item.notification_type
                                                    }
                                                    handleAccept={handleAccept}
                                                    matched_code={
                                                        item.matched_code
                                                    }
                                                    handleGroup={handleGroup}
                                                    group_code={item.group_code}
                                                />
                                            ))}
                                    </Fragment>
                                ) : (
                                    <div className="d-flex align-items-center justify-content-center flex-column px-5">
                                        <img
                                            src={IlNoNotif}
                                            alt="icon notif"
                                            width={100}
                                        />
                                        <h6>No Notification</h6>
                                    </div>
                                )}
                            </div>
                            <button
                                className="btn-notif py-2"
                                onClick={() => history.push('/notification')}
                            >
                                See More
                            </button>
                        </div>
                    </Menu>
                </div>
            </Toolbar>
        </AppBar>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user.user,
        notif: state?.user?.notif
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDetailUser: () => dispatch(getDetailUser()),
        logout: () => dispatch(logout()),
        getNotif: () => dispatch(getNotif()),
        readNotif: () => dispatch(readNotif()),
        acceptMatchFriend: (params) => dispatch(acceptMatchFriend(params)),
        responseInviteGroup: (params) => dispatch(responseInvite(params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navs);
