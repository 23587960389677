import {
    GET_CITY,
    GET_COUNTRY,
    GET_HOBBY,
    GET_LEARN_TYPE,
    GET_MOVIE_GENRE,
    GET_MUSIC_TYPE,
    GET_NEW_TOPIC,
    GET_TOPIC,
    GET_UNIV,
} from '../constant';

const initialState = {
    country: [],
    city: [],
    topic: [],
    newTopic: [],
    univ: [],
    learnType: [],
    hobby: [],
    musicType: [],
    movieGenre: []
};

const appReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${GET_COUNTRY}_PENDING`:
            return {
                ...state,
                country: []
            };
        case `${GET_COUNTRY}_FULFILLED`:
            return {
                ...state,
                country: action.payload
            };
        case `${GET_COUNTRY}_REJECTED`:
            return {
                ...state,
                country: []
            };
        case `${GET_CITY}_PENDING`:
            return {
                ...state,
                city: []
            };
        case `${GET_CITY}_FULFILLED`:
            return {
                ...state,
                city: action.payload
            };
        case `${GET_CITY}_REJECTED`:
            return {
                ...state,
                city: []
            };
        case `${GET_TOPIC}_PENDING`:
            return {
                ...state,
                topic: []
            };
        case `${GET_TOPIC}_FULFILLED`:
            return {
                ...state,
                topic: action.payload
            };
        case `${GET_TOPIC}_REJECTED`:
            return {
                ...state,
                topic: []
            };
        case `${GET_NEW_TOPIC}_PENDING`:
            return {
                ...state,
                newTopic: []
            };
        case `${GET_NEW_TOPIC}_FULFILLED`:
            return {
                ...state,
                newTopic: action.payload
            };
        case `${GET_NEW_TOPIC}_REJECTED`:
            return {
                ...state,
                newTopic: []
            };
        case `${GET_UNIV}_PENDING`:
            return {
                ...state,
                univ: []
            };
        case `${GET_UNIV}_FULFILLED`:
            return {
                ...state,
                univ: action.payload
            };
        case `${GET_UNIV}_REJECTED`:
            return {
                ...state,
                univ: []
            };
        case `${GET_LEARN_TYPE}_PENDING`:
            return {
                ...state,
                learnType: []
            };
        case `${GET_LEARN_TYPE}_FULFILLED`:
            return {
                ...state,
                learnType: action.payload
            };
        case `${GET_LEARN_TYPE}_REJECTED`:
            return {
                ...state,
                learnType: []
            };
        case `${GET_HOBBY}_PENDING`:
            return {
                ...state,
                hobby: []
            };
        case `${GET_HOBBY}_FULFILLED`:
            return {
                ...state,
                hobby: action.payload
            };
        case `${GET_HOBBY}_REJECTED`:
            return {
                ...state,
                hobby: []
            };
        case `${GET_MUSIC_TYPE}_PENDING`:
            return {
                ...state,
                musicType: []
            };
        case `${GET_MUSIC_TYPE}_FULFILLED`:
            return {
                ...state,
                musicType: action.payload
            };
        case `${GET_MUSIC_TYPE}_REJECTED`:
            return {
                ...state,
                musicType: []
            };
        case `${GET_MOVIE_GENRE}_PENDING`:
            return {
                ...state,
                movieGenre: []
            };
        case `${GET_MOVIE_GENRE}_FULFILLED`:
            return {
                ...state,
                movieGenre: action.payload
            };
        case `${GET_MOVIE_GENRE}_REJECTED`:
            return {
                ...state,
                movieGenre: []
            };
        default:
            return state;
    }
};

export default appReducer;
