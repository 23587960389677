import {
    ADD_CONF,
    CONFERENCE,
    DELETE_CONF,
    EDIT_CONF,
    ADD_CONF_USER
} from '../constant';

const initialState = {
    data: null,
    add_conf: {},
    add_conf_user: {},
    edit_conf: {},
    delete_conf: {}
};

const confReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${ADD_CONF}_PENDING`:
            return {
                ...state,
                add_conf: {}
            };
        case `${ADD_CONF}_FULFILLED`:
            return {
                ...state,
                add_conf: action.payload
            };
        case `${ADD_CONF}_REJECTED`:
            return {
                ...state,
                add_conf: {}
            };

        case `${ADD_CONF_USER}_PENDING`:
            return {
                ...state,
                add_conf_user: {}
            };
        case `${ADD_CONF_USER}_FULFILLED`:
            return {
                ...state,
                add_conf_user: action.payload
            };
        case `${ADD_CONF_USER}_REJECTED`:
            return {
                ...state,
                add_conf_user: {}
            };

        case `${EDIT_CONF}_PENDING`:
            return {
                ...state,
                edit_conf: {}
            };
        case `${EDIT_CONF}_FULFILLED`:
            return {
                ...state,
                edit_conf: action.payload
            };
        case `${EDIT_CONF}_REJECTED`:
            return {
                ...state,
                edit_conf: {}
            };

        case `${CONFERENCE}_PENDING`:
            return {
                ...state,
                data: null
            };
        case `${CONFERENCE}_FULFILLED`:
            return {
                ...state,
                data: action.payload
            };
        case `${CONFERENCE}_REJECTED`:
            return {
                ...state,
                data: null
            };

        case `${DELETE_CONF}_PENDING`:
            return {
                ...state,
                delete_conf: {}
            };
        case `${DELETE_CONF}_FULFILLED`:
            return {
                ...state,
                delete_conf: action.payload
            };
        case `${DELETE_CONF}_REJECTED`:
            return {
                ...state,
                delete_conf: {}
            };

        default:
            return state;
    }
};

export default confReducer;
