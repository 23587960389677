import { createTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createTheme({
    palette: {
        primary: {
            main: '#f09856'
        },
        secondary: {
            main: '#f5f0dd'
        },
        error: {
            main: '#eb5757'
        },
        background: {
            default: '#fff'
        }
    },
    overrides: {
        MuiOutlinedInput: {
            root: {
                backgroundColor: '#fff'
            },
            input: {
                fontSize: 15,
                padding: 16,
                fontFamily: '"Inter", sans-serif'
            }
        },
        MuiButton: {
            root: {
                textTransform: 'none !important'
            }
        }
    }
});

export default theme;
