import { API, setAuthToken } from '../../config/Api';
import {
    GET_AVATAR_LIST,
    GET_CITY,
    GET_COUNTRY,
    GET_EXAM_TYPE,
    GET_HOBBY,
    GET_LEARN_TYPE,
    GET_MOVIE_GENRE,
    GET_MUSIC_TYPE,
    GET_NEW_TOPIC,
    GET_SUGGEST_TOPIC,
    GET_TOPIC,
    GET_UNIV,
} from '../constant';

export const getCountry = () => {
    return {
        type: GET_COUNTRY,
        payload: async () => {
            const res = await API.get('apps/country').catch((error) =>
                Promise.reject(error)
            );

            return res.data?.data;
        }
    };
};

export const getCity = (code) => {
    return {
        type: GET_CITY,
        payload: async () => {
            const res = await API.get(`apps/city?country_code=${code}`).catch(
                (error) => Promise.reject(error)
            );

            return res.data?.data;
        }
    };
};

export const getTopic = () => {
    return {
        type: GET_TOPIC,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);
            const res = await API.get(`user/topic`).catch((error) =>
                Promise.reject(error)
            );

            return res.data?.data;
        }
    };
};

export const getNewTopic = () => {
    return {
        type: GET_NEW_TOPIC,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);
            const res = await API.get(`user/topic/new`).catch((error) =>
                Promise.reject(error)
            );

            return res.data?.data;
        }
    };
};

export const getSuggestTopic = (params) => {
    return {
        type: GET_SUGGEST_TOPIC,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);
            const res = await API.get(
                `user/topic/suggestion?limit=${params?.limit}&offset=${params?.offset}`
            ).catch((error) => Promise.reject(error));

            return res.data?.data;
        }
    };
};

export const getUniv = (params) => {
    return {
        type: GET_UNIV,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);
            const res = await API.get(
                `apps/university?city_code=${params}`
            ).catch((error) => Promise.reject(error));

            return res.data?.data;
        }
    };
};

export const getLearnType = () => {
    return {
        type: GET_LEARN_TYPE,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);
            const res = await API.get(`apps/learner_type`).catch((error) =>
                Promise.reject(error)
            );
            return res.data?.data;
        }
    };
};

export const getHobby = () => {
    return {
        type: GET_HOBBY,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);
            const res = await API.get(`apps/hobbies`).catch((error) =>
                Promise.reject(error)
            );
            return res.data?.data;
        }
    };
};

export const getMusicType = () => {
    return {
        type: GET_MUSIC_TYPE,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);
            const res = await API.get(`apps/music_type`).catch((error) =>
                Promise.reject(error)
            );
            return res.data?.data;
        }
    };
};

export const getMovieGenre = () => {
    return {
        type: GET_MOVIE_GENRE,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);
            const res = await API.get(`apps/movie_genre`).catch((error) =>
                Promise.reject(error)
            );
            return res.data?.data;
        }
    };
};

export const getExamType = (type) => {
    return {
        type: GET_EXAM_TYPE,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);
            const res = await API.get(`apps/exam/${type}`).catch((error) =>
                Promise.reject(error)
            );
            return res.data?.data;
        }
    };
};

export const getAvatarList = () => {
    return {
        type: GET_AVATAR_LIST,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);
            const res = await API.get(`apps/profile`).catch((error) =>
                Promise.reject(error)
            );
            return res.data?.data;
        }
    };
};
