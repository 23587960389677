import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Kebijakan = () => {
    useEffect(() => {
        document.title = 'Saleem App | Kebijakan Verifikasi';
    }, []);
    return (
        <Container className=" d-flex align-items-center flex-column container-not-found">
            <p>
                This Privacy Policy has been created by BuddyMed to protect and
                maintain the privacy of the users of our Website or App
                (BuddyMed.com). We have provided you with this policy statement
                to inform you of the kinds of information we may gather during
                your visit to the BuddyMed’s website (the “Website”) or the
                mobile device application (the “App”), why we gather customer
                information, what we use the information for, under what
                circumstances we may disclose such information, and how you can
                instruct us to limit the use of that information.
            </p>

            <p>
                This Privacy Policy is an integral part of our Terms and
                Conditions. If there is any difference between this Privacy
                Policy and our Terms and Conditions with regard to privacy
                matters, the provisions of this Privacy Policy shall prevail.
            </p>

            <p>
                Please note that in order to use our Services, you must be 18
                years old or older. If you are under 18 years old, you will need
                permission from your parents or legal guardians.
            </p>

            <div>
                <div class="elementor-widget-container">
                    <div class="elementor-text-editor elementor-clearfix">
                        <p>
                            This Privacy Policy has been created by PT ALAN
                            MEDIATECH INDONESIA (the “Company”, “we”, “us”,
                            “our”, “ALAN”, or “TemanPasar”) to protect and
                            maintain the privacy of the users of our Website or
                            App (Temanpasar.com). We have provided you with this
                            policy statement to inform you of the kinds of
                            information we may gather during your visit to the
                            TemanPasar’s website (the “Website”) or the mobile
                            device application (the “App”), why we gather
                            customer information, what we use the information
                            for, under what circumstances we may disclose such
                            information, and how you can instruct us to limit
                            the use of that information.
                        </p>
                        <p>
                            This Privacy Policy is an integral part of our Terms
                            and Conditions. If there is any difference between
                            this Privacy Policy and our Terms and Conditions
                            with regard to privacy matters, the provisions of
                            this Privacy Policy shall prevail.
                        </p>
                        <p>
                            Please note that in order to use our Services, you
                            must be 18 years old or older. If you are under 18
                            years old, you will need permission from your
                            parents or legal guardians.
                        </p>
                        <ol>
                            <li>
                                <strong>Data collected on this website</strong>
                                <ul>
                                    <li>IP Address:</li>
                                </ul>
                            </li>
                        </ol>
                        <p>
                            When visiting the Website, the IP address of the
                            user’s computer is registered to the TemanPasar
                            online system. The IP address is the numerical
                            address of the computer used when visiting the
                            Website. The IP address is registered to enable
                            TemanPasar to trace the used computer in case of
                            misuse or unlawful actions in connection with the
                            visit on or use of the Website. Further, the IP
                            address is used to derive your approximate location
                            (city level).
                        </p>
                        <ul>
                            <li>Type of device you are using</li>
                        </ul>
                        <p>
                            E.g. Computer, mobile device etc., including the
                            operating system you use.
                        </p>
                        <ul>
                            <li>Type of web browser you are using</li>
                        </ul>
                        <p>
                            E.g. Google Chrome, Mozilla Firefox, Safari,
                            Internet Explorer, etc
                        </p>
                        <ul>
                            <li>Personal Information</li>
                        </ul>
                        <p>
                            In order to be able to process your order that you
                            place using our Website or App, we will require you
                            to provide us with a set of personal information
                            that personally identifies you and your delivery
                            location. We receive and store all information that
                            you enter anywhere on the Website or App. This input
                            includes:
                        </p>
                        <ul>
                            <li>Registration forms:</li>
                        </ul>
                        <p>
                            Information including, but not limited to: Name,
                            Address, Telephone Number, E-mail Address.
                        </p>
                        <ul>
                            <li>Order forms:</li>
                        </ul>
                        <p>
                            Information including, but not limited to: Credit
                            Card number, Expiration Date, and Billing Address.
                        </p>
                        <ul>
                            <li>Delivery Information;</li>
                        </ul>
                        <p>
                            Information including, but not limited to: Name,
                            Address, Telephone Number, Special delivery
                            instructions.
                        </p>
                        <ul>
                            <li>Customer Information:</li>
                        </ul>
                        <p>
                            Information including, but not limited to: Products
                            Ordered, Quantity of Order, Relevant promotional
                            codes.
                        </p>
                        <ul>
                            <li>Other customer information:</li>
                        </ul>
                        <p>
                            If you communicate with us by e-mail, or otherwise
                            complete online forms, surveys, or contest entries,
                            any information provided in such communication may
                            be collected as Personal Information.
                        </p>
                        <p>
                            This information is collectively referred to as
                            “Personal Information.” You may choose not to
                            provide us with certain Personal Information. In
                            such an event, you can still access and view much of
                            the Website or App; however, you may not be able to
                            order any products. In addition, you can choose not
                            to provide certain optional information, but then
                            you might not be able to take full advantage of many
                            of the features on the Website or App.
                        </p>
                        <ol start="2">
                            <li>
                                <strong>
                                    How we use your personal information
                                </strong>
                            </li>
                        </ol>
                        <p>
                            We use your Personal Information for such purposes
                            as responding to your requests, processing and
                            filling customer orders, verifying your
                            qualifications for certain products and services,
                            billing, improving our services, providing an
                            enhanced and more personalized shopping experience,
                            communicating with you, and informing you of special
                            offers and discounts. From time to time, we may also
                            use your Personal Information to send you free
                            samples of new products or product brands different
                            from those you usually order. However, if you wish
                            to “opt out” from this feature, please see Section
                            10 of this Privacy Policy.
                        </p>
                        <p>
                            By accepting this Privacy Policy, you understand and
                            agree that TemanPasar may provide your Personal
                            Information to third parties to the extent necessary
                            or desirable to fill your order or complete your
                            transaction. For example, we may use a third party
                            supplier for certain goods, an outside shipping
                            company to ship orders, and we do as a matter of
                            course use a credit card processing company to bill
                            users for goods and services. We may also share your
                            Personal Information as described in Sections 5 and
                            12 below. Except as provided above, and in Sections
                            5 and 12 below, we will not release your Personal
                            Information to a third party unless you have granted
                            us permission to do so.
                        </p>
                        <ol start="3">
                            <li>
                                <strong>Sharing of Personal Information</strong>
                            </li>
                        </ol>
                        <p>
                            In addition to Section 2 above, we share demographic
                            information with our partners and advertisers on an
                            anonymous and aggregate basis. This type of data is
                            not readily linked to any personally identifiable
                            information. We work closely with third parties to
                            bring you our services. We share information when
                            another company is involved in the transaction so
                            that company can perform its functions.
                        </p>
                        <ol start="4">
                            <li>
                                <strong>Data controller</strong>
                                <ul>
                                    <li>
                                        1. The information for which TemanPasar
                                        is the data controller, TemanPasar shall
                                        be the data controller of the master
                                        data you enter in connection with your
                                        creation of a profile or registration
                                        for newsletters, i.e. your name, your
                                        password and your email address, as well
                                        as registration of your IP address.
                                        Furthermore, TemanPasar shall be the
                                        data controller of the information which
                                        is disclosed to other services.
                                    </li>
                                    <li>
                                        2. The information for which you are the
                                        data controller, You shall be data
                                        controller of the content you choose to
                                        disclose on the Website and for the data
                                        disclosed on your profile on Social
                                        Networks, which is the consequence of
                                        the connection of your profile on the
                                        Website with your profile on the Social
                                        Network.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Data processor</strong>
                            </li>
                        </ol>
                        <p>
                            TemanPasar may exclusively use the service provided
                            by an external company for maintaining the technical
                            operation, security, confidentiality of personal
                            data contained in the Website. This company is the
                            data processor with regards to the personal data for
                            which TemanPasar is the data controller.
                        </p>
                        <p>
                            By accepting this Privacy Policy, You give your
                            personal consent to TemanPasar in allowing the data
                            for which you are the data controller be processed
                            by the same data processor.
                        </p>
                        <p>
                            The data processor shall solely act according to
                            instructions from TemanPasar. By accepting these
                            terms and conditions, you also give your consent to
                            TemanPasar to give such instructions to the data
                            processor which are necessary for the processing of
                            data in accordance with this personal data policy
                            and for the purpose of use of the Website.The data
                            processor has made necessary technical and
                            organizational safety measures against the
                            information being accidentally or illegally
                            destroyed, lost or deteriorated, and against the
                            information coming to the knowledge of unauthorized
                            persons, is misused or in other ways is processed in
                            violation with the act on processing of personal
                            data. On your request – and against remuneration of
                            the data processor’s current hourly rates at any
                            time for such work – the data processor shall supply
                            you with sufficient information to demonstrate that
                            the above-mentioned technical and organizational
                            safety measures have been made. In such situation,
                            TemanPasar will facilitate the request to the data
                            processor.
                        </p>
                        <ol start="6">
                            <li>
                                <strong>Security measures</strong>
                            </li>
                        </ol>
                        <p>
                            TemanPasar will use its reasonable efforts to ensure
                            that recorded data, including personal information,
                            credit card data, password and any confidential
                            information, will not be disclosed, transferred,
                            given to, or illegally used by unauthorized persons.
                            In connection with this, TemanPasar will regularly
                            audit its system in order to prevent possible
                            vulnerabilities and attacks. However, since the
                            internet is not a 100% secure environment,
                            TemanPasar cannot, from time to time, ensure or
                            warrant the security of information you transmit to
                            TemanPasar. Information sent via the Website are not
                            encrypted, and TemanPasar therefore advises you to
                            prudentially communicate any confidential
                            information through these means.
                        </p>
                        <ol start="7">
                            <li>
                                <strong>
                                    This website uses cookies and in the
                                    following we describe what cookies we use
                                    and for what purposes. By using our website
                                    you accept that we use cookies as described
                                    in the following.
                                </strong>
                                <ul>
                                    <li>1. What is a cookie?</li>
                                </ul>
                            </li>
                        </ol>
                        <p>
                            Cookies are small information units which the
                            Website places on your computer’s hard disk, on your
                            tablet or on your smartphone. Cookies contain
                            information that the Website uses to make the
                            communication between you and your web browser more
                            efficient. The cookie does not identify you as an
                            individual user but identifies your computer.
                        </p>
                        <p>
                            There are two types of cookies – session cookies and
                            persistent cookies. Session cookies are temporary
                            bits of information which are deleted when you exit
                            your web browser. Persistent cookies are more
                            permanent bits of information that are stored and
                            remain on your computer until they are deleted.
                            Persistent cookies delete themselves after a certain
                            period of time but are renewed each time you visit
                            the website. The Website uses session cookies and
                            persistent cookies.
                        </p>
                        <ul>
                            <li>
                                What type of cookies do we use and for what
                                purposes? We use cookies for
                                <ul>
                                    <li>Statistics:</li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            Measuring Website traffic i.e. number of visits on
                            the Website, which domains the visitors come from,
                            which pages the visitors visit on the Website and in
                            which overall geographical area the visitors are
                            located.
                        </p>
                        <ul>
                            <li>
                                Improvement of the functionalities of the
                                Website:
                            </li>
                        </ul>
                        <p>
                            Optimizing your experience with the Website, which
                            includes remembering your user ID and password when
                            you return to the Website so you do not have to log
                            in again.
                        </p>
                        <ul>
                            <li>Connect with Social Media</li>
                        </ul>
                        <p>
                            We give you the possibility of connecting with
                            Social Media, such as Facebook.
                        </p>
                        <ul>
                            <li>Third party cookies</li>
                        </ul>
                        <p>
                            Our Website use cookies from the following third
                            parties:
                        </p>
                        <ul>
                            <li>
                                Google Analytics: for statistical purposes. You
                                can decline cookies from Google Analytics by
                                clicking on this link:
                            </li>
                        </ul>
                        <p>
                            <a href="http://tools.google.com/dlpage/gaoptout">
                                http://tools.google.com/dlpage/gaoptout
                            </a>
                        </p>
                        <ul>
                            <li>
                                Facebook: Set by Facebook only if you interact
                                with the Facebook plugin or are already logged
                                into Facebook from other source for the purpose
                                of connecting and integrating with them.
                            </li>
                            <li>
                                Twitter: Set by Twitter only if you interact
                                with the Twitter plugin or are already logged
                                into Twitter from other source for the purpose
                                of connecting and integrating with them.
                            </li>
                            <li>
                                Google+: Set by Google only if you interact with
                                the Google+ plugin or are already logged into
                                Google from other source for the purpose of
                                connecting with them.
                            </li>
                        </ul>
                        <ul>
                            <li>Deletion of cookies</li>
                        </ul>
                        <p>
                            If you want to delete the cookies already on your
                            equipment, you can, if you use a PC or a more recent
                            browser, press CTRL + SHIFT + DELETE simultaneously.
                            If the shortcuts do not work on your browser, please
                            visit the support page for the browser in question.
                            Please note that our Website will not work in its
                            full extend if you delete the cookies.
                        </p>
                        <ol start="8">
                            <li>
                                <strong>Lost or Stolen Information</strong>
                            </li>
                        </ol>
                        <p>
                            You must promptly contact us through our Customer
                            Service at +62 21 75924733 and/or send an email to
                            support.indonesia@TemanPasar.com if your credit
                            card, user name, or password is lost, stolen, or
                            used without permission. In such an event, we will
                            note that you have cancelled your credit card
                            number, user name, or password in our system and
                            will update our records accordingly.
                        </p>
                        <ol start="9">
                            <li>
                                <strong>Access, correction and deletion</strong>
                                <ul>
                                    <li>Access</li>
                                </ul>
                            </li>
                        </ol>
                        <p>
                            Upon written request to TemanPasar via the contact
                            information stated in section 10, TemanPasar shall
                            inform you of the purpose of the processing; who
                            receives the information and from where the
                            information originates. TemanPasar will provide the
                            above mentioned as soon as reasonably possible.
                        </p>
                        <ul>
                            <li>Correction and deletion</li>
                        </ul>
                        <p>
                            If you discover that the information which
                            TemanPasar as the data controller process on you is
                            incorrect or misleading, TemanPasar shall make the
                            correction hereof on your request. We recommend that
                            you – if possible – make the correction of such
                            errors yourself.
                        </p>
                        <p>
                            You may at any time correct or delete any content
                            and information on the Website for which you are the
                            data controller, cf. Section 4.2. If your personal
                            data change, or if you no longer wish to appear on
                            the Website, you may update or delete the
                            information by logging on to your user profile.
                        </p>
                        <p>
                            When your profile is deleted, all the data
                            associated with your user profile is deleted.
                        </p>
                        <p>&nbsp;</p>
                        <ol start="10">
                            <li>
                                <strong>
                                    How to limit the use of your information
                                </strong>
                            </li>
                        </ol>
                        <p>
                            Our users are given the opportunity to “opt out” of
                            having their information used for purposes not
                            directly related to placement, processing,
                            fulfillment or delivery of a product order at the
                            point where we ask for the information. If you
                            prefer to “opt out” and not have us send you
                            material that we think may be of interest to you,
                            such as product information, product samples, and
                            promotional mailings/e-mails from us and sites and
                            companies we own, then you can notify us in one of
                            the following two ways:
                        </p>
                        <ul>
                            <li>Writing to us at:</li>
                        </ul>
                        <p>PT ALAN MEDIATECH INDONESIA</p>
                        <p>
                            Perumahan Graha Mandiri No. 1, Jl. Tugu Raya,
                            Kelurahan Tugu, Cimanggis, Depok, Jawa Barat,
                            Indonesia.
                        </p>
                        <ul>
                            <li>Emailing us at: support@TemanPasar.com</li>
                        </ul>
                        <ol start="11">
                            <li>
                                <strong>Changes in the privacy policy</strong>
                                <ul>
                                    <li>
                                        TemanPasar may at any time make changes
                                        to its Privacy Policy with future
                                        effect. We will provide reasonable
                                        advance notice of any such changes.
                                        TemanPasar shall inform the users of the
                                        Website of such changes when the users
                                        log on to the Website. If you wish,
                                        TemanPasar will inform you to your
                                        e-mail registered in our data to any
                                        substantial changes of the Privacy
                                        Policy.
                                    </li>
                                    <li>
                                        You agree that the continued use of the
                                        Website or App after any posted modified
                                        version of the Privacy Policy is your
                                        acceptance of the modified Privacy
                                        Policy.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <strong>Disclosure Required by Law</strong>
                            </li>
                        </ol>
                        <p>
                            We may disclose Personal Information when required
                            by law or when such action is necessary in order to
                            conform to the edicts of the law or comply with a
                            court order or legal process served upon TemanPasar
                            or any of its officers or principals. We further
                            reserve the right to disclose such information when
                            TemanPasar reasonably believes that it is needed to
                            identify, contact, or bring legal action against a
                            party who may be violating TemanPasar Terms of
                            Condition or may be causing injury to or
                            interference with TemanPasar rights or property,
                            registered customers or potential customers of
                            TemanPasar, or anyone else that could be harmed by
                            such activities.
                        </p>
                        <ol start="13">
                            <li>
                                <strong>
                                    Inquiries regarding the Website or the
                                    privacy policy
                                </strong>
                            </li>
                        </ol>
                        <p>
                            In case of inquiries regarding TemanPasar’s privacy
                            policy, our processing of personal data, correction
                            of data or your relations to us in general, you are
                            welcome to contact TemanPasar by email at
                            support.indonesia@TemanPasar.com.
                        </p>{' '}
                    </div>
                </div>
            </div>

            <Link
                className="btn btn-back mt-4 radius-8 text-white px-4 py-2 rounded-silinder border-0"
                to="/"
            >
                Back to Homepage
            </Link>
        </Container>
    );
};

export default Kebijakan;
