import { API } from '../../config/Api';
import { GET_FOOTER } from '../constant';

export const getFooter = () => {
    return {
        type: GET_FOOTER,
        payload: async () => {
            const res = await API.get('apps/settings').catch((error) =>
                Promise.reject(error)
            );

            return res.data?.data;
        }
    };
};
