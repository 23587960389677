import { GET_FOOTER } from '../constant';

const initialState = {
    footer: {}
};

const footerReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${GET_FOOTER}_PENDING`:
            return {
                ...state,
                footer: {}
            };
        case `${GET_FOOTER}_FULFILLED`:
            return {
                ...state,
                footer: action.payload
            };
        case `${GET_FOOTER}_REJECTED`:
            return {
                ...state,
                footer: {}
            };

        default:
            return state;
    }
};

export default footerReducer;
