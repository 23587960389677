import React from 'react';
import { Redirect, Route } from 'react-router-dom';

export const isLogin = () => {
    return !!localStorage.getItem('token');
};

export const isVerified = () => {
    return parseInt(localStorage.getItem('verification_status')) === 1;
};

export const isCompleted = () => {
    return parseInt(localStorage.getItem('complete_profile')) === 1;
};

const UserLogin = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                isLogin() ? <Component {...props} /> : <Redirect to="/login" />
            }
        />
    );
};

const UserNotLogin = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                isLogin() ? <Redirect to="/" /> : <Component {...props} />
            }
        />
    );
};

const UserVerifiedCompleted = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                !isLogin() ? (
                    <Redirect to="/login" />
                ) : !isVerified() ? (
                    <Redirect to="/confirm_email" />
                ) : !isCompleted() ? (
                    <Redirect to="/complete_profile" />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

const UserNotCompleted = ({ component: Component, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) =>
                !isLogin() ? (
                    <Redirect to="/login" />
                ) : isCompleted() ? (
                    <Redirect to="/" />
                ) : (
                    <Component {...props} />
                )
            }
        />
    );
};

const PrivateRoutes = {
    UserLogin,
    UserNotLogin,
    isLogin,
    UserVerifiedCompleted,
    UserNotCompleted
};

export default PrivateRoutes;
