import { Button, Checkbox, CircularProgress, FormControlLabel, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import Swal from 'sweetalert2';

import { IcConfirmEdit, IcSuccessModal } from '../../assets';
import { ModalEditAvatar, Sidebar } from '../../components';
import {
    getAvatarList,
    getCity,
    getCountry,
    getHobby,
    getLearnType,
    getMovieGenre,
    getUniv,
} from '../../store/actions/appAction';
import { getDetailUser, sendEditProfile } from '../../store/actions/userAction';
import { getAcademicYear } from '../../utils/getAcademicYear';

const EditProfile = ({
    getDetailUser,
    dataCountry,
    getCountry,
    sendEditProfile,
    getUniv,
    getCity,
    getLearnType,
    getHobby,
    getMovieGenre,
    getAvatarList
}) => {
    const history = useHistory();
    const [params, setParams] = useState({
        fullname: '',
        username: '',
        birthdate: '',
        gender: '',
        profile_pict_old: '',
        country: '',
        city: '',
        profile_code: '',
        profile_preview: '',
        country_val: '',
        email: '',
        university: '',
        academic_year: '',
        learner_type: '',
        hobbies: [],
        music_type: '',
        movie_genre: [],
        univ_val: '',
        city_val: '',
        learner_type_val: '',
        hobbies_val: '',
        music_type_val: '',
        movie_genre_val: '',
        is_other: false
    });
    const [maxData, setMaxData] = useState('');
    const [submit, setSubmit] = useState(false);
    const [openAvatar, setOpenAvatar] = useState(false);
    const [optionCountry, setOptionCountry] = useState([]);
    const [dataUniv, setDataUniv] = useState([]);
    const [dataAvatar, setDataAvatar] = useState([]);
    const [optionCity, setOptionCity] = useState([]);
    const [dataLearnType, setDataLearnType] = useState([]);
    const [dataHobby, setDataHobby] = useState([]);
    // const [dataMusicType, setDataMusicType] = useState([]);
    const [dataMovieGenre, setDataMovieGenre] = useState([]);
    const [optionYear, setOptionYear] = useState([]);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'BuddyMed | Edit Profile';
        const tgl = new Date();
        let month =
            tgl.getMonth() <= 9
                ? '0' + (tgl.getMonth() + 1)
                : tgl.getMonth() + 1;
        let tanggal = tgl.getDate() <= 9 ? '0' + tgl.getDate() : tgl.getDate();
        setMaxData(tgl.getFullYear() + '-' + month + '-' + tanggal);
        getDetailUser().then(({ value: { user } }) => {
            setParams(
                {
                    ...params,
                    fullname: user?.fullname,
                    username: user?.username,
                    birthdate: user?.birthdate,
                    gender: user?.gender,
                    profile_pict_old: user?.profile_pict_origin,
                    country: user?.country_code,
                    profile_preview: user?.profile_pict,
                    email: user?.email,
                    university: user?.university_code,
                    city: user?.city_code,
                    academic_year: user?.academic_year,
                    learner_type: user?.learner_type_code,
                    hobbies: user?.hobbies,
                    music_type: user?.music_type_code,
                    movie_genre: user?.movie_genre,
                    is_other: user?.is_other,
                    profile_code: user?.profile_code
                },
                getUniv(user?.city_code).then((res) => {
                    setDataUniv(res?.value);
                }),

                getCity(user?.country_code).then((res) => {
                    setOptionCity(res?.value);
                })
            );
            initialValSelect(user);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const changeWithName = (name, value) => {
        setParams((pref) => ({
            ...pref,
            [name]: value
        }));
    };

    const initialValSelect = (userData) => {
        getCountry().then((res) => {
            const countryVal = res?.value?.filter(
                (item) => item.country_code === userData?.country_code
            );
            if (countryVal.length > 0) {
                changeWithName('country_val', countryVal[0]);
            }
            setOptionCountry(res?.value);
        });
        getLearnType().then((res) => {
            const typeLearnVal = res?.value?.filter(
                (item) => item.lt_code === userData?.learner_type_code
            );
            if (typeLearnVal.length > 0) {
                changeWithName('learner_type_val', typeLearnVal[0]);
            }
            setDataLearnType(res?.value);
        });
        getHobby().then((res) => {
            const hobbyVal = res?.value?.filter(
                (item) => item.hobbies_code === userData?.hobbies_code
            );
            if (hobbyVal.length > 0) {
                changeWithName('hobbies_val', hobbyVal[0]);
            }
            setDataHobby(res?.value);
        });
        getMovieGenre().then((res) => {
            const movieVal = res?.value?.filter(
                (item) => item.genre_code === userData?.movie_genre_code
            );
            if (movieVal.length > 0) {
                changeWithName('movie_genre_val', movieVal[0]);
            }
            setDataMovieGenre(res?.value);
        });
        let optionAcademic = getAcademicYear(6);
        setOptionYear(optionAcademic);
        getAvatarList().then((res) => setDataAvatar(res?.value));
    };

    const handleConfirmSubmit = () => {
        Swal.fire({
            text: 'Are you sure you want to save changes?',
            imageUrl: IcConfirmEdit,
            imageWidth: 92,
            imageHeight: 92,
            imageAlt: 'icon edit',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            confirmButtonColor: '#f09856',
            cancelButtonColor: '#EBEAEA'
        }).then((res) => {
            if (res.isConfirmed) {
                handleSubmit();
            }
        });
    };

    const handleSubmit = () => {
        setSubmit(true);
        sendEditProfile(params)
            .then(() => {
                Swal.fire({
                    text: 'Profile changed successfully!',
                    imageUrl: IcSuccessModal,
                    imageWidth: 92,
                    imageHeight: 92,
                    imageAlt: 'icon success',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    getDetailUser();
                    setSubmit(false);
                    history.push('/profile/' + params.username);
                });
            })
            .catch((error) => {
                const err = error?.response?.data;
                if (err.code === 1) {
                    Swal.fire({
                        title: 'Error',
                        text: err.data[0],
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: 'Failed, try again',
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
                setSubmit(false);
            });
    };

    const handleChange = ({ target }) => {
        if (target.name === 'username') {
            setParams((pref) => ({
                ...pref,
                [target.name]: target.value.trim()
            }));
            return;
        }
        setParams((pref) => ({
            ...pref,
            [target.name]: target.value
        }));
    };

    const handleChangeProfile = (value, preview) => {
        setParams((pref) => ({
            ...pref,
            profile_code: value,
            profile_preview: preview
        }));
        setOpenAvatar(false);
    };

    const handleChangeCountry = (value) => {
        let nameVal = '';
        nameVal = value
            ? dataCountry.filter((item) => item.country_code === value)[0]
            : '';

        setParams(
            {
                ...params,
                country: value,
                country_val: nameVal,
                university: '',
                univ_val: '',
                city: '',
                city_val: ''
            },

            getCity(value).then((res) => {
                setOptionCity(res?.value);
            })
        );
    };

    const handleChangeUniv = (value) => {
        let nameVal = '';
        nameVal = value
            ? dataUniv.filter((item) => item.university_code === value)[0]
            : '';

        setParams({
            ...params,
            university: value,
            univ_val: nameVal
        });
    };

    const handleChangeCity = (value) => {
        let nameVal = '';
        nameVal = value
            ? optionCity.filter((item) => item.city_code === value)[0]
            : '';
        setParams(
            {
                ...params,
                city: value,
                city_val: nameVal,
                university: ' ',
                univ_val: ''
            },
            getUniv(value).then((res) => {
                setDataUniv(res?.value);
            })
        );
    };

    const handleChangeLearn = (value) => {
        let nameVal = '';
        nameVal = value
            ? dataLearnType.filter((item) => item.lt_code === value)[0]
            : '';
        setParams({
            ...params,
            learner_type: value,
            learner_type_val: nameVal
        });
    };

    const handleChangeHobby = (value) => {
        setParams({
            ...params,
            hobbies: value
        });
    };

    const handleChangeMovie = (value) => {
        setParams({
            ...params,
            movie_genre: value
        });
    };

    const showSwalErrow = (teks) => {
        Swal.fire({
            title: 'Error',
            text: teks,
            icon: 'error',
            timer: 2000,
            showConfirmButton: false
        });
    };

    const validationData = () => {
        if (params.country.length <= 0) {
            showSwalErrow('Please Select your country');
            return false;
        } else if (params.university.length <= 0) {
            showSwalErrow('Please Select your university');
            return false;
        } else if (params.academic_year.length <= 0) {
            showSwalErrow('Please Select your academic year');
            return false;
        } else if (params.learner_type.length <= 0) {
            showSwalErrow('Please Select your learn type');
            return false;
        } else if (params.hobbies.length <= 0) {
            showSwalErrow('Please Select your hobby');
            return false;
        } else if (params.movie_genre.length <= 0) {
            showSwalErrow('Please Select your movie genre');
            return false;
        } else {
            handleConfirmSubmit();
        }
    };

    const handleChangeOther = ({ target: { checked } }) => {
        setParams((pref) => ({
            ...pref,
            is_other: checked,
            university: '',
            univ_val: ''
        }));
    };

    return (
        <Sidebar>
            <div className="mt-3">
                <Row>
                    <Col xl="12" lg="12" md="12" sm="12" xs="12">
                        <h4 className="font-weight-bold title-page">
                            Edit Profile
                        </h4>
                    </Col>
                    <Col xl="12" lg="12" md="12" sm="12" xs="12">
                        <Card className="py-3 card-complete-profile">
                            <Container>
                                <ValidatorForm
                                    onSubmit={validationData}
                                    className="form"
                                >
                                    <Row>
                                        <Col
                                            xs="12"
                                            sm="6"
                                            md="6"
                                            lg="6"
                                            xl="6"
                                            className="mb-3"
                                        >
                                            <label className="label">
                                                Your Interest
                                            </label>
                                            <div className="d-flex align-items-center mb-3">
                                                <img
                                                    src={params.profile_preview}
                                                    className="input-avatar"
                                                    alt={params.fullname}
                                                />
                                                <Button
                                                    variant="contained"
                                                    color="default"
                                                    className="ml-2"
                                                    onClick={() =>
                                                        setOpenAvatar(true)
                                                    }
                                                    size="small"
                                                    disableElevation
                                                >
                                                    Select Your Interest
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col
                                            xs="12"
                                            sm="6"
                                            md="6"
                                            lg="6"
                                            xl="6"
                                        >
                                            <label
                                                htmlFor="fullname"
                                                className="label"
                                            >
                                                Full Name
                                            </label>
                                            <TextValidator
                                                variant="outlined"
                                                placeholder="Type your full name"
                                                type="text"
                                                onChange={handleChange}
                                                name="fullname"
                                                id="fullname"
                                                value={params.fullname}
                                                validators={['required']}
                                                errorMessages={[
                                                    'this field is required'
                                                ]}
                                                className="w-100 mb-3"
                                                color="primary"
                                            />
                                        </Col>
                                        <Col
                                            xs="12"
                                            sm="6"
                                            md="6"
                                            lg="6"
                                            xl="6"
                                        >
                                            <label
                                                htmlFor="username"
                                                className="label"
                                            >
                                                Username
                                            </label>
                                            <TextValidator
                                                variant="outlined"
                                                placeholder="Type your username"
                                                type="text"
                                                onChange={handleChange}
                                                name="username"
                                                id="username"
                                                value={params.username}
                                                validators={[
                                                    'required',
                                                    'minStringLength: 3'
                                                ]}
                                                errorMessages={[
                                                    'this field is required',
                                                    'Min length username is 3 character'
                                                ]}
                                                className="w-100 mb-3"
                                                color="primary"
                                            />
                                        </Col>
                                        <Col
                                            xs="12"
                                            sm="6"
                                            md="6"
                                            lg="6"
                                            xl="6"
                                        >
                                            <label
                                                htmlFor="birthdate"
                                                className="label"
                                            >
                                                Birth Date
                                            </label>
                                            <TextValidator
                                                variant="outlined"
                                                type="date"
                                                onChange={handleChange}
                                                name="birthdate"
                                                id="birthdate"
                                                value={params.birthdate}
                                                className="w-100 mb-3"
                                                color="primary"
                                                InputProps={{
                                                    inputProps: {
                                                        max: maxData
                                                    }
                                                }}
                                                validators={['required']}
                                                errorMessages={[
                                                    'this field is required'
                                                ]}
                                            />
                                        </Col>
                                        <Col
                                            xs="12"
                                            sm="6"
                                            md="6"
                                            lg="6"
                                            xl="6"
                                        >
                                            <label
                                                htmlFor="gender"
                                                className="label"
                                            >
                                                Gender
                                            </label>
                                            <SelectValidator
                                                value={params.gender}
                                                onChange={handleChange}
                                                className="w-100 mb-3"
                                                variant="outlined"
                                                validators={['required']}
                                                errorMessages={[
                                                    'this field is required'
                                                ]}
                                                name="gender"
                                                id="gender"
                                            >
                                                <MenuItem value="1">
                                                    Male
                                                </MenuItem>
                                                <MenuItem value="0">
                                                    Female
                                                </MenuItem>
                                            </SelectValidator>
                                        </Col>
                                        <Col
                                            xs="12"
                                            sm="6"
                                            md="6"
                                            lg="6"
                                            xl="6"
                                        >
                                            <label
                                                htmlFor="country"
                                                className="label"
                                            >
                                                Country
                                            </label>
                                            <Select
                                                isClearable
                                                isSearchable
                                                name="country"
                                                id="country"
                                                options={optionCountry}
                                                value={params.country_val}
                                                onChange={(e) =>
                                                    handleChangeCountry(
                                                        e?.country_code || ''
                                                    )
                                                }
                                                placeholder="Country"
                                                className="w-100 mb-3"
                                                getOptionValue={(item) =>
                                                    item.country_code
                                                }
                                                getOptionLabel={(item) =>
                                                    item.country_name
                                                }
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#fff',
                                                        primary: '#f09856'
                                                    }
                                                })}
                                            />
                                        </Col>
                                        <Col
                                            xs="12"
                                            sm="6"
                                            md="6"
                                            lg="6"
                                            xl="6"
                                        >
                                            <label
                                                htmlFor="city"
                                                className="label"
                                            >
                                                City
                                            </label>
                                            <Select
                                                isClearable
                                                isSearchable
                                                name="city"
                                                id="city"
                                                options={optionCity}
                                                value={optionCity.filter(
                                                    (item) =>
                                                        item.city_code ===
                                                        params.city
                                                )}
                                                onChange={(e) =>
                                                    handleChangeCity(
                                                        e?.city_code || ''
                                                    )
                                                }
                                                placeholder="City"
                                                className="w-100 mb-3"
                                                getOptionValue={(item) =>
                                                    item.city_code
                                                }
                                                getOptionLabel={(item) =>
                                                    item.city_name
                                                }
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#fff',
                                                        primary: '#f09856'
                                                    }
                                                })}
                                            />
                                        </Col>
                                        <Col
                                            xs="12"
                                            sm="6"
                                            md="6"
                                            lg="6"
                                            xl="6"
                                        >
                                            <label
                                                htmlFor="univ_val"
                                                className="label"
                                            >
                                                University
                                            </label>
                                            <Select
                                                isClearable
                                                isSearchable
                                                name="univ_val"
                                                id="univ_val"
                                                options={dataUniv}
                                                value={dataUniv.filter(
                                                    (item) =>
                                                        item.university_code ===
                                                        params.university
                                                )}
                                                onChange={(e) =>
                                                    handleChangeUniv(
                                                        e?.university_code || ''
                                                    )
                                                }
                                                placeholder="University"
                                                className="w-100 mb-3"
                                                getOptionValue={(item) =>
                                                    item.university_code
                                                }
                                                getOptionLabel={(item) =>
                                                    item.university_name
                                                }
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#fff',
                                                        primary: '#f09856'
                                                    }
                                                })}
                                                isDisabled={params.is_other}
                                            />
                                            <div className="d-flex align-items-center mb-3">
                                                <FormControlLabel
                                                    value="other"
                                                    control={
                                                        <Checkbox
                                                            color="default"
                                                            checked={
                                                                params.is_other
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={
                                                                handleChangeOther
                                                            }
                                                        />
                                                    }
                                                    label="Other"
                                                />
                                                <TextValidator
                                                    variant="outlined"
                                                    placeholder="Type your university"
                                                    type="text"
                                                    onChange={handleChange}
                                                    name="university"
                                                    value={
                                                        params.is_other
                                                            ? params.university
                                                            : ''
                                                    }
                                                    validators={
                                                        params.is_other
                                                            ? ['required']
                                                            : []
                                                    }
                                                    errorMessages={[
                                                        'this field is required'
                                                    ]}
                                                    className="w-100 ml-2 w-100"
                                                    color="primary"
                                                    disabled={!params.is_other}
                                                />
                                            </div>
                                        </Col>
                                        <Col
                                            xs="12"
                                            sm="6"
                                            md="6"
                                            lg="6"
                                            xl="6"
                                        >
                                            <label
                                                htmlFor="academic_year"
                                                className="label"
                                            >
                                                Academic Year
                                            </label>
                                            <Select
                                                isClearable
                                                isSearchable
                                                name="academic_year"
                                                id="academic_year"
                                                options={optionYear}
                                                value={
                                                    params.academic_year
                                                        ? {
                                                              label: params.academic_year,
                                                              value: params.academic_year
                                                          }
                                                        : ''
                                                }
                                                onChange={(e) =>
                                                    changeWithName(
                                                        'academic_year',
                                                        e?.value || ''
                                                    )
                                                }
                                                placeholder="Academic Year"
                                                className="w-100 mb-3"
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#fff',
                                                        primary: '#f09856'
                                                    }
                                                })}
                                            />
                                        </Col>
                                        <Col
                                            xs="12"
                                            sm="6"
                                            md="6"
                                            lg="6"
                                            xl="6"
                                        >
                                            <label
                                                htmlFor="learner_type_val"
                                                className="label"
                                            >
                                                Type of Learner
                                            </label>
                                            <Select
                                                isClearable
                                                isSearchable
                                                name="learner_type_val"
                                                id="learner_type_val"
                                                options={dataLearnType}
                                                value={params.learner_type_val}
                                                onChange={(e) =>
                                                    handleChangeLearn(
                                                        e?.lt_code || ''
                                                    )
                                                }
                                                placeholder="Type of Learner"
                                                className="w-100 mb-3"
                                                getOptionValue={(item) =>
                                                    item.lt_code
                                                }
                                                getOptionLabel={(item) =>
                                                    item.lt_name
                                                }
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#fff',
                                                        primary: '#f09856'
                                                    }
                                                })}
                                            />
                                        </Col>
                                        <Col
                                            xs="12"
                                            sm="6"
                                            md="6"
                                            lg="6"
                                            xl="6"
                                        >
                                            <label
                                                htmlFor="hobbies_name"
                                                className="label"
                                            >
                                                Hobbies
                                            </label>
                                            <Select
                                                isClearable
                                                isSearchable
                                                isMulti
                                                name="hobbies_name"
                                                id="hobbies_name"
                                                options={dataHobby}
                                                // defaultValue={[params.hobbies]}
                                                value={params.hobbies}
                                                onChange={(e) =>
                                                    handleChangeHobby(e || '')
                                                }
                                                placeholder="Hobbies"
                                                className="w-100 mb-3"
                                                getOptionValue={(item) =>
                                                    item.hobbies_code
                                                }
                                                getOptionLabel={(item) =>
                                                    item.hobbies_name
                                                }
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#fff',
                                                        primary: '#f09856'
                                                    }
                                                })}
                                            />
                                        </Col>
                                        <Col
                                            xs="12"
                                            sm="6"
                                            md="6"
                                            lg="6"
                                            xl="6"
                                        >
                                            <label
                                                htmlFor="movie_genre_val"
                                                className="label"
                                            >
                                                Movie Genre
                                            </label>
                                            <Select
                                                isClearable
                                                isSearchable
                                                isMulti
                                                name="movie_genre_val"
                                                id="movie_genre_val"
                                                options={dataMovieGenre}
                                                value={params.movie_genre}
                                                onChange={(e) =>
                                                    handleChangeMovie(e || '')
                                                }
                                                placeholder="Movie Genre"
                                                className="w-100 mb-3"
                                                getOptionValue={(item) =>
                                                    item.genre_code
                                                }
                                                getOptionLabel={(item) =>
                                                    item.genre_name
                                                }
                                                theme={(theme) => ({
                                                    ...theme,
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: '#fff',
                                                        primary: '#f09856'
                                                    }
                                                })}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="d-flex justify-content-end mt-4">
                                        <Button
                                            disableElevation
                                            disabled={submit}
                                            variant="contained"
                                            className="px-5 text-white btn_buddy"
                                            type="submit"
                                        >
                                            {submit ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                'Save'
                                            )}
                                        </Button>
                                    </div>
                                </ValidatorForm>
                            </Container>
                        </Card>
                    </Col>
                </Row>
            </div>
            {openAvatar && (
                <ModalEditAvatar
                    open={openAvatar}
                    handleClose={() => setOpenAvatar(false)}
                    data={dataAvatar}
                    value={params.profile_code}
                    handleChange={handleChangeProfile}
                />
            )}
        </Sidebar>
    );
};

const mapStateToProps = (state) => {
    return {
        dataUser: state.user.user,
        dataCountry: state.app.country
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDetailUser: () => dispatch(getDetailUser()),
        getCountry: () => dispatch(getCountry()),
        sendEditProfile: (params) => dispatch(sendEditProfile(params)),
        getUniv: (params) => dispatch(getUniv(params)),
        getCity: (params) => dispatch(getCity(params)),
        getLearnType: () => dispatch(getLearnType()),
        getHobby: () => dispatch(getHobby()),
        getMovieGenre: () => dispatch(getMovieGenre()),
        getAvatarList: () => dispatch(getAvatarList())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProfile);
