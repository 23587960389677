import { Button, Hidden } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { IlFindFriendsPng } from '../../assets';
import { Sidebar } from '../../components';

const Home = () => {
    const history = useHistory();

    useEffect(() => {
        document.title = 'BuddyMed | Home';
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    }, []);

    return (
        <Sidebar>
            <div className="d-flex flex-column justify-content-center container-home">
                <div className="text-center mt-5 d-flex flex-column align-items-center">
                    <Hidden mdUp>
                        <img
                            src={IlFindFriendsPng}
                            className="w-100 mb-3"
                            alt="find buddy"
                        />
                    </Hidden>

                    <h2 className="font-weight-bold mb-3 text-center title">
                        It's never to late to find your buddy
                    </h2>
                    <p className="text-center subtitle">
                        A positive buddy can provide supportive environment to
                        complete tasks and retain knowledge more effectively
                    </p>
                    <Button
                        variant="contained"
                        className="rounded-silinder px-5 mt-4 mb-3 btn_buddy"
                        disableElevation
                        onClick={() => history.push('/find_buddy')}
                    >
                        Find a Buddy
                    </Button>
                    <Hidden smDown>
                        <img
                            src={IlFindFriendsPng}
                            className="w-30 img-buddy text-center"
                            alt="find buddy"
                        />
                    </Hidden>
                </div>
            </div>
        </Sidebar>
    );
};

export default Home;
