import {
    CHAT_DETAIL,
    CREATE_GROUP,
    GROP_CHAT,
    GROP_CHAT_DETAIL,
    GROUP_DETAIL,
    INVITE_FRIEND_GROUP,
    KICK_MEMBER,
    LEAVE_GROUP,
    LIST_FRIEND_GROUP,
    LIST_MEMBER_GROUP,
    MESSAGE_CHAT,
    SEND_MESSAGES,
} from '../constant';

const initialState = {
    data_group: null,
    chat_group_detail: null,
    data_chat: null,
    chat_detail: null,
    detail_group: null,
    list_member_group: null,
    messages: {},
    leave_group: {},
    create_group: {},
    list_friend_group: [],
    invite_friend_group: {},
    kick_members: []
};

const chatGroupReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${SEND_MESSAGES}_PENDING`:
            return {
                ...state,
                messages: {}
            };
        case `${SEND_MESSAGES}_FULFILLED`:
            return {
                ...state,
                messages: action.payload
            };
        case `${SEND_MESSAGES}_REJECTED`:
            return {
                ...state,
                messages: {}
            };

        case `${CREATE_GROUP}_PENDING`:
            return {
                ...state,
                create_group: {}
            };
        case `${CREATE_GROUP}_FULFILLED`:
            return {
                ...state,
                create_group: action.payload.data
            };
        case `${CREATE_GROUP}_REJECTED`:
            return {
                ...state,
                create_group: {}
            };

        case `${GROP_CHAT}_PENDING`:
            return {
                ...state,
                data_group: null
            };
        case `${GROP_CHAT}_FULFILLED`:
            return {
                ...state,
                data_group: action.payload.data
            };
        case `${GROP_CHAT}_REJECTED`:
            return {
                ...state,
                data_group: []
            };

        case `${LIST_FRIEND_GROUP}_PENDING`:
            return {
                ...state,
                list_friend_group: null
            };
        case `${LIST_FRIEND_GROUP}_FULFILLED`:
            return {
                ...state,
                list_friend_group: action.payload
            };
        case `${LIST_FRIEND_GROUP}_REJECTED`:
            return {
                ...state,
                list_friend_group: []
            };

        case `${INVITE_FRIEND_GROUP}_PENDING`:
            return {
                ...state,
                invite_friend_group: null
            };
        case `${INVITE_FRIEND_GROUP}_FULFILLED`:
            return {
                ...state,
                invite_friend_group: action.payload
            };
        case `${INVITE_FRIEND_GROUP}_REJECTED`:
            return {
                ...state,
                invite_friend_group: {}
            };

        case `${KICK_MEMBER}_PENDING`:
            return {
                ...state,
                kick_members: null
            };
        case `${KICK_MEMBER}_FULFILLED`:
            return {
                ...state,
                kick_members: action.payload
            };
        case `${KICK_MEMBER}_REJECTED`:
            return {
                ...state,
                kick_members: []
            };

        case `${GROUP_DETAIL}_PENDING`:
            return {
                ...state,
                detail_group: null
            };
        case `${GROUP_DETAIL}_FULFILLED`:
            return {
                ...state,
                detail_group: action.payload.data
            };
        case `${GROUP_DETAIL}_REJECTED`:
            return {
                ...state,
                detail_group: null
            };

        case `${LIST_MEMBER_GROUP}_PENDING`:
            return {
                ...state
            };
        case `${LIST_MEMBER_GROUP}_FULFILLED`:
            return {
                ...state,
                list_member_group: action.payload
            };
        case `${LIST_MEMBER_GROUP}_REJECTED`:
            return {
                ...state,
                list_member_group: null
            };

        case `${GROP_CHAT_DETAIL}_PENDING`:
            return {
                ...state,
                chat_group_detail: null
            };
        case `${GROP_CHAT_DETAIL}_FULFILLED`:
            return {
                ...state,
                chat_group_detail: action.payload.data
            };
        case `${GROP_CHAT_DETAIL}_REJECTED`:
            return {
                ...state,
                chat_group_detail: null
            };

        case `${LEAVE_GROUP}_PENDING`:
            return {
                ...state,
                leave_group: {}
            };
        case `${LEAVE_GROUP}_FULFILLED`:
            return {
                ...state,
                leave_group: action.payload.data
            };
        case `${LEAVE_GROUP}_REJECTED`:
            return {
                ...state,
                leave_group: {}
            };

        case `${MESSAGE_CHAT}_PENDING`:
            return {
                ...state,
                data_chat: null
            };
        case `${MESSAGE_CHAT}_FULFILLED`:
            return {
                ...state,
                data_chat: action.payload
            };
        case `${MESSAGE_CHAT}_REJECTED`:
            return {
                ...state,
                data_chat: []
            };

        case `${CHAT_DETAIL}_PENDING`:
            return {
                ...state,
                chat_detail: null
            };
        case `${CHAT_DETAIL}_FULFILLED`:
            return {
                ...state,
                chat_detail: action.payload.data.data
            };
        case `${CHAT_DETAIL}_REJECTED`:
            return {
                ...state,
                chat_detail: null
            };

        default:
            return state;
    }
};

export default chatGroupReducer;
