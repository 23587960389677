import { Button, Chip, IconButton, Tooltip } from '@material-ui/core';
import { CheckCircleOutlineOutlined, HighlightOffRounded } from '@material-ui/icons';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const CardMessages = ({
    name,
    message,
    time,
    username,
    onClick,
    notif,
    picture,
    to,
    isNotif,
    isMember,
    statusMember,
    handleStatus,
    data,
    isMe,
    submitFriend,
    canChat,
    isRecentMatch
}) => {
    return (
        <>
            {isMember ? (
                <Card className="py-2 d-flex flex-row align-items-center border-0 radius-8 card-chat">
                    <img
                        src={picture}
                        alt={name}
                        className="profile rounded-circle"
                    />
                    <div className="w-100 content ml-3 d-flex flex-row align-items-center justify-content-between flex-1">
                        <div>
                            <Link
                                to={`/profile/${data.username}`}
                                className="link-hover"
                            >
                                <h6 className="nama">{name}</h6>
                            </Link>
                            <p className="message elipse-2 mb-1">{message}</p>
                            <div className="d-flex align-item-center">
                                {username && (
                                    <p className="m-0 font-weight-light text-dark mr-3">
                                        {username}
                                    </p>
                                )}
                                {statusMember === 2 ? (
                                    <Chip
                                        label="pending"
                                        color="secondary"
                                        size="small"
                                        className="text-black"
                                    />
                                ) : null}
                            </div>
                        </div>
                        <div>
                            {!isMe && statusMember === 2 && canChat ? (
                                <div className="d-flex align-items-center">
                                    <Tooltip title="reject user">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleStatus(
                                                    data.member_code,
                                                    data.group_code,
                                                    data.users_code,
                                                    data.fullname,
                                                    0
                                                )
                                            }
                                        >
                                            <HighlightOffRounded color="disabled" />
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="accept user">
                                        <IconButton
                                            size="small"
                                            onClick={() =>
                                                handleStatus(
                                                    data.member_code,
                                                    data.group_code,
                                                    data.users_code,
                                                    data.fullname,
                                                    1
                                                )
                                            }
                                        >
                                            <CheckCircleOutlineOutlined color="primary" />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            ) : !isMe && data.matched_status === 0 ? (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className="rounded-8"
                                    size="small"
                                    onClick={() =>
                                        onClick(
                                            data,
                                            data.matched_status === 0 ? 1 : 0
                                        )
                                    }
                                    disabled={submitFriend}
                                >
                                    Add Buddy
                                </Button>
                            ) : !isMe && data.matched_status === 2 ? (
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    className="rounded-8"
                                    size="small"
                                    onClick={() =>
                                        onClick(
                                            data,
                                            data.matched_status === 0 ? 1 : 0
                                        )
                                    }
                                    disabled={submitFriend}
                                >
                                    Cancel
                                </Button>
                            ) : null}
                        </div>
                    </div>
                </Card>
            ) : (
                <Card
                    className={`px-4 py-3 d-flex flex-row align-items-center border-0 radius-8 card-chat`}
                >
                    <img
                        src={picture}
                        alt={name}
                        className="profile text-muted"
                    />
                    <div className="w-100 content ml-3 d-flex flex-row align-items-center justify-content-between flex-1">
                        <div className="flex-1 mr-2">
                            <Link
                                to={to}
                                className={`text-decoration-none ${
                                    isRecentMatch ? 'stretched-link' : ''
                                }`}
                            >
                                <p className="nama">{name}</p>
                            </Link>
                            <p className="message elipse-2">{message}</p>
                            {username && (
                                <p className="m-0 font-weight-light">
                                    {username}
                                </p>
                            )}
                        </div>
                        <div>
                            <p className="waktu">{time}</p>
                            {isNotif && (
                                <span className="badge badge-custom">
                                    {notif}
                                </span>
                            )}
                        </div>
                    </div>
                </Card>
            )}
        </>
    );
};

export default CardMessages;
