import { API, setAuthToken } from '../../config/Api';
import {
    ADD_CONFS,
    DELETE_CONFS,
    GET_DETAIL_CONFERENCES
} from '../constant';

export const addConfs = (params) => {
    return {
        type: ADD_CONFS,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.post('user/conferences/create', params).catch(
                (error) => Promise.reject(error)
            );

            return res.data;
        }
    };
};

export const deleteConfs = (code) => {
    return {
        type: DELETE_CONFS,
        payload: async () => {
            const token = await localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.delete(
                `user/conferences/${code}/delete`
            ).catch((error) => Promise.reject(error));

            return res.data;
        }
    };
};

export const getDetailConferences = (code) => {
    return {
        type: GET_DETAIL_CONFERENCES,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get('user/conferences/' + code).catch(
                (error) => Promise.reject(error)
            );

            return res.data;
        }
    };
};
