import '../../assets/style/bootstrap.css';
import '../../assets/style/react-select.css';
import './App.css';

import { CircularProgress } from '@material-ui/core';
import { ZoomMtg } from '@zoomus/websdk';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getSignature } from '../../store/actions/userAction';

ZoomMtg.setZoomJSLib('https://source.zoom.us/2.16.0/lib', '/av');
// ZoomMtg.setZoomJSLib('node_modules/@zoomus/websdk/dist/lib', '/av');

// loads language files, also passes any error messages to the ui
ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();
ZoomMtg.i18n.load('en-US');
ZoomMtg.i18n.reload('en-US');

const LiveStreaming = ({ getSignature, user }) => {
    const { meet_pass, meet_number } = useParams();
    let apiKey = process.env.REACT_APP_ZOOM_API_KEY;
    let meetingNumber = meet_number;
    const leaveUrl = window.location.origin;
    const userName = user?.fullname;
    const userEmail = user?.email;
    const passWord = meet_pass;
    

    const startMeeting = (signature) => {
      
        document.getElementById('Zoom-App').style.display = 'none';
        document.getElementById('zmmtg-root').style.display = 'block';

        ZoomMtg.init({
            leaveUrl: leaveUrl,
            isSupportAV: true,
            isLockBottom: false,
            meetingInfo: [
                'topic',
                'host',
                'participant',
                'dc',
                'report',
                'telPwd'
            ],
            success: (success) => {
                ZoomMtg.join({
                    signature: signature,
                    meetingNumber: meetingNumber,
                    userName: userName,
                    sdkKey: apiKey,
                    userEmail: userEmail,
                    passWord: passWord,
                    success: (success) => {
                        console.log(success);
                    },
                    error: (error) => {
                        console.log(
                            '@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@',
                            error
                        );
                        console.log(error);
                    }
                });
            },
            error: (error) => {
              console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!', error);
                console.log(error);
            }
        });
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleGetSignature = () => {
        getSignature({
            meeting_number: meetingNumber
        })
            .then((response) => {
                startMeeting(response.value.data.signature);
            })
            .catch((error) => {
                console.error(error);
            });
    };

    useEffect(() => {
        document.title = 'BuddyMed | Meet Conference';
        handleGetSignature();
    }, [handleGetSignature]);

    return (
        <div
            id="Zoom-App"
            className="w-100 h-100 d-flex align-items-center justify-content-center mt-5"
        >
            <CircularProgress size={25} />
            <p className="ml-2 m-0">Loading...</p>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user.user
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getSignature: (params) => dispatch(getSignature(params))
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LiveStreaming);
