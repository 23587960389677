import {
    Button,
    CircularProgress,
    Dialog,
    DialogTitle,
    IconButton
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import {
    getBreakTheIce,
    sendMessages,
    sendMessagesGroup
} from '../../store/actions/chatGroupAction';

const ModalBTI = ({
    handleClose,
    open,
    getBreakTheIce,
    type,
    code,
    sendMessages,
    sendMessagesGroup
}) => {
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');

    const submitMessage = () => {
        setSubmit(true);
        if (type === 'GROUP') {
            sendMessagesGroup({
                group_code: code,
                text: message
            })
                .then(() => {
                    setSubmit(false);
                    handleClose();
                })
                .catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'failed, try again later',
                        confirmButtonText: 'ok',
                        confirmButtonColor: '#f09856'
                    });
                    setSubmit(false);
                });
        } else {
            sendMessages({
                messages_parent_code: code,
                messages: message
            })
                .then(() => {
                    setSubmit(false);
                    handleClose();
                })
                .catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'failed, try again later',
                        confirmButtonText: 'ok',
                        confirmButtonColor: '#f09856'
                    });
                    setSubmit(false);
                });
        }
    };

    useEffect(() => {
        getBreakTheIce().then((res) => {
            setLoading(false);
            setMessage(res?.value?.data?.bti_text);
        });
    }, [getBreakTheIce]);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <div className="d-flex justify-content-between align-items-center">
                <DialogTitle className="title-dialog">
                    Break The Ice
                </DialogTitle>
                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div>

            <div className="px-4 pb-3">
                <ValidatorForm onSubmit={submitMessage} className="form">
                    <TextValidator
                        variant="outlined"
                        placeholder="Question"
                        type="text"
                        name="message"
                        value={message}
                        className="w-100 mb-3"
                        color="primary"
                        disabled
                        multiline
                        rows={4}
                    />

                    <div className="d-flex justify-content-center mt-3">
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            className="radius-8 text-white px-5"
                            type="submit"
                            disabled={submit || loading}
                        >
                            {submit || loading ? (
                                <CircularProgress size={24} />
                            ) : (
                                'Send'
                            )}
                        </Button>
                    </div>
                </ValidatorForm>
            </div>
        </Dialog>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getBreakTheIce: () => dispatch(getBreakTheIce()),
        sendMessagesGroup: (params) => dispatch(sendMessagesGroup(params)),
        sendMessages: (params) => dispatch(sendMessages(params))
    };
};

export default connect(null, mapDispatchToProps)(ModalBTI);
