import { Button, Checkbox, FormControlLabel } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import Select from 'react-select';
import introJs from "intro.js";

import { getAcademicYear } from '../../utils/getAcademicYear';

const CardInputLocation = ({
    data,
    handleNext,
    handleBack,
    dataCountry,
    dataCity,
    handleChangeCountry,
    handleChangeCity,
    dataUniv,
    handleChangeUniv,
    handleChangeSelect,
    handleChange,
    handleChangeOther
}) => {
    const [optionYear, setOptionYear] = useState([]);
    useEffect(() => {
        const tourString = window.localStorage.getItem("tour");
        const tour = tourString ? JSON.parse(tourString) : {};
        const email = window.localStorage.getItem("email");
        const myTour = tour[email];

        if (!myTour || !Object.keys(myTour).length) {
            window.localStorage.setItem("tour", JSON.stringify({
                ...tour,
                [email] :  {
                    profile: false,
                    location: true,
                    topic: true
                }
            }));
        }

        const intro = introJs();

        intro.setOptions({
            steps: [
                {
                    element: document.querySelector('[data-step="2"]'),
                    title: 'Step 2 (Location)',
                    intro: "This form is for your location information",
                    position: "top",
                    scrollTo: "element"
                },
                {
                    element: document.querySelector('[data-step="5"]'),
                    title: 'Step 2 (Location)',
                    intro: "Fill in your location information",
                    position: "top",
                    scrollTo: "element",
                },
                {
                    element: document.querySelector('[data-step="6"]'),
                    title: 'Step 2 (Location)',
                    intro: "Press back if you mistakenly fill in your profile information",
                    position: "top",
                    scrollTo: "element"
                },
                {
                    element: document.querySelector('[data-step="7"]'),
                    title: 'Step 2 (Location)',
                    intro: "Press next to continue to next step",
                    position: "top",
                    scrollTo: "element"
                }
            ]
        });

        if (tour[email].location) {
            intro.start();
        }

        let optionAcademic = getAcademicYear(6);
        setOptionYear(optionAcademic);

        return () => {
            if (tour[email].location) {
                intro.exit();
            }
        }
    }, []);

    return (
        <ValidatorForm onSubmit={handleNext} className="form">
            <Row data-step="5">
                <Col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label className="label">Country</label>
                    <Select
                        isClearable
                        isSearchable
                        name="country"
                        options={dataCountry}
                        value={data.country_val}
                        onChange={(e) =>
                            handleChangeCountry(e?.country_code || '')
                        }
                        placeholder="Country"
                        className="w-100 mb-3"
                        getOptionValue={(item) => item.country_code}
                        getOptionLabel={(item) => item.country_name}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#fff',
                                primary: '#F09856'
                            }
                        })}
                    />
                    <label className="label">University</label>
                    <Select
                        isClearable
                        isSearchable
                        name="university"
                        options={dataUniv}
                        value={data.univ_val}
                        onChange={(e) =>
                            handleChangeUniv(e?.university_code || '')
                        }
                        placeholder="University"
                        className="w-100 mb-3"
                        getOptionValue={(item) => item.university_code}
                        getOptionLabel={(item) => item.university_name}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#fff',
                                primary: '#F09856'
                            }
                        })}
                        isDisabled={data.is_other}
                    />
                    <div className="d-flex align-items-center mb-3">
                        <FormControlLabel
                            value="other"
                            control={
                                <Checkbox
                                    color="primary"
                                    checked={data.is_other}
                                    onChange={handleChangeOther}
                                />
                            }
                            label="Other"
                        />
                        <TextValidator
                            variant="outlined"
                            placeholder="Type your university"
                            type="text"
                            onChange={handleChange}
                            name="university"
                            value={data.is_other ? data.university : ''}
                            validators={data.is_other ? ['required'] : []}
                            errorMessages={['this field is required']}
                            className="w-100 w-100"
                            color="primary"
                            disabled={!data.is_other}
                        />
                    </div>
                </Col>

                <Col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label className="label">City</label>
                    <Select
                        isClearable
                        isSearchable
                        name="city"
                        options={dataCity}
                        value={data.city_val}
                        onChange={(e) => handleChangeCity(e?.city_code || '')}
                        placeholder="City"
                        className="w-100 mb-3"
                        getOptionValue={(item) => item.city_code}
                        getOptionLabel={(item) => item.city_name}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#fff',
                                primary: '#F09856'
                            }
                        })}
                    />
                    <label className="label">Academic Year</label>
                    <Select
                        isClearable
                        isSearchable
                        name="countfry"
                        options={optionYear}
                        value={
                            data.academic_year
                                ? {
                                      value: data.academic_year,
                                      label: data.academic_year
                                  }
                                : ''
                        }
                        onChange={(e) =>
                            handleChangeSelect('academic_year', e?.value || '')
                        }
                        placeholder="Academic Year"
                        className="w-100 mb-3"
                        getOptionValue={(item) => item.value}
                        getOptionLabel={(item) => item.label}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#fff',
                                primary: '#F09856'
                            }
                        })}
                    />
                </Col>
            </Row>
            <div className="d-flex mt-5 align-items-center container-btn">
                <Button
                    disableElevation
                    variant="contained"
                    className="px-5 mr-2 py-2 rounded-silinder btn-half"
                    onClick={handleBack}
                    data-step="6"
                >
                    Back
                </Button>
                <button className="btn btn-next px-5 m-2 rounded-silinder btn-half btn_buddy" data-step="7">
                    Next
                </button>
            </div>
        </ValidatorForm>
    );
};

export default CardInputLocation;
