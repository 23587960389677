import {
    ADD_CONFS,
    DELETE_CONFS,
    GET_DETAIL_CONFERENCES
} from '../constant';

const initialState = {
    data: null,
    add_confs: {},
    delete_confs: {}
};

const confsReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${ADD_CONFS}_PENDING`:
            return {
                ...state,
                add_confs: {}
            };
        case `${ADD_CONFS}_FULFILLED`:
            return {
                ...state,
                add_confs: action.payload
            };
        case `${ADD_CONFS}_REJECTED`:
            return {
                ...state,
                add_confs: {}
            };
        case `${DELETE_CONFS}_PENDING`:
            return {
                ...state,
                delete_confs: {}
            };
        case `${DELETE_CONFS}_FULFILLED`:
            return {
                ...state,
                delete_confs: action.payload
            };
        case `${DELETE_CONFS}_REJECTED`:
            return {
                ...state,
                delete_confs: {}
            };
        case `${GET_DETAIL_CONFERENCES}_PENDING`:
            return {
              ...state,
                data: null
            };
        case `${GET_DETAIL_CONFERENCES}_FULFILLED`:
            return {
            ...state,
               data: action.payload
            };
        case `${GET_DETAIL_CONFERENCES}_REJECTED`:
            return {
             ...state,
               data: null
            };

        default:
            return state;
    }
};

export default confsReducer;
