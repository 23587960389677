import { API, setAuthToken } from '../../config/Api';
import { FIND_BUDDY, MATCHED_BUDDY } from '../constant';

export const findBuddy = function (params) {
    return {
        type: FIND_BUDDY,
        payload: async function () {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.post('user/discover/new', params).catch(
                function (error) {
                    Promise.reject(error);
                }
            );
            return res.data;
        }
    };
};

export const matchedBuddy = function (params) {
    return {
        type: MATCHED_BUDDY,
        payload: async function () {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            // params -> friends_code

            const res = await API.post('user/matched/recent', params).catch(
                function (error) {
                    Promise.reject(error);
                }
            );
            return res.data;
        }
    };
};
