import { FIND_BUDDY, MATCHED_BUDDY } from '../constant';

const initialState = {
    data: null,
    dataMatched: null
};

const buddyReducer = function (state = initialState, action) {
    switch (action.type) {
        case `${FIND_BUDDY}_PENDING`:
            return {
                ...state,
                data: null
            };
        case `${FIND_BUDDY}_FULFILLED`:
            return {
                ...state,
                data: action.payload.data
            };
        case `${FIND_BUDDY}_REJECTED`:
            return {
                ...state,
                data: null
            };

        case `${MATCHED_BUDDY}_PENDING`:
            return {
                ...state,
                dataMatched: null
            };
        case `${MATCHED_BUDDY}_FULFILLED`:
            return {
                ...state,
                dataMatched: action.payload.data
            };
        case `${MATCHED_BUDDY}_REJECTED`:
            return {
                ...state,
                dataMatched: null
            };

        default:
            return state;
    }
};

export default buddyReducer;
