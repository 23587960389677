export const LOGIN = 'LOGIN';
export const SIGNUP = 'SIGNUP';
export const SEND_FORGOT = 'SEND_FORGOT';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const LOGOUT = 'LOGOUT';
export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const DETAIL_USER = 'DETAIL_USER';
export const DETAIL_USER_CODE = 'DETAIL_USER_CODE';
export const CONFERENCE = 'CONFERENCE';
export const DISCOVER_FRIENDS = 'DISCOVER_FRIENDS';
export const MATCHED_FRIENDS = 'MATCHED_FRIENDS';
export const REQUEST_MATCHED_FRIENDS = 'REQUEST_MATCHED_FRIENDS';
export const GROP_CHAT = 'GROP_CHAT';
export const GROP_CHAT_DETAIL = 'GROP_CHAT_DETAIL';
export const MESSAGE_CHAT = 'MESSAGE_CHAT';
export const CHAT_DETAIL = 'CHAT_DETAIL';
export const GROUP_DETAIL = 'GROUP_DETAIL';
export const LIST_MEMBER_GROUP = 'LIST_MEMBER_GROUP';
export const ADD_MATCH = 'ADD_MATCH';
export const CANCEL_MATCH = 'CANCEL_MATCH';
export const ACCEPT_MATCH = 'ACCEPT_MATCH';
export const SEND_MESSAGES = 'SEND_MESSAGES';
export const CHECK_USERNAME = 'CHECK_USERNAME';
export const GET_COUNTRY = 'GET_COUNTRY';
export const GET_CITY = 'GET_CITY';
export const GET_TOPIC = 'GET_TOPIC';
export const COMPLETE_PROFILE = 'COMPLETE_PROFILE';
export const ADD_CONF = 'ADD_CONF';
export const ADD_CONF_USER = 'ADD_CONF_USER';
export const EDIT_CONF = 'EDIT_CONF';
export const DELETE_CONF = 'DELETE_CONF';
export const LEAVE_GROUP = 'LEAVE_GROUP';
export const SEND_MESSAGES_GROUP = 'SEND_MESSAGES_GROUP';
export const EDIT_PROFILE = 'EDIT_PROFILE';
export const EDIT_PASSWORD = 'EDIT_PASSWORD';
export const DETAIL_USERNAME = 'DETAIL_USERNAME';
export const CREATE_GROUP = 'CREATE_GROUP';
export const GET_NOTIF = 'GET_NOTIF';
export const READ_NOTIF = 'READ_NOTIF';
export const GET_DETAIL_CONFERENCE = 'GET_DETAIL_CONFERENCE';
export const GET_SIGNATUR = 'GET_SIGNATUR';
export const EDIT_GROUP = 'EDIT_GROUP';
export const LIST_FRIEND_GROUP = 'LIST_FRIEND_GROUP';
export const INVITE_FRIEND_GROUP = 'INVITE_FRIEND_GROUP';
export const KICK_MEMBER = 'KICK_MEMBER';
export const CHECK_TOKEN_RESET = 'CHECK_TOKEN_RESET';
export const GET_NEW_TOPIC = 'GET_NEW_TOPIC';
export const EDIT_TOPIC = 'EDIT_TOPIC';
export const RESPONSE_INVITE_GROUP = 'RESPONSE_INVITE_GROUP';
export const GET_SUGGEST_TOPIC = 'GET_SUGGEST_TOPIC';
export const ADD_TOPIC = 'ADD_TOPIC';
export const GET_UNIV = 'GET_UNIV';
export const GET_LEARN_TYPE = 'GET_LEARN_TYPE';
export const GET_HOBBY = 'GET_HOBBY';
export const GET_MUSIC_TYPE = 'GET_MUSIC_TYPE';
export const GET_MOVIE_GENRE = 'GET_MOVIE_GENRE';
export const GET_RECENT_MATCH = 'GET_RECENT_MATCH';
export const FIND_BUDDY = 'FIND_BUDDY';
export const MATCHED_BUDDY = 'MATCHED_BUDDY';
export const GET_EXAM_TYPE = 'GET_EXAM_TYPE';
export const GET_AVATAR_LIST = 'GET_AVATAR_LIST';
export const GET_FOOTER = 'GET_FOOTER';
export const GET_BREAK_THE_ICE = 'GET_BREAK_THE_ICE';
export const ADD_CONFS = 'ADD_CONFS';
export const DELETE_CONFS = 'DELETE_CONFS';
export const GET_DETAIL_CONFERENCES = 'GET_DETAIL_CONFERENCES';
