import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { Fragment } from 'react';
import { Col } from 'react-bootstrap';

const LoadingFindBuddy = ({ jml }) => {
    let data = [];
    for (let i = 0; i < jml; i++) {
        data.push(i);
    }
    return (
        <Fragment>
            {data?.map((item) => (
                <Col
                    xs="12"
                    sm="12"
                    md="4"
                    lg="3"
                    xl="3"
                    className="mb-3 d-flex justify-content-center"
                    key={item}
                >
                    <Skeleton animation="wave" width={300} height={70} />
                </Col>
            ))}
        </Fragment>
    );
};

export default LoadingFindBuddy;
