import { Card } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { Fragment } from 'react';

const LoadingMessage = ({ jml }) => {
    let data = [];
    for (let i = 0; i < jml; i++) {
        data.push(i);
    }
    return (
        <Fragment>
            {data?.map((item) => (
                <Card
                    key={item}
                    className={`radius-8 d-flex align-items-center card-friend mt-1 mb-3 p-3`}
                    elevation={0}
                >
                    <Skeleton
                        animation="wave"
                        variant="circle"
                        width={70}
                        height={70}
                    />
                    <div className="ml-3">
                        <Skeleton animation="wave" width={150} height={18} />
                        <Skeleton animation="wave" width={100} height={13} />
                    </div>
                </Card>
            ))}
        </Fragment>
    );
};

export default LoadingMessage;
