import { Button, Checkbox, CircularProgress, IconButton, InputAdornment } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import GoogleLogin from 'react-google-login';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import { IcBrand, IcEyeClose, IcEyeOpen, IcGoogle, IcPass, IcUsername, IlLoginSession } from '../../assets';
import { login, loginRegisterGoogle } from '../../store/actions/authAction';
import { getFooter } from '../../store/actions/footerAction';
import { decryptAes, encryptAes } from '../../utils/encryptAes';

const Login = ({ history, login, loginRegisterGoogle, getFooter }) => {
    const [state, setstate] = useState({
        email: '',
        password: '',
        agree: false
    });
    const [submit, setSubmit] = useState(false);
    const [showPass, setshowPass] = useState(false);
    const [footer, setFooter] = useState(null);

    const handleSubmit = () => {
        setSubmit(true);
        login(state)
            .then((res) => {
                localStorage.setItem('rememberMe', state.agree);
                if (state.agree) {
                    localStorage.setItem('emailUser', encryptAes(state.email));
                    localStorage.setItem('psUser', encryptAes(state.password));
                } else {
                    localStorage.removeItem('emailUser');
                    localStorage.removeItem('psUser');
                }
                const name = res.value.user.fullname;
                Swal.fire({
                    title: 'Success',
                    text: `Welcome ${name}`,
                    icon: 'success',
                    iconColor: '#F09856',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    history.push('/');
                    setSubmit(false);
                });
            })
            .catch((error) => {
                const err = error.response.data;
                if (err.code === 2) {
                    Swal.fire({
                        title: 'Error',
                        text: err.message,
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: 'Login failed, try again',
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
                setSubmit(false);
            });
    };

    const handleChange = ({ target }) => {
        setstate((pref) => ({
            ...pref,
            [target.name]: target.value
        }));
    };

    const responseGoogle = (res) => {
        if (!res.googleId) {
            Swal.fire({
                title: 'Error',
                text: 'Failed login with google',
                timer: 2000,
                showConfirmButton: false,
                icon: 'error'
            });
        } else {
            const params = {
                accessToken: res.tokenId,
                login_by: 'google',
                google_id: res.googleId
            };
            loginRegisterGoogle(params)
                .then((res) => {
                    const name = res.value.user.fullname;
                    Swal.fire({
                        title: 'Success',
                        text: `Welcome ${name}`,
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        history.push('/');
                        setSubmit(false);
                    });
                })
                .catch((err) => {
                    const error = err?.response?.data;
                    console.log('err', error);
                    Swal.fire({
                        title: 'Error',
                        text: 'Login failed, try again',
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                });
        }
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'BuddyMed | Login';
        let rememberMe = localStorage.getItem('rememberMe');
        let emailUser = localStorage.getItem('emailUser');
        let psUser = localStorage.getItem('psUser');
        if (rememberMe === 'true') {
            setstate((pref) => ({
                ...pref,
                email: decryptAes(emailUser),
                password: decryptAes(psUser),
                agree: true
            }));
        }

        getFooter().then((res) => setFooter(res?.value));
    }, [getFooter]);

    return (
        <section className="container-auth bg-auth">
            <div className="container-ilustration flex-column align-items-center p-4">
                <div className="wrap-content">
                    <img alt="signup" src={IlLoginSession} className="w-35" />
                    <p className="m-4 font-weight-light text-white text-center">
                        {footer?.about_text}
                    </p>
                </div>
            </div>
            <Container>
                <Row className="justify-content-center">
                    <Col lg="9" md="9" sm="12" xs="12">
                        <img
                            src={IcBrand}
                            alt="ic-brand"
                            className="d-flex m-auto"
                            width={166}
                            height={166}
                        />

                        <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                            buttonText="Login with Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                            render={(props) => (
                                <Button
                                    variant="contained"
                                    className="btn-google w-100 d-flex align-items-center justify-content-center py-3 mb-4 bg-white"
                                    onClick={props.onClick}
                                    disabled={props.disabled}
                                >
                                    <img
                                        src={IcGoogle}
                                        alt="icon_gooogle"
                                        className="mr-3"
                                    />
                                    Sign in or Sign up with Google
                                </Button>
                            )}
                        />
                        <div className="w-100 position-relative container-or d-flex align-items-center flex-column mb-4">
                            <div className="line w-100"></div>
                            <p className="m-0 mx-auto position-absolute px-3 bg-auth text-white">
                                or
                            </p>
                        </div>
                        <ValidatorForm onSubmit={handleSubmit} className="form">
                            <label htmlFor="email" className="label text-white">
                                Email or Username
                            </label>
                            <TextValidator
                                variant="outlined"
                                placeholder="Type your email or username"
                                type="text"
                                onChange={handleChange}
                                name="email"
                                id="email"
                                value={state.email}
                                validators={['required']}
                                errorMessages={[
                                    'this field is required',
                                    'email is not valid'
                                ]}
                                className="w-100 mb-3"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img
                                                alt="full name"
                                                src={IcUsername}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                            />

                            <label
                                htmlFor="password"
                                className="label text-white"
                            >
                                Password
                            </label>
                            <TextValidator
                                variant="outlined"
                                placeholder="Password"
                                onChange={handleChange}
                                name="password"
                                id="password"
                                value={state.password}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                className="w-100 mb-3"
                                type={showPass ? 'text' : 'password'}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img alt="password" src={IcPass} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    setshowPass(!showPass)
                                                }
                                            >
                                                <img
                                                    alt="password"
                                                    src={
                                                        showPass
                                                            ? IcEyeClose
                                                            : IcEyeOpen
                                                    }
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />

                            <div className="mb-5 d-flex flex-row justify-content-between align-items-center text-forgot">
                                <div>
                                    <Checkbox
                                        name="remember"
                                        id="remember"
                                        className="text-white"
                                        onChange={({ target }) =>
                                            setstate((pref) => ({
                                                ...pref,
                                                agree: target.checked
                                            }))
                                        }
                                        checked={state.agree}
                                    />
                                    <label
                                        htmlFor="remember"
                                        className="m-0 text-white"
                                    >
                                        Remember Me
                                    </label>
                                </div>
                                <Link
                                    to="/forgot_password"
                                    className="font-weight-bold text-white"
                                >
                                    Forgot password?
                                </Link>
                            </div>
                            <Button
                                type="submit"
                                variant="contained"
                                className="mb-4 w-100 font-weight-bold py-2 btn-auth"
                                disabled={submit}
                            >
                                {submit ? (
                                    <CircularProgress
                                        size={24}
                                        className="text-salmon"
                                    />
                                ) : (
                                    'Login'
                                )}
                            </Button>
                        </ValidatorForm>
                        <p className="text-center text-forgot mb-100">
                            New to BuddyMed?{' '}
                            <Link to="/signup" className="text-white">
                                <b>Register now</b>
                            </Link>
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        login: (params) => dispatch(login(params)),
        loginRegisterGoogle: (params) => dispatch(loginRegisterGoogle(params)),
        getFooter: () => dispatch(getFooter())
    };
};

export default connect(null, mapDispatchToProps)(Login);
