import { API, setAuthToken } from '../../config/Api';
import {
    CHAT_DETAIL,
    CREATE_GROUP,
    EDIT_GROUP,
    GET_BREAK_THE_ICE,
    GROP_CHAT,
    GROP_CHAT_DETAIL,
    GROUP_DETAIL,
    INVITE_FRIEND_GROUP,
    KICK_MEMBER,
    LEAVE_GROUP,
    LIST_FRIEND_GROUP,
    LIST_MEMBER_GROUP,
    MESSAGE_CHAT,
    RESPONSE_INVITE_GROUP,
    SEND_MESSAGES,
    SEND_MESSAGES_GROUP
} from '../constant';

export const sendMessages = (params) => {
    return {
        type: SEND_MESSAGES,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.post('user/messages/send', params).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};

export const sendMessagesGroup = (params) => {
    return {
        type: SEND_MESSAGES_GROUP,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.post('user/group/chat/send', params).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};

export const getListFriendGroup = (params) => {
    return {
        type: LIST_FRIEND_GROUP,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get(`user/group/add/member/${params}`).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};

export const createGroup = (params) => {
    return {
        type: CREATE_GROUP,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.post('user/group/create', params).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};

export const inviteListMemberGroup = (params) => {
    return {
        type: INVITE_FRIEND_GROUP,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.post('user/group/invite', params).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};

export const kickMember = (params) => {
    return {
        type: KICK_MEMBER,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.post('user/group/kick', params).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};

export const getListGroup = () => {
    return {
        type: GROP_CHAT,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get('user/group').catch((error) =>
                Promise.reject(error)
            );

            return res.data;
        }
    };
};

export const getListMessage = () => {
    return {
        type: MESSAGE_CHAT,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get('user/messages').catch((error) =>
                Promise.reject(error)
            );

            return res.data;
        }
    };
};

export const getChatDetail = (params) => {
    return {
        type: CHAT_DETAIL,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get(
                `user/messages/chat?users_code=${params}`
            ).catch((error) => Promise.reject(error));

            return res.data;
        }
    };
};

export const getChatGroupDetail = (params) => {
    return {
        type: GROP_CHAT_DETAIL,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get(
                `user/group/chat?group_code=${params}`
            ).catch((error) => Promise.reject(error));
            return res.data;
        }
    };
};

export const getGroupDetail = (params) => {
    return {
        type: GROUP_DETAIL,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get(`user/group/detail/${params}`).catch(
                (error) => Promise.reject(error)
            );

            return res.data;
        }
    };
};

export const getListMemberGroup = (params) => {
    return {
        type: LIST_MEMBER_GROUP,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get(`user/group/member/${params}`).catch(
                (error) => Promise.reject(error)
            );

            return res.data;
        }
    };
};

export const leaveGroup = (params) => {
    return {
        type: LEAVE_GROUP,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.post(`user/group/leave`, params).catch(
                (error) => Promise.reject(error)
            );

            return res.data;
        }
    };
};

export const editGroup = (params) => {
    return {
        type: EDIT_GROUP,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.post('user/group/edit', params).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};

export const responseInvite = (params) => {
    return {
        type: RESPONSE_INVITE_GROUP,
        payload: async () => {
            const token = await localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.post('user/group/accept', params).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};

export const getBreakTheIce = () => {
    return {
        type: GET_BREAK_THE_ICE,
        payload: async () => {
            const token = await localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get('apps/bti').catch((error) =>
                Promise.reject(error)
            );
            return res.data;
        }
    };
};
