import { Button, CircularProgress, Dialog, IconButton, InputAdornment, MenuItem } from '@material-ui/core';
import { CloseRounded, EventRounded, ScheduleRounded } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import { IcConfirmEdit, IcSuccessModal } from '../../assets';
import { addConf, editConf } from '../../store/actions/confAction';

const ModalAddConference = ({
    open,
    handleClose,
    addConf,
    conf,
    editConf,
    msg_parent_code
}) => {
    const [state, setState] = useState({
        conf_name: '',
        conf_desc: '',
        conf_date: '',
        conf_time: '',
        conf_hour: '',
        conf_minute: '',
        conf_id: '',
        messages_parent_code: ''
    });
    const [optionHour] = useState([
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
        20, 21, 22, 23, 24
    ]);
    const [optionMin] = useState([0, 15, 30, 45]);
    const [submit, setSubmit] = useState(false);

    useEffect(() => {
        if (conf) {
            setState({
                conf_name: conf?.conf_topic,
                conf_desc: conf?.conf_desc,
                conf_date: conf?.conf_date_origin,
                conf_time: conf?.start_time,
                conf_hour: conf?.duration_hour,
                conf_minute: conf?.duration_minute,
                conf_id: conf?.conf_id
            });
        } else {
            setState({
                conf_name: '',
                conf_desc: '',
                conf_date: '',
                conf_time: '',
                conf_hour: '',
                conf_minute: '',
                conf_id: ''
            });
        }
    }, [conf, msg_parent_code]);

    const handleChange = (e) => {
        setState((pref) => ({
            ...pref,
            [e.target.name]: e.target.value
        }));
    };

    const handleSubmitEdit = () => {
        setSubmit(true);
        editConf(state)
            .then(() => {
                setSubmit(false);
                Swal.fire({
                    imageUrl: IcSuccessModal,
                    imageWidth: 92,
                    imageHeight: 92,
                    imageAlt: 'icon success',
                    showConfirmButton: false,
                    text: 'Conference successfully edited!',
                    timer: 2000
                }).then(() => {
                    handleClose();
                });
            })
            .catch((error) => {
                const err = error?.response?.data;
                if (err.code === 1) {
                    Swal.fire({
                        title: 'Error',
                        text: err.data[0],
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: 'Failed, try again',
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
                setSubmit(false);
            });
    };
    const handleSubmit = () => {
        const { conf_id } = state;
        if (conf_id) {
            Swal.fire({
                text: 'Are you sure you want to save changes?',
                imageUrl: IcConfirmEdit,
                imageWidth: 92,
                imageHeight: 92,
                imageAlt: 'icon edit',
                showCancelButton: true,
                cancelButtonText: 'No',
                confirmButtonText: 'Yes',
                confirmButtonColor: '#f09856',
                cancelButtonColor: '#EBEAEA'
            }).then((res) => {
                if (res.isConfirmed) {
                    handleSubmitEdit();
                }
            });
        } else {
            setSubmit(true);
            state.messages_parent_code = msg_parent_code;
            addConf(state)
                .then(({ value }) => {
                    setSubmit(false);
                    Swal.fire({
                        imageUrl: IcSuccessModal,
                        imageWidth: 92,
                        imageHeight: 92,
                        imageAlt: 'icon success',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#f09856',
                        text: 'Conference successfully created!'
                    }).then((resSwal) => {
                        if (resSwal.isConfirmed) {
                            window.open(
                                `${window.location.origin}/conference/live/${value?.data?.data?.conf_id}/${value?.data?.data?.password}`,
                                '_blank'
                            );
                        }
                        handleClose();
                    });
                })
                .catch((error) => {
                    const err = error?.response?.data;
                    if (err.code === 1) {
                        Swal.fire({
                            title: 'Error',
                            text: err.data[0],
                            icon: 'error',
                            timer: 2000,
                            showConfirmButton: false
                        });
                    } else {
                        Swal.fire({
                            title: 'Error',
                            text: 'Failed, try again',
                            icon: 'error',
                            timer: 2000,
                            showConfirmButton: false
                        });
                    }
                    setSubmit(false);
                });
        }
    };

    return (
        <Dialog maxWidth="sm" fullWidth open={open} onClose={handleClose}>
            <div className="py-3 px-4">
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="font-weight-bold m-0">
                        {state?.conf_id
                            ? 'Edit Conference'
                            : 'Create New Conference'}
                    </h5>
                    <IconButton onClick={handleClose}>
                        <CloseRounded />
                    </IconButton>
                </div>
                <ValidatorForm onSubmit={handleSubmit}>
                    <Row>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                            <label className="label">Conference Name</label>
                            <TextValidator
                                variant="outlined"
                                placeholder="Conference Name"
                                type="text"
                                name="conf_name"
                                value={state.conf_name}
                                onChange={handleChange}
                                validators={['required']}
                                errorMessages={['this field is required']}
                                className="w-100 mb-3"
                                color="primary"
                            />
                        </Col>
                        <Col xl="12" lg="12" md="12" sm="12" xs="12">
                            <label>Date</label>
                            <TextValidator
                                variant="outlined"
                                type="date"
                                name="conf_date"
                                value={state.conf_date}
                                onChange={handleChange}
                                className="w-100 mb-3"
                                color="primary"
                                endadornment={
                                    <InputAdornment position="end">
                                        <EventRounded />
                                    </InputAdornment>
                                }
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Col>
                        <Col lg="6" md="6" sm="6" xs="12">
                            <label>Time</label>
                            <TextValidator
                                variant="outlined"
                                type="time"
                                name="conf_time"
                                value={state.conf_time}
                                onChange={handleChange}
                                className="w-100 mb-3"
                                color="primary"
                                endadornment={
                                    <InputAdornment position="end">
                                        <ScheduleRounded />
                                    </InputAdornment>
                                }
                                validators={['required']}
                                errorMessages={['this field is required']}
                            />
                        </Col>
                        <Col lg="6" md="6" sm="6" xs="12">
                            <label>Duration</label>
                            <Row>
                                <Col sm="6" xs="6">
                                    <SelectValidator
                                        className="w-100"
                                        variant="outlined"
                                        name="conf_hour"
                                        value={state.conf_hour}
                                        onChange={handleChange}
                                        validators={['required']}
                                        errorMessages={[
                                            'this field is required'
                                        ]}
                                    >
                                        {optionHour.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </SelectValidator>
                                </Col>
                                <Col sm="6" xs="6">
                                    <SelectValidator
                                        placeholder="Minutes"
                                        className="w-100"
                                        variant="outlined"
                                        name="conf_minute"
                                        value={state.conf_minute}
                                        onChange={handleChange}
                                        validators={['required']}
                                        errorMessages={[
                                            'this field is required'
                                        ]}
                                    >
                                        {optionMin.map((item) => (
                                            <MenuItem key={item} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </SelectValidator>
                                </Col>
                            </Row>
                        </Col>
                        <Col lg="12" md="12" sm="12" xs="12">
                            <label>Conference Description</label>
                            <TextValidator
                                variant="outlined"
                                type="text"
                                name="conf_desc"
                                value={state.conf_desc}
                                onChange={handleChange}
                                className="w-100 mb-3"
                                color="primary"
                                validators={['required']}
                                errorMessages={['this field is required']}
                                multiline
                                rows={4}
                                placeholder="Write your conference description here!"
                            />
                        </Col>
                    </Row>
                    <div className="d-flex justify-content-end">
                        <Button
                            type="submit"
                            variant="contained"
                            className="py-2 px-5 text-white"
                            disableElevation
                            disabled={submit}
                            color="primary"
                        >
                            {submit ? (
                                <CircularProgress
                                    size={24}
                                    className="text-white"
                                />
                            ) : state?.conf_id ? (
                                'Save'
                            ) : (
                                'Create'
                            )}
                        </Button>
                    </div>
                </ValidatorForm>
            </div>
        </Dialog>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        addConf: (params) => dispatch(addConf(params)),
        editConf: (params) => dispatch(editConf(params))
    };
};

export default connect(null, mapDispatchToProps)(ModalAddConference);
