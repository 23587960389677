import {
    Button,
    Dialog,
    IconButton,
    InputAdornment,
    TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { connect } from 'react-redux';

import { IcGoogleMeet, IcZoom, IlConfBack, IlConfFront, IlFindFriendsPng } from '../../assets';


const ModalConference = ({
    handleClose,
    open,
    conferenceLink,
    setConferenceLink,
    selected,
    groupConferences,
    handleGoogleMeet,
    handleZoom,
    handleJoin,
}) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm">
            <div className="d-flex justify-content-center align-items-center flex-column">
                <img src={IlConfBack} className="position-absolute w-100 il-conf-back" alt="" />
                <img src={IlConfFront} className="position-absolute w-100  il-conf-front" alt="" />
                <div className="w-100 text-right conf-content">
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </div>
                <div className="text-center conf-content">
                    <div className="rounded-circle p-3 conf-img-buddy">
                        <img
                            src={IlFindFriendsPng}
                            className="img-buddy"
                            alt="find buddy"
                        />
                    </div>
                </div>
                <h6 className="font-weight-bold mt-2 conf-title conf-content">
                    Choose Virtual Meet
                </h6>
                <p className="px-2 text-center w-75 conf-content">
                    Choose one of virtual meet you will use for meetings and chatting with your other buddy
                </p>
            </div>

            <div className="px-4 pt-5 pb-3">
                {!groupConferences.conf_link ? <div className="d-flex justify-content-center align-items-stretch conf-checkboxes">
                    <div
                        className={`d-flex flex-column align-items-center w-50 py-2 conf-checkbox ${selected === 'googlemeet' ? 'conf-checkbox-selected': ''}`}
                        onClick={handleGoogleMeet}
                    >
                        <img
                            src={IcGoogleMeet}
                            alt="Google Meet"
                        />
                        <h6 className="font-weight-bold mb-0 mt-2 text-center conf-checkbox-text">
                            Google Meet
                        </h6>
                    </div>
                    <div
                        className={`d-flex flex-column align-items-center w-50 py-2 conf-checkbox ${selected === 'zoom' ? 'conf-checkbox-selected': ''}`}
                        onClick={handleZoom}
                    >
                        <img
                            src={IcZoom}
                            alt="Zoom"
                        />
                        <h6 className="font-weight-bold mb-0 mt-2 text-center conf-checkbox-text">
                            Zoom
                        </h6>
                    </div>
                </div> : null}
                <div className="d-flex justify-content-center align-items-center flex-column mt-2">
                    <TextField
                        placeholder="Paste your link here..."
                        variant="outlined"
                        type="text"
                        name="conference-link"
                        value={conferenceLink}
                        className="w-100"
                        onChange={(e) => setConferenceLink(e.target.value)}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <p className="m-0 pr-2 conf-before-input">
                                        Link:
                                    </p>
                                </InputAdornment>
                            )
                        }}
                    />
                    <Button
                        variant="contained"
                        className="radius-8 shadow-none text-white btn-card-friend ml-1 mt-2 w-100"
                        color="primary"
                        onClick={handleJoin}
                        disabled={!groupConferences.conf_link ? !conferenceLink : !groupConferences.conf_link}
                    >
                        {!groupConferences.conf_link ? 'Start Meeting' : 'Stop Meeting'}
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default connect(null, null)(ModalConference);
