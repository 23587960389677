/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import introJs from 'intro.js';
import 'intro.js/introjs.css';

import { CardInputLocation, CardInputProfile, CardInputTopic } from '../../components';
import Footer from '../../components/footer/footer';
import {
    getAvatarList,
    getCity,
    getCountry,
    getHobby,
    getLearnType,
    getMovieGenre,
    getMusicType,
    getUniv,
} from '../../store/actions/appAction';
import { checkUsername } from '../../store/actions/authAction';
import { getDetailUser, sendCompleteProfile } from '../../store/actions/userAction';

const CompleteProfile = ({
    getDetailUser,
    checkUsername,
    getCountry,
    sendComplete,
    getUniv,
    getCity,
    getLearnType,
    getHobby,
    getMovieGenre,
    getMusicType,
    getAvatarList
}) => {
    const history = useHistory();
    const [InputForm, setInputForm] = useState({
        fullname: '',
        username: '',
        email: '',
        birthdate: '',
        gender: '',
        country: '',
        city: '',
        university: '',
        academic_year: '',
        learner_type: '',
        hobbies: [],
        music_type: '',
        movie_genre: [],
        country_val: '',
        city_val: '',
        univ_val: '',
        learner_type_val: '',
        hobbies_val: '',
        music_type_val: '',
        movie_genre_val: '',
        is_other: '',
        profile_code: '',
        profile_pict_old: '',
        profile_preview: ''
    });
    const [activeStep, setActiveStep] = useState(0);
    const [submit, setSubmit] = useState(false);
    const [dataCity, setDataCity] = useState([]);
    const [dataCountry, setDataCountry] = useState([]);
    const [dataUniv, setDataUniv] = useState([]);
    const [dataLearnType, setDataLearnType] = useState([]);
    const [dataHobby, setDataHobby] = useState([]);
    const [dataMusicType, setDataMusicType] = useState([]);
    const [dataMovieGenre, setDataMovieGenre] = useState([]);
    const [dataAvatar, setDataAvatar] = useState([]);
    const [openAvatar, setOpenAvatar] = useState(false);

    const getData = () => {
        getDetailUser().then(({ value: { user } }) => {
            setInputForm({
                ...InputForm,
                fullname: user?.fullname,
                username: user?.username,
                email: user?.email,
                profile_preview: user?.profile_pict,
                profile_pict_old: user?.profile_pict_origin
            });
        });
        getCountry().then((res) => {
            setDataCountry(res?.value);
        });
        getLearnType().then((res) => setDataLearnType(res?.value));
        getHobby().then((res) => setDataHobby(res?.value));
        getMusicType().then((res) => setDataMusicType(res?.value));
        getMovieGenre().then((res) => setDataMovieGenre(res?.value));
        getAvatarList().then((res) => setDataAvatar(res?.value));
    };

    useEffect(() => {
        const tourString = window.localStorage.getItem("tour");
        const tour = tourString ? JSON.parse(tourString) : {};
        const email = window.localStorage.getItem("email");
        const myTour = tour[email];

        if (!myTour || !Object.keys(myTour).length) {
            window.localStorage.setItem("tour", JSON.stringify({
                ...tour,
                [email] :  {
                    profile: true,
                    location: true,
                    topic: true
                }
            }));
        }

        const intro = introJs();

        intro.setOptions({
            steps: [
                {
                    element: document.querySelector('[data-step="1"]'),
                    intro: "There will be 3 steps to complete your profile",
                    position: "bottom",
                    scrollTo: "element",
                },
                {
                    element: document.querySelector('[data-step="2"]'),
                    title: 'Step 1 (Profile)',
                    intro: "This form is for your profile information",
                    position: "top",
                    scrollTo: "element"
                },
                {
                    element: document.querySelector('[data-step="3"]'),
                    title: 'Step 1 (Profile)',
                    intro: "Fill in your profile information",
                    position: "top",
                    scrollTo: "element"
                },
                {
                    element: document.querySelector('[data-step="4"]'),
                    title: 'Step 1 (Profile)',
                    intro: "Press next to continue to next step",
                    position: "auto",
                    scrollTo: "element"
                }
            ],
        });

        if (!myTour || !Object.keys(myTour).length || tour[email]?.profile) {
            intro.start();
        }

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'BuddyMed | Complete Profile';
        getData();

        return () => {
            if (!myTour || !Object.keys(myTour).length || tour[email]?.profile) {
                intro.exit();
            }
        }
    }, []);

    const handleChange = ({ target }) => {
        setInputForm({
            ...InputForm,
            [target.name]: target.value
        });
    };

    const handleChangeProfile = (val, pref) => {
        setInputForm({
            ...InputForm,
            profile_code: val,
            profile_preview: pref
        });
        setOpenAvatar(false);
    };

    const handleChangeCountry = (value) => {
        let nameVal = '';
        nameVal = value
            ? dataCountry.filter((item) => item.country_code === value)[0]
            : '';
        setInputForm(
            {
                ...InputForm,
                country: value,
                country_val: nameVal,
                university: '',
                univ_val: '',
                city: '',
                city_val: ''
            },
            getCity(value).then((res) => {
                setDataCity(res?.value);
            })
        );
    };

    const handleChangeCity = (value) => {
        let nameVal = '';
        nameVal = value
            ? dataCity.filter((item) => item.city_code === value)[0]
            : '';
        setInputForm(
            {
                ...InputForm,
                city: value,
                city_val: nameVal,
                university: '',
                univ_val: ''
            },
            getUniv(value).then((res) => {
                setDataUniv(res?.value);
            })
        );
    };

    const handleChangeUniv = (value) => {
        let nameVal = '';
        nameVal = value
            ? dataUniv.filter((item) => item.university_code === value)[0]
            : '';
        setInputForm({
            ...InputForm,
            university: value,
            univ_val: nameVal
        });
    };

    const handleChangeLearn = (value) => {
        let nameVal = '';
        nameVal = value
            ? dataLearnType.filter((item) => item.lt_code === value)[0]
            : '';
        setInputForm({
            ...InputForm,
            learner_type: value,
            learner_type_val: nameVal
        });
    };

    const handleChangeHobby = (value) => {
        setInputForm({
            ...InputForm,
            hobbies: value
        });
    };

    const handleChangeMusic = (value) => {
        let nameVal = '';
        nameVal = value
            ? dataMusicType.filter((item) => item.music_code === value)[0]
            : '';
        setInputForm({
            ...InputForm,
            music_type: value,
            music_type_val: nameVal
        });
    };

    const handleChangeMovie = (value) => {
        setInputForm({
            ...InputForm,
            movie_genre: value
        });
    };

    const handleChangeSelect = (name, value) => {
        setInputForm({
            ...InputForm,
            [name]: value
        });
    };

    const changeUnivOther = ({ target: { value } }) => {
        setInputForm({
            ...InputForm,
            university: value,
            univ_val: ''
        });
    };

    const handleNext = () => {
        setSubmit(true);
        if (activeStep === 0) {
            const params = {
                username: InputForm.username,
                email: InputForm.email
            };
            checkUsername(params)
                .then((res) => {
                    setSubmit(false);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);

                    const tourString = window.localStorage.getItem("tour");
                    const tour = tourString ? JSON.parse(tourString) : {};
                    const email = window.localStorage.getItem("email");
                    window.localStorage.setItem("tour", JSON.stringify({
                        ...tour,
                        [email] :  {
                            profile: false,
                            location: true,
                            topic: true
                        }
                    }));
                })
                .catch((error) => {
                    const err = error?.response?.data;
                    if (err?.code === 1) {
                        Swal.fire({
                            title: 'Error',
                            text: err.data[0],
                            icon: 'error',
                            timer: 2000,
                            showConfirmButton: false
                        });
                    } else {
                        Swal.fire({
                            title: 'Error',
                            text: 'The username has already been taken.',
                            icon: 'error',
                            timer: 2000,
                            showConfirmButton: false
                        });
                    }
                    setSubmit(false);
                });
        } else if (activeStep === 1) {
            if (InputForm.country.length <= 0) {
                Swal.fire({
                    title: 'Error',
                    text: 'Please select your country',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                });
            } else if (InputForm.academic_year.length <= 0) {
                Swal.fire({
                    title: 'Error',
                    text: 'Please select your academic year',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                });
            } else if (InputForm.university.length <= 0) {
                Swal.fire({
                    title: 'Error',
                    text: 'Please select your university',
                    icon: 'error',
                    timer: 2000,
                    showConfirmButton: false
                });
            } else {
                setSubmit(false);
                setActiveStep((prevActiveStep) => prevActiveStep + 1);

                const tourString = window.localStorage.getItem("tour");
                const tour = tourString ? JSON.parse(tourString) : {};
                const email = window.localStorage.getItem("email");
                window.localStorage.setItem("tour", JSON.stringify({
                    ...tour,
                    [email] :  {
                        profile: false,
                        location: false,
                        topic: true
                    }
                }));
            }
        } else {
            setSubmit(false);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);

            const tourString = window.localStorage.getItem("tour");
            const tour = tourString ? JSON.parse(tourString) : {};
            const email = window.localStorage.getItem("email");
            window.localStorage.setItem("tour", JSON.stringify({
                ...tour,
                [email] :  {
                    profile: false,
                    location: false,
                    topic: false
                }
            }));
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmit = () => {
        setSubmit(true);
        sendComplete(InputForm)
            .then(() => {
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    iconColor: '#F09856',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    localStorage.setItem('complete_profile', 1);
                    history.push('/');
                    setSubmit(false);
                });
            })
            .catch((error) => {
                const err = error?.response?.data;
                if (err.code === 1) {
                    Swal.fire({
                        title: 'Error',
                        text: err.data[0],
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: 'Failed, try again',
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
                setSubmit(false);
            });
    };

    const handleChangeOther = ({ target: { checked } }) => {
        setInputForm((pref) => ({
            ...pref,
            is_other: checked,
            university: '',
            univ_val: ''
        }));
    };

    const getStepContent = () => {
        switch (activeStep) {
            case 0:
                return (
                    <CardInputProfile
                        data={InputForm}
                        handleChange={handleChange}
                        handleNext={handleNext}
                        submit={submit}
                        dataAvatar={dataAvatar}
                        handleChangeProfile={handleChangeProfile}
                        openAvatar={openAvatar}
                        setOpenAvatar={(val) => setOpenAvatar(val)}
                    />
                );
            case 1:
                return (
                    <CardInputLocation
                        data={InputForm}
                        handleNext={handleNext}
                        handleBack={handleBack}
                        dataCity={dataCity}
                        dataCountry={dataCountry}
                        dataUniv={dataUniv}
                        handleChangeCountry={handleChangeCountry}
                        handleChangeCity={handleChangeCity}
                        handleChangeUniv={handleChangeUniv}
                        handleChangeSelect={handleChangeSelect}
                        handleChange={handleChange}
                        handleChangeOther={handleChangeOther}
                        changeUnivOther={changeUnivOther}
                    />
                );
            case 2:
                return (
                    <CardInputTopic
                        data={InputForm}
                        handleSubmit={handleSubmit}
                        handleBack={handleBack}
                        dataLearnType={dataLearnType}
                        dataHobby={dataHobby}
                        dataMusicType={dataMusicType}
                        dataMovieGenre={dataMovieGenre}
                        handleChangeLearn={handleChangeLearn}
                        handleChangeHobby={handleChangeHobby}
                        handleChangeMusic={handleChangeMusic}
                        handleChangeMovie={handleChangeMovie}
                        submit={submit}
                    />
                );

            default:
                return (
                    <figure className="d-flex justify-content-center mt-5">
                        <p>done</p>
                    </figure>
                );
        }
    };

    return (
        <div className="pt-4 min-h-screen">
            <Container className="complete-profile">
                <Row>
                    <Col
                        xs="12"
                        sm="12"
                        md="12"
                        lg="12"
                        xl="12"
                        className="mt-3 mb-5"
                    >
                        <h5 className="font-weight-bold m-0 mb-1">
                            Complete Profile
                        </h5>
                        <p>
                            You are so close to finding buddy! Let's complete
                            your profile and interests as the final step
                        </p>
                        <div className="container-stepper" data-step="1">
                            <div
                                className={`content-stepper ${
                                    activeStep === 0
                                        ? 'step-one'
                                        : activeStep === 1
                                        ? 'step-two'
                                        : 'step-three'
                                }`}
                            ></div>
                        </div>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <div className="strepper-custome" data-step="2">
                            {getStepContent()}
                        </div>
                    </Col>
                </Row>
            </Container>
            <Footer />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDetailUser: () => dispatch(getDetailUser()),
        checkUsername: (params) => dispatch(checkUsername(params)),
        getCountry: () => dispatch(getCountry()),
        getCity: (params) => dispatch(getCity(params)),
        sendComplete: (params) => dispatch(sendCompleteProfile(params)),
        getUniv: (params) => dispatch(getUniv(params)),
        getLearnType: () => dispatch(getLearnType()),
        getHobby: () => dispatch(getHobby()),
        getMusicType: () => dispatch(getMusicType()),
        getMovieGenre: () => dispatch(getMovieGenre()),
        getAvatarList: () => dispatch(getAvatarList())
    };
};

export default connect(null, mapDispatchToProps)(CompleteProfile);
