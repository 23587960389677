
import moment from 'moment-timezone';

// export function formatDate(date) {
//     let d = new Date(date),
//         month = '' + (d.getMonth() + 1),
//         day = '' + d.getDate(),
//         year = d.getFullYear();

//     if (month.length < 2) month = '0' + month;
//     if (day.length < 2) day = '0' + day;

//     return [year, month, day].join('-');
// }

export function formatDate(year) {
    const d = new Date();
    const fullYear = d.getFullYear();
    const getAcademicYear = fullYear - +year;

    let academicYear;
    switch (getAcademicYear) {
        case 0:
            academicYear = 'Latest';
            break;

        case 1:
            academicYear = 'First';
            break;

        case 2:
            academicYear = 'Second';
            break;

        case 3:
            academicYear = 'Third';
            break;

        case 4:
            academicYear = 'Fourth';
            break;

        case 5:
            academicYear = 'Fifth';
            break;

        case 6:
            academicYear = 'Sixth';
            break;

        default:
            academicYear = 'Dropped Out';
            break;
    }

    return academicYear;
}

// export const convertToLocal = (date, format) => {
//     let res = moment
//         .utc(date)
//         .local()
//         .format(format || 'YYYY-MM-DD HH:mm:ss');
//     return res;
// };
export const convertToLocal = (date, format) => {
  const utcMoment = moment.utc(date);
   const saudiArabiaOffset = -4; // Offset waktu untuk Arab Saudi (UTC+4)
  const saudiArabiaMoment = utcMoment.clone().add(saudiArabiaOffset, 'hours');
  let res = saudiArabiaMoment.format(format || 'YYYY-MM-DD HH:mm:ss');
  return res;
};
