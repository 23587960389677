import { Button, CircularProgress, Dialog, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import { IcConfirmEdit, IcSuccessModal } from '../../assets';
import { editGroup } from '../../store/actions/chatGroupAction';
import InputFile from '../Input/InputFile';

const ModalGroupChat = ({
    handleClose,
    open,
    maxWidth,
    editGroup,
    group,
    getMessage
}) => {
    const [submit, setSubmit] = useState(false);
    const [State, setState] = useState({
        group_name: '',
        group_desc: '',
        group_pict: '',
        group_preview: '',
        group_code: '',
        group_pict_old: ''
    });

    const handleUploadFile = (name, file) => {
        setState((pref) => ({ ...pref, [name]: file }));
    };

    const confirmSubmit = () => {
        Swal.fire({
            text: 'Are you sure you want to save changes?',
            imageUrl: IcConfirmEdit,
            imageWidth: 92,
            imageHeight: 92,
            imageAlt: 'icon edit',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            confirmButtonColor: '#f09856',
            cancelButtonColor: '#EBEAEA'
        }).then((res) => {
            if (res.isConfirmed) {
                handleSubmit();
            }
        });
    };

    const handleSubmit = () => {
        setSubmit(true);
        editGroup(State)
            .then(() => {
                Swal.fire({
                    text: 'Group successfully edited!',
                    imageUrl: IcSuccessModal,
                    imageWidth: 92,
                    imageHeight: 92,
                    imageAlt: 'icon success',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    handleClose();
                    getMessage();
                    setSubmit(false);
                });
            })
            .catch((error) => {
                const err = error?.response?.data;
                if (err.code === 1) {
                    Swal.fire({
                        title: 'Error',
                        text: err.data[0],
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: 'Failed, try again',
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
                setSubmit(false);
            });
    };

    useEffect(() => {
        if (group) {
            setState({
                group_name: group?.group_name,
                group_desc: group?.group_desc,
                group_preview: group?.group_pict,
                group_pict: '',
                group_code: group?.group_code,
                group_pict_old: group?.group_pict_origin
            });
        }

        // clean
        return () => {
            setState({});
        };
    }, [group]);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth={maxWidth} fullWidth>
            <div className="d-flex justify-content-between align-items-center">
                <DialogTitle className="title-dialog">Edit Group</DialogTitle>

                <IconButton onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </div>

            <div className="px-4 pb-3">
                <ValidatorForm onSubmit={confirmSubmit} className="form">
                    {/* <label className="label">Group Name</label>
                    <TextValidator
                        variant="outlined"
                        placeholder="Group Name"
                        type="text"
                        name="group_name"
                        value={State.group_name}
                        onChange={handleChange}
                        validators={['required']}
                        errorMessages={['this field is required']}
                        className="w-100 mb-3"
                        color="primary"
                    />

                    <label className="label">Group Description</label>
                    <TextValidator
                        variant="outlined"
                        type="text"
                        name="group_desc"
                        value={State.group_desc}
                        onChange={handleChange}
                        className="w-100 mb-3"
                        color="primary"
                        validators={['required']}
                        errorMessages={['this field is required']}
                        multiline
                        rows={4}
                        placeholder="Write your gorup description here!"
                    /> */}

                    <label className="label">Profile Picture</label>
                    <InputFile
                        maxSize={10240}
                        acceptFile="image"
                        preview={State.group_preview}
                        uploadFoto={(file, path) => {
                            handleUploadFile('group_pict', file);
                            handleUploadFile('group_preview', path);
                        }}
                    />

                    <div className="d-flex justify-content-end mt-3">
                        <Button
                            variant="contained"
                            color="primary"
                            disableElevation
                            className="radius-8 text-white px-5"
                            type="submit"
                            disabled={submit}
                        >
                            {submit ? <CircularProgress size={24} /> : 'Save'}
                        </Button>
                    </div>
                </ValidatorForm>
            </div>
        </Dialog>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        editGroup: (params) => dispatch(editGroup(params))
    };
};

export default connect(null, mapDispatchToProps)(ModalGroupChat);
