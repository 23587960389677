import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import {
    IcBack,
    IcClose,
    IcLogout,
    IcModalUnfollow,
    IcSendChat,
    IcSuccessModal
} from '../../assets';
import {
    CardChatDetail,
    CardMessages,
    ModalBTI,
    ModalConference,
    SidebarChat
} from '../../components';
import {
    getChatGroupDetail,
    getGroupDetail,
    getListMemberGroup,
    leaveGroup,
    responseInvite,
    sendMessagesGroup
} from '../../store/actions/chatGroupAction';
import { addConf } from '../../store/actions/confAction';
import { addConfs, deleteConfs, getDetailConferences } from '../../store/actions/confsAction';
import {
    addMatchFriend,
    cancelMatchFriend
} from '../../store/actions/matchAction';

const GroupChat = ({
    match,
    getChatGroupDetail,
    detail_group,
    getGroupDetail,
    list_member_group,
    getListMemberGroup,
    sendMessagesGroup,
    leaveGroup,
    responseInvite,
    addMatchFriend,
    cancelMatchFriend,
    addConf,
    addConfs,
    deleteConfs,
    getDetailConferences,
}) => {
    const [Show, setShow] = useState(false);
    const [messages, setMessages] = useState('');
    const [conversation, setConversation] = useState(null);
    const [confCreated, setConfCreated] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [submitFriend, setsubmitFriend] = useState(false);
    const [submitConf, setSubmitConf] = useState(false);
    const [openBTI, setOpenBTI] = useState(false);
    const [openConference, setOpenConference] = useState(false);
    const [groupConferences, setGroupConferences] = useState({});
    const [conferenceLink, setConferenceLink] = useState("");
    const [selected, setSelected] = useState("");

    let code = match.params.user_code;
    const getUserStorage = localStorage.getItem('users_code');
    const history = useHistory();

    const handleClick = () => setShow(!Show);

    const handleChange = (event) => {
        setMessages(event.target.value);
    };

    // submit chat
    const handleSubmit = (group_code) => {
        setSubmit(true);
        sendMessagesGroup({
            group_code,
            text: messages
        })
            .then(() => {
                setSubmit(false);
                getMesage(true);
                setMessages('');
            })
            .catch((error) => {
                setSubmit(false);
            });
    };

    const scrollToBottom = () => {
        let elemScroll = document.getElementById('chat-area');
        elemScroll?.scrollTo({
            top: elemScroll.scrollHeight + 30,
            behavior: 'smooth'
        });
    };

    const getMesage = useCallback(
        (from_me) => {
            let elemScroll = document.getElementById('chat-area');

            getChatGroupDetail(code).then(({ value: { data } }) => {
                if (
                    elemScroll.offsetHeight + elemScroll.scrollTop >=
                        elemScroll.scrollHeight ||
                    from_me
                ) {
                    setConversation(data?.data);
                    setConfCreated(data?.conf_created);
                    scrollToBottom();
                } else {
                    setConversation(data?.data);
                    setConfCreated(data?.conf_created);
                }
            });
        },
        [code, getChatGroupDetail]
    );

    // leave group
    const confirmLeave = (group_code) => {
        Swal.fire({
            text: 'Are you sure want to leave?',
            imageUrl: IcLogout,
            imageWidth: 92,
            imageHeight: 92,
            imageAlt: 'icon logout',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            confirmButtonColor: '#F09856',
            cancelButtonColor: '#EBEAEA'
        }).then((res) => {
            if (res.isConfirmed) {
                handleLeave(group_code);
            }
        });
    };

    const handleLeave = (group_code) => {
        leaveGroup({ group_code })
            .then(() => {
                Swal.fire({
                    text: `Leave group success!`,
                    imageUrl: IcSuccessModal,
                    imageWidth: 92,
                    imageHeight: 92,
                    imageAlt: 'icon success',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    history.push('/friends');
                });
            })
            .catch((error) => error.message);
    };

    const handleKeyPress = (e) => {
        if (
            (e.key === 'Enter' && e.shiftKey && messages.length <= 0) ||
            (e.key === 'Enter' && messages.length <= 0)
        ) {
            e.preventDefault();
        } else if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(detail_group?.group_code);
        }
    };

    const handleAccept = (
        member_code,
        group_code,
        users_code,
        name,
        status
    ) => {
        Swal.fire({
            text: `Are you sure want ${
                status === 1 ? 'accept' : 'reject'
            } ${name}?`,
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            confirmButtonColor: '#F09856',
            cancelButtonColor: '#EBEAEA'
        }).then((res) => {
            if (res.isConfirmed) {
                responseInvite({ member_code, group_code, users_code, status })
                    .then((res) => {
                        if (res.value.data.success) {
                            Swal.fire({
                                title: 'Success',
                                text: `Success ${
                                    status === 1 ? 'accept' : 'reject'
                                } ${name}`,
                                imageUrl: IcSuccessModal,
                                imageWidth: 92,
                                imageHeight: 92,
                                imageAlt: 'icon success',
                                timer: 2000,
                                showConfirmButton: false
                            }).then(() => {
                                getGroupDetail(code);
                                getListMemberGroup(code);
                            });
                        } else {
                            Swal.fire({
                                title: 'Failed',
                                text: `Failed ${
                                    status === 1 ? 'accept' : 'reject'
                                } ${name}`,
                                icon: 'error',
                                timer: 2000,
                                showConfirmButton: false
                            });
                        }
                    })
                    .catch((er) => {
                        console.log('err', er);
                        Swal.fire({
                            title: 'Failed',
                            text: `Failed ${
                                status === 1 ? 'accept' : 'reject'
                            } ${name}`,
                            icon: 'error',
                            timer: 2000,
                            showConfirmButton: false
                        });
                    });
            }
        });
    };

    const handleMatchUser = (data, status) => {
        setsubmitFriend(true);
        if (status === 1) {
            addMatchFriend({ users_code: data?.users_code })
                .then(() => {
                    getListMemberGroup(code);
                    setsubmitFriend(false);
                })
                .catch(() => {
                    setsubmitFriend(false);
                });
        } else {
            Swal.fire({
                text: 'Are you sure want to unfriend?',
                cancelButtonText: 'No',
                cancelButtonColor: '#F09856',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: '#EBEAEA',
                imageUrl: IcModalUnfollow,
                imageWidth: 92,
                imageHeight: 92,
                imageAlt: 'icon logout'
            }).then((res) => {
                if (res.isConfirmed) {
                    cancelMatchFriend({
                        matched_code: data?.matched_code,
                        status: 0
                    })
                        .then(() => {
                            getListMemberGroup(code);
                            setsubmitFriend(false);
                        })
                        .catch((error) => {
                            console.log(error.message);
                            setsubmitFriend(false);
                        });
                } else {
                    setsubmitFriend(false);
                }
            });
        }
    };

    // const handleStartConference = () => {
    //     setSubmitConf(true);
    //     let date = moment().format('YYYY-MM-DDTHH:mm:ss');

    //     const params = {
    //         conf_date: date,
    //         messages_parent_code: code
    //     };
    //     addConf(params)
    //         .then((res) => {
    //             const value = res?.value;
    //             window.location.href = value?.data?.data?.link_url;
    //             setSubmitConf(false);
    //         })
    //         .catch((error) => {
    //             const err = error.response.data;
    //             if (err.code === 2) {
    //                 Swal.fire({
    //                     title: 'Error',
    //                     text: err.data.msg,
    //                     icon: 'error',
    //                     timer: 2000,
    //                     showConfirmButton: false
    //                 });
    //             } else {
    //                 Swal.fire({
    //                     title: 'Error',
    //                     text: 'failed to create conference, try again later',
    //                     confirmButtonText: 'OK',
    //                     confirmButtonColor: '#F09856'
    //                 });
    //             }
    //             setSubmitConf(false);
    //         });
    // };

    const handleStartConference = async () => {
        setSubmitConf(true);

        const groupConferencesResponse = await getDetailConferences(detail_group.group_code);
        const groupConferences = groupConferencesResponse.value.data;

        const now = moment();
        const lastUpdatedAt = moment(groupConferences.updated_at);
        const diffDateTime = now.diff(lastUpdatedAt);

        if (moment.duration(diffDateTime).get('h') > 0) {
            setConfCreated(false);
            deleteConfs(groupConferences.conf_code);
            setOpenConference(true);
            setSubmitConf(false);
            return;
        }

        if (!groupConferences.conf_link) {
            setConfCreated(false);
            setOpenConference(true);
            setSubmitConf(false);
            return;
        }

        if (getUserStorage !== groupConferences.created_by) {
            window.open(groupConferences.conf_link, '_blank');
            setOpenConference(false);
            setSubmitConf(false);
            return;
        }

        setOpenConference(true);
        setSubmitConf(false);
        return;
    };

    const handleGoogleMeet = () => {
        setSelected("googlemeet");
        window.open('https://meet.new/', '_blank');
    };

    const handleZoom = () => {
        setSelected("zoom");
        window.open('https://us04web.zoom.us/signin#/login', '_blank');
    };

    const handleJoin = () => {
        if (!selected) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please choose the virtual meet type',
                confirmButtonText: 'ok',
                confirmButtonColor: '#f09856'
            });
            return;
        }

        if (!conferenceLink) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Please input virtual meet link',
                confirmButtonText: 'ok',
                confirmButtonColor: '#f09856'
            });
            return;
        }

        const googleMeetUrl = /^https?:\/\/(www\.)?meet\.google\.com\/[a-z]{3}-[a-z]{4}-[a-z]{3}(\?.*)?$/i;
        const zoomUrl = /^https?:\/\/(www\.)?(.*\.)?zoom\.us\/.{1}\/\d+(\?.*)?$/i;

        if (selected === 'googlemeet' && !googleMeetUrl.test(conferenceLink)) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Google Meet link is invalid',
                confirmButtonText: 'ok',
                confirmButtonColor: '#f09856'
            });
            return;
        }

        if (selected === 'zoom' && !zoomUrl.test(conferenceLink)) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Zoom link is invalid',
                confirmButtonText: 'ok',
                confirmButtonColor: '#f09856'
            });
            return;
        }

        if (groupConferences && groupConferences.created_by === getUserStorage) {
            deleteConfs(groupConferences.conf_code);

            Swal.fire({
                title: 'Virtual meet stopped successfully!',
                imageUrl: IcSuccessModal,
                imageWidth: 92,
                imageHeight: 92,
                iconColor: '#f09856',
                imageAlt: 'icon success',
                timer: 2000,
                showConfirmButton: false
            });

            setOpenConference(false);
            setConferenceLink('');
            return;
        }

        addConfs({
            'group_code': detail_group.group_code,
            'conf_link': conferenceLink || groupConferences.conf_link,
            'conf_type': selected || groupConferences.conf_type,
        });
        setConfCreated(true);

        window.open(conferenceLink || groupConferences.conf_link, '_blank');
        setOpenConference(false);
    }


    useEffect(() => {
        document.title = 'BuddyMed | Group';
        getListMemberGroup(code);

        const timer = setInterval(() => {
            getMesage(false);
        }, 10000);

        getMesage(true);
        getGroupDetail(code);

        return () => {
            clearInterval(timer);
        };
    }, [code, getMesage, getUserStorage, getListMemberGroup, getGroupDetail]);

    useEffect(() => {
        const getGroupConferences = async () => {
            const detailConferences = await getDetailConferences(detail_group?.group_code);
            const detailConferencesData = detailConferences.value.data;
            setGroupConferences(detailConferencesData);
            setConferenceLink(detailConferencesData.conf_link);
            setSelected(detailConferencesData.conf_type);
        }

        getGroupConferences();
    }, [getDetailConferences, detail_group?.group_code]);

    console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<',confCreated);

    return (
        <SidebarChat isChat>
            <Container className="container-detail-chat pb-0">
                <Row className="h-100">
                    <Col
                        xs={12}
                        sm={12}
                        md={Show ? 7 : 12}
                        lg={Show ? 7 : 12}
                        xl={Show ? 7 : 12}
                        className={Show ? 'detail-chat-show h-100' : ' h-100'}
                    >
                        <CardChatDetail
                            icon={IcBack}
                            icon_send={IcSendChat}
                            picture={detail_group?.group_pict}
                            name={detail_group?.group_name}
                            username={detail_group?.group_member}
                            conversation={conversation}
                            value={messages}
                            handleClick={handleClick}
                            onChange={handleChange}
                            onSubmit={() =>
                                handleSubmit(detail_group?.group_code)
                            }
                            submit={submit}
                            onKeyPress={handleKeyPress}
                            canChat={detail_group?.can_chat}
                            handleConference={handleStartConference}
                            isConfCreated={confCreated}
                            isAdmin={
                                detail_group?.created_by === getUserStorage
                            }
                            submitConf={submitConf}
                            isGroupChat
                            handleBreak={() => setOpenBTI(true)}
                            groupConferences={groupConferences}
                            users_code={getUserStorage}
                        />
                    </Col>
                    <Col
                        xs={12}
                        sm={12}
                        md={5}
                        lg={5}
                        xl={5}
                        className={`group-detail h-100 ${
                            Show ? 'show-group-detail' : ''
                        }`}
                    >
                        <Card className="border-0 card-group-detail h-100">
                            <div className="d-flex justify-content-between pt-4 px-4">
                                <h5 className="font-weight-bold m-1 title-side-section">
                                    Detail Group
                                </h5>
                                <button
                                    className="btn-title"
                                    onClick={handleClick}
                                >
                                    <img src={IcClose} alt="" />
                                </button>
                            </div>
                            <div className="h-100 overflow-auto px-4">
                                <img
                                    src={detail_group?.group_pict}
                                    alt={detail_group?.group_name}
                                    className="w-100 my-4 rounded thumbnail"
                                />
                                <div className="d-flex justify-content-between mt-4 align-items-center">
                                    <h5 className="font-weight-bold title-side-section m-0">
                                        Group Members
                                    </h5>
                                </div>
                                <div className="my-4">
                                    {list_member_group?.data?.map((member) => (
                                        <CardMessages
                                            key={member.member_code}
                                            name={member.fullname}
                                            username={`@${member.username}`}
                                            picture={member.profile_pict}
                                            statusMember={member?.member_status}
                                            data={member}
                                            handleStatus={handleAccept}
                                            isMember
                                            isMe={
                                                member?.users_code ===
                                                getUserStorage
                                            }
                                            onClick={handleMatchUser}
                                            submitFriend={submitFriend}
                                            canChat={detail_group?.can_chat}
                                        />
                                    ))}
                                </div>
                                <Button
                                    variant="outline-danger py-2 font-weight-bold mb-4 mt-2 w-100"
                                    onClick={() =>
                                        confirmLeave(detail_group?.group_code)
                                    }
                                >
                                    Leave Group
                                </Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {openBTI ? (
                <ModalBTI
                    open={openBTI}
                    handleClose={() => {
                        getMesage(true);
                        setOpenBTI(false);
                    }}
                    code={code}
                    type="GROUP"
                />
            ) : null}
            {openConference ? (
                <ModalConference
                    open={openConference}
                    handleClose={() => setOpenConference(false)}
                    handleGoogleMeet={handleGoogleMeet}
                    handleZoom={handleZoom}
                    handleJoin={handleJoin}
                    conferenceLink={conferenceLink}
                    setConferenceLink={setConferenceLink}
                    selected={selected}
                    groupConferences={groupConferences}
                />
            ) : null}
        </SidebarChat>
    );
};

const mapStateToProps = (state) => {
    return {
        detail_group: state.chat.detail_group,
        list_member_group: state.chat.list_member_group
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getChatGroupDetail: (params) => dispatch(getChatGroupDetail(params)),
        getGroupDetail: (params) => dispatch(getGroupDetail(params)),
        getListMemberGroup: (params) => dispatch(getListMemberGroup(params)),
        sendMessagesGroup: (params) => dispatch(sendMessagesGroup(params)),
        leaveGroup: (params) => dispatch(leaveGroup(params)),
        responseInvite: (params) => dispatch(responseInvite(params)),
        addMatchFriend: (params) => dispatch(addMatchFriend(params)),
        cancelMatchFriend: (params) => dispatch(cancelMatchFriend(params)),
        addConf: (params) => dispatch(addConf(params)),
        addConfs: (params) => dispatch(addConfs(params)),
        deleteConfs: (params) => dispatch(deleteConfs(params)),
        getDetailConferences: (params) => dispatch(getDetailConferences(params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupChat);
