import { API, setAuthToken } from '../../config/Api';
import {
    ADD_CONF,
    CONFERENCE,
    DELETE_CONF,
    EDIT_CONF,
    GET_DETAIL_CONFERENCE
} from '../constant';

export const addConf = (params) => {
    return {
        type: ADD_CONF,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.post('user/conference/group', params).catch(
                (error) => Promise.reject(error)
            );

            return res.data;
        }
    };
};

export const addConfUser = (params) => {
    return {
        type: ADD_CONF,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.post('user/conference/create', params).catch(
                (error) => Promise.reject(error)
            );

            return res.data;
        }
    };
};

export const editConf = (params) => {
    return {
        type: EDIT_CONF,
        payload: async () => {
            const token = await localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.post('user/conference/edit', params).catch(
                (error) => Promise.reject(error)
            );

            return res.data;
        }
    };
};

export const getListConf = () => {
    return {
        type: CONFERENCE,
        payload: async () => {
            const token = await localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get('user/conference').catch((error) =>
                Promise.reject(error)
            );

            return res.data;
        }
    };
};

export const deleteConf = (params) => {
    return {
        type: DELETE_CONF,
        payload: async () => {
            const token = await localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.delete(
                `user/conference/${params}/delete`
            ).catch((error) => Promise.reject(error));

            return res.data;
        }
    };
};

export const getDetailConference = (code) => {
    return {
        type: GET_DETAIL_CONFERENCE,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get('user/conference/' + code).catch(
                (error) => Promise.reject(error)
            );

            return res.data;
        }
    };
};
