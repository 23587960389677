import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import { IcBack, IcSendChat } from '../../assets';
import { CardChatDetail, ModalBTI, SidebarChat } from '../../components';
import {
    getChatDetail,
    sendMessages
} from '../../store/actions/chatGroupAction';
import { addConfUser } from '../../store/actions/confAction';

const Chat = ({ match, getChatDetail, sendMessages, addConfUser }) => {
    let code = match.params.user_code;

    const [detailChat, setDetailChat] = useState(null);
    const [confCreate, setConfCreate] = useState(null);
    const [messages, setMessages] = useState('');
    const [submit, setSubmit] = useState(false);
    const [submitConf, setSubmitConf] = useState(false);
    const [openBTI, setOpenBTI] = useState(false);

    const handleChange = (event) => {
        setMessages(event.target.value);
    };

    const handleSubmit = (messages_parent_code) => {
        setSubmit(true);

        sendMessages({ messages_parent_code, messages: messages })
            .then(() => {
                setSubmit(false);
                getMesage(true);
                setMessages('');
            })
            .catch(() => {
                setSubmit(false);
            });
    };

    const scrollToBottom = () => {
        let elemScroll = document.getElementById('chat-area');
        elemScroll.scrollTo({
            top: elemScroll.scrollHeight + 30,
            behavior: 'smooth'
        });
    };

    const getMesage = useCallback(
        (from_me) => {
            let elemScroll = document.getElementById('chat-area');

            getChatDetail(code).then(({ value: { data } }) => {

           
                if (
                    elemScroll.offsetHeight + elemScroll.scrollTop >=
                        elemScroll.scrollHeight ||
                    from_me
                ) {
                    setDetailChat(data?.data);
                    setConfCreate(data?.conf_created);
                    scrollToBottom();
                } else {
                    setDetailChat(data?.data);
                    setConfCreate(data?.conf_created);
                }
            });
        },
        [code, getChatDetail]
    );

    const handleKeyPress = (e) => {
        if (
            (e.key === 'Enter' && e.shiftKey && messages.length <= 0) ||
            (e.key === 'Enter' && messages.length <= 0)
        ) {
            e.preventDefault();
        } else if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSubmit(detailChat?.messages_parent_code);
        }
    };

    const handleStartConference = () => {
        setSubmitConf(true);
        let date = moment().format('YYYY-MM-DDTHH:mm:ss');

        const params = {
            conf_date: date,
            messages_parent_code: detailChat?.messages_parent_code
        };
        addConfUser(params)
            .then((res) => {
                console.log('res', res);
                const value = res?.value;
                window.location.href = value?.data?.data?.link_url;
                setSubmitConf(false);
            })
            .catch((err) => {
                setSubmitConf(false);
                Swal.fire({
                    title: 'Error',
                    text: 'failed to create conference, try again later',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#F09856'
                });
            });
    };

    useEffect(() => {
        document.title = 'BuddyMed | Chat';

        const timer = setInterval(() => {
            getMesage(false);
        }, 10000);

        getMesage(true);
        getChatDetail(code);

        return () => {
            clearInterval(timer);
        };
    }, [getMesage, code, getChatDetail]);

    return (
        <SidebarChat isChat>
            <Container className="container-detail-chat pb-0">
                <CardChatDetail
                    icon={IcBack}
                    icon_send={IcSendChat}
                    picture={detailChat?.profile_pict}
                    name={detailChat?.fullname}
                    username={detailChat?.username}
                    conversation={detailChat?.conversation}
                    value={messages}
                    onChange={handleChange}
                    onSubmit={() =>
                        handleSubmit(detailChat?.messages_parent_code)
                    }
                    submit={submit}
                    onKeyPress={handleKeyPress}
                    handleConference={handleStartConference}
                    isPersonalChat
                    submitConf={submitConf}
                    isConfCreated={confCreate}
                    handleBreak={() => setOpenBTI(true)}
                />
            </Container>
            {openBTI ? (
                <ModalBTI
                    open={openBTI}
                    handleClose={() => {
                        getMesage(true);
                        setOpenBTI(false);
                    }}
                    code={detailChat?.messages_parent_code}
                    type="PERSONAL"
                />
            ) : null}
        </SidebarChat>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getChatDetail: (params) => dispatch(getChatDetail(params)),
        sendMessages: (params) => dispatch(sendMessages(params)),
        addConfUser: (params) => dispatch(addConfUser(params))
    };
};

export default connect(null, mapDispatchToProps)(Chat);
