import Swal from 'sweetalert2';

import { API, setAuthToken } from '../../config/Api';
import {
    ADD_TOPIC,
    COMPLETE_PROFILE,
    DETAIL_USER,
    DETAIL_USER_CODE,
    DETAIL_USERNAME,
    EDIT_PASSWORD,
    EDIT_PROFILE,
    EDIT_TOPIC,
    GET_NOTIF,
    GET_SIGNATUR,
    READ_NOTIF,
} from '../constant';

export const getDetailUserCode = (params) => {
    return {
        type: DETAIL_USER_CODE,
        payload: async () => {
            const token = await localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get(`user/profile/${params}`).catch(
                (error) => {
                    return Promise.reject(error);
                }
            );

            return res.data.data;
        }
    };
};

export const getDetailUser = () => {
    return {
        type: DETAIL_USER,
        payload: async () => {
            const token = await localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get(`user/profile`).catch((error) => {
                if (error.response.status === 401) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Login Session Ended',
                        text: 'Your login session has ended, try login again',
                        showConfirmButton: true,
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f09856'
                    }).then(() => {
                        let remember = localStorage.getItem('rememberMe');
                        let emailUser, psUser;
                        if (remember === 'true') {
                            emailUser = localStorage.getItem('emailUser');
                            psUser = localStorage.getItem('psUser');
                        }
                        localStorage.clear();
                        localStorage.setItem('rememberMe', remember);
                        if (remember === 'true') {
                            localStorage.setItem('emailUser', emailUser);
                            localStorage.setItem('psUser', psUser);
                        }
                        window.location.href = '/login';
                    });
                }
                return Promise.reject(error);
            });

            if (res.data.code === 0) {
                localStorage.setItem('email', res.data.data.email);
                localStorage.setItem('users_code', res.data.data.users_code);
                localStorage.setItem('username', res.data.data.username);
                localStorage.setItem(
                    'complete_profile',
                    res.data.data.complete_profile
                );
                localStorage.setItem(
                    'verification_status',
                    res.data.data.verification_status
                );

                const result = {
                    user: res.data.data
                };
                return result;
            } else {
                let remember = localStorage.getItem('rememberMe');
                let emailUser, psUser;
                if (remember === 'true') {
                    emailUser = localStorage.getItem('emailUser');
                    psUser = localStorage.getItem('psUser');
                }
                localStorage.clear();
                localStorage.setItem('rememberMe', remember);
                if (remember === 'true') {
                    localStorage.setItem('emailUser', emailUser);
                    localStorage.setItem('psUser', psUser);
                }

                const result = {
                    user: null
                };
                return result;
            }
        }
    };
};

export const sendCompleteProfile = (params) => {
    return {
        type: COMPLETE_PROFILE,
        payload: async () => {
            localStorage.setItem('username', params.username);
            const res = await API.post('user/complete_profile', params).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};

export const sendEditProfile = (params) => {
    return {
        type: EDIT_PROFILE,
        payload: async () => {
            const res = await API.post('user/profile/edit', params).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};

export const sendEditPass = (params) => {
    return {
        type: EDIT_PASSWORD,
        payload: async () => {
            const res = await API.post('user/reset_password', params).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};

export const getDetailUsername = (params) => {
    return {
        type: DETAIL_USERNAME,
        payload: async () => {
            const token = await localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get(`user/profile/by/${params}`).catch(
                (error) => {
                    return Promise.reject(error);
                }
            );

            return res.data.data;
        }
    };
};

export const getNotif = () => {
    return {
        type: GET_NOTIF,
        payload: async () => {
            const token = await localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get(`user/notification`).catch((error) => {
                return Promise.reject(error);
            });

            return res.data.data;
        }
    };
};

export const readNotif = () => {
    return {
        type: READ_NOTIF,
        payload: async () => {
            const token = await localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get(`user/notification/read`).catch(
                (error) => {
                    return Promise.reject(error);
                }
            );

            return res.data.data;
        }
    };
};

export const getSignature = (params) => {
    return {
        type: GET_SIGNATUR,
        payload: async () => {
            const token = await localStorage.getItem('token');
            setAuthToken(token);
            const res = await API.post(
                'user/conference/signature',
                params
            ).catch((err) => {
                return Promise.reject(err);
            });
            return res.data;
        }
    };
};

export const editTopic = (params) => {
    return {
        type: EDIT_TOPIC,
        payload: async () => {
            const res = await API.post('user/topic/edit', params).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};

export const addTopic = (params) => {
    return {
        type: ADD_TOPIC,
        payload: async () => {
            const res = await API.post('user/topic/add', params).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};
