import {
    ACCEPT_MATCH,
    ADD_MATCH,
    CANCEL_MATCH,
    DISCOVER_FRIENDS,
    GET_RECENT_MATCH,
    MATCHED_FRIENDS,
    REQUEST_MATCHED_FRIENDS,
} from '../constant';

const initialState = {
    discover_friends: null,
    matched_friends: null,
    request_matched_friends: null,
    add_match: [],
    cancel_match: [],
    accept_match: [],
    recent_match: []
};

const matchReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${ADD_MATCH}_PENDING`:
            return {
                ...state,
                add_match: []
            };
        case `${ADD_MATCH}_FULFILLED`:
            return {
                ...state,
                add_match: action.payload
            };
        case `${ADD_MATCH}_REJECTED`:
            return {
                ...state,
                add_match: []
            };

        case `${ACCEPT_MATCH}_PENDING`:
            return {
                ...state,
                accept_match: []
            };
        case `${ACCEPT_MATCH}_FULFILLED`:
            return {
                ...state,
                accept_match: action.payload
            };
        case `${ACCEPT_MATCH}_REJECTED`:
            return {
                ...state,
                accept_match: []
            };

        case `${CANCEL_MATCH}_PENDING`:
            return {
                ...state,
                cancel_match: []
            };
        case `${CANCEL_MATCH}_FULFILLED`:
            return {
                ...state,
                cancel_match: action.payload
            };
        case `${CANCEL_MATCH}_REJECTED`:
            return {
                ...state,
                cancel_match: []
            };

        case `${DISCOVER_FRIENDS}_PENDING`:
            return {
                ...state,
                discover_friends: null
            };
        case `${DISCOVER_FRIENDS}_FULFILLED`:
            return {
                ...state,
                discover_friends: action.payload
            };
        case `${DISCOVER_FRIENDS}_REJECTED`:
            return {
                ...state,
                discover_friends: []
            };

        case `${MATCHED_FRIENDS}_PENDING`:
            return {
                ...state,
                matched_friends: null
            };
        case `${MATCHED_FRIENDS}_FULFILLED`:
            return {
                ...state,
                matched_friends: action.payload
            };
        case `${MATCHED_FRIENDS}_REJECTED`:
            return {
                ...state,
                matched_friends: []
            };

        case `${REQUEST_MATCHED_FRIENDS}_PENDING`:
            return {
                ...state,
                request_matched_friends: null
            };
        case `${REQUEST_MATCHED_FRIENDS}_FULFILLED`:
            return {
                ...state,
                request_matched_friends: action.payload
            };
        case `${REQUEST_MATCHED_FRIENDS}_REJECTED`:
            return {
                ...state,
                request_matched_friends: []
            };

        case `${GET_RECENT_MATCH}_PENDING`:
            return {
                ...state,
                recent_match: null
            };
        case `${GET_RECENT_MATCH}_FULFILLED`:
            return {
                ...state,
                recent_match: action.payload
            };
        case `${GET_RECENT_MATCH}_REJECTED`:
            return {
                ...state,
                recent_match: []
            };

        default:
            return state;
    }
};

export default matchReducer;
