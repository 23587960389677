import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { IcClose } from '../../assets';

const ModalEditAvatar = ({ open, handleClose, data, handleChange, value }) => {
    const [selected, setSelected] = useState('');
    const [preview, setPreview] = useState('');
    useEffect(() => {
        setSelected(value);
    }, [value]);
    return (
        <Dialog
            scroll="paper"
            maxWidth="sm"
            fullWidth
            open={open}
            onClose={handleClose}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
        >
            <DialogTitle>
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="font-weight-bold mb-0">
                        Select Your Interest
                    </h5>
                    <IconButton onClick={handleClose} size="small">
                        <img src={IcClose} alt="icon close" />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent dividers="paper">
                <Row>
                    <Col
                        lg="12"
                        md="12"
                        sm="12"
                        xs="12"
                        className="grid-avatar"
                    >
                        {data?.map((item) => (
                            <div
                                key={item.idx}
                                className={`d-flex flex-column align-items-center item-avatar mb-2 ${
                                    selected === item.profile_code
                                        ? 'active'
                                        : ''
                                }`}
                            >
                                <img
                                    src={item.profile_code_img}
                                    alt="img 1"
                                    className={item.name}
                                    onClick={() => {
                                        setSelected(item.profile_code);
                                        setPreview(item.profile_code_img);
                                    }}
                                />
                                <h6 className="font-weight-bold mb-0 mt-2 text-center">
                                    {item.name}
                                </h6>
                            </div>
                        ))}
                    </Col>
                </Row>
            </DialogContent>
            <DialogActions className="d-flex justify-content-center align-items-center">
                <Button
                    variant="contained"
                    color="primary"
                    disableElevation
                    className="text-white px-5"
                    onClick={() => handleChange(selected, preview)}
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
};
export default ModalEditAvatar;
