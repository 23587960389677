import { Button, CircularProgress, IconButton, InputAdornment } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import GoogleLogin from 'react-google-login';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import { IcBrand, IcEmail, IcEyeClose, IcEyeOpen, IcGoogle, IcPass, IcPerson, IcUsername } from '../../assets';
import { IlLoginSession } from '../../assets/ilustrations';
import { loginRegisterGoogle, signup } from '../../store/actions/authAction';
import { getFooter } from '../../store/actions/footerAction';

const Signup = ({ history, signup, loginRegisterGoogle, getFooter }) => {
    const [state, setstate] = useState({
        fullname: '',
        username: '',
        email: '',
        password: '',
        password_confirmation: '',
        agree: true
    });
    const [submit, setSubmit] = useState(false);
    const [showPass, setshowPass] = useState(false);
    const [showPassConf, setshowPassConf] = useState(false);
    const [footer, setFooter] = useState(null);

    const handleSubmit = () => {
        setSubmit(true);
        signup(state)
            .then(() => {
                Swal.fire({
                    title: 'Success',
                    icon: 'success',
                    iconColor: '#F09856',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    history.push('/');
                    setSubmit(false);
                });
            })
            .catch((error) => {
                const err = error?.response?.data;
                if (err.code === 1) {
                    Swal.fire({
                        title: 'Error',
                        text: err.data[0],
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: 'Failed register, try again',
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
                setSubmit(false);
            });
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'BuddyMed | Signup';
        getFooter().then((res) => setFooter(res?.value));
    }, [getFooter]);

    useEffect(() => {
        ValidatorForm.addValidationRule('isPassMatch', (value) => {
            if (value !== state.password) {
                return false;
            }
            return true;
        });
    }, [state]);

    const responseGoogle = (res) => {
        if (!res.googleId) {
            Swal.fire({
                title: 'Error',
                text: 'Failed login with google',
                timer: 2000,
                showConfirmButton: false,
                icon: 'error'
            });
        } else {
            const params = {
                accessToken: res.tokenId,
                login_by: 'google',
                google_id: res.googleId
            };
            loginRegisterGoogle(params)
                .then((res) => {
                    const name = res.value.user.fullname;
                    Swal.fire({
                        title: 'Success',
                        text: `Welcome ${name}`,
                        icon: 'success',
                        timer: 2000,
                        showConfirmButton: false
                    }).then(() => {
                        history.push('/');
                        setSubmit(false);
                    });
                })
                .catch((err) => {
                    const error = err.response?.data;
                    console.log('err', error);
                    Swal.fire({
                        title: 'Error',
                        text: 'Login failed, try again',
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                });
        }
    };

    return (
        <section className="container-auth bg-auth">
            <div className="container-ilustration flex-column align-items-center p-4">
                <div className="wrap-content">
                    <img alt="signup" src={IlLoginSession} className="w-35" />
                    <p className="m-4 font-weight-light text-white text-center">
                        {footer?.about_text}
                    </p>
                </div>
            </div>
            <Container>
                <Row className="justify-content-center">
                    <Col lg="9" md="9" sm="12" xs="12">
                        <img
                            src={IcBrand}
                            alt="ic-brand"
                            className="d-flex m-auto"
                            width={166}
                            height={166}
                        />
                        <GoogleLogin
                            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                            buttonText="Login with Google"
                            onSuccess={responseGoogle}
                            onFailure={responseGoogle}
                            cookiePolicy={'single_host_origin'}
                            render={(props) => (
                                <Button
                                    variant="contained"
                                    className="btn-google w-100 d-flex align-items-center justify-content-center py-3 mb-4 bg-white"
                                    onClick={props.onClick}
                                    disabled={props.disabled}
                                >
                                    <img
                                        src={IcGoogle}
                                        alt="icon_gooogle"
                                        className="mr-3"
                                    />
                                    Sign in or Sign up with Google
                                </Button>
                            )}
                        />
                        <div className="w-100 position-relative container-or d-flex align-items-center flex-column mb-4">
                            <div className="line w-100"></div>
                            <p className="m-0 mx-auto position-absolute px-3 text-white bg-auth">
                                or
                            </p>
                        </div>
                        <ValidatorForm onSubmit={handleSubmit} className="form">
                            <label
                                htmlFor="fullname"
                                className="label text-white"
                            >
                                Full Name
                            </label>
                            <TextValidator
                                variant="outlined"
                                placeholder="Full Name"
                                onChange={(e) =>
                                    setstate((params) => ({
                                        ...params,
                                        fullname: e.target.value
                                    }))
                                }
                                name="fullname"
                                id="fullname"
                                value={state.fullname}
                                validators={['required', 'minStringLength: 2']}
                                errorMessages={[
                                    'this field is required',
                                    'Min length full name is 2 character'
                                ]}
                                className="w-100 mb-3"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img
                                                alt="full name"
                                                src={IcPerson}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <label
                                htmlFor="username"
                                className="label text-white"
                            >
                                Username
                            </label>
                            <TextValidator
                                variant="outlined"
                                placeholder="Username"
                                onChange={(e) =>
                                    setstate((params) => ({
                                        ...params,
                                        username: e.target.value
                                    }))
                                }
                                name="username"
                                id="username"
                                value={state.username}
                                validators={['required', 'minStringLength: 3']}
                                errorMessages={[
                                    'this field is required',
                                    'Min length username is 3 character'
                                ]}
                                className="w-100 mb-3"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img
                                                alt="username"
                                                src={IcUsername}
                                            />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <label htmlFor="email" className="label text-white">
                                Email
                            </label>
                            <TextValidator
                                variant="outlined"
                                placeholder="Email address"
                                onChange={(e) =>
                                    setstate((params) => ({
                                        ...params,
                                        email: e.target.value
                                    }))
                                }
                                name="email"
                                id="email"
                                value={state.email}
                                validators={['required']}
                                errorMessages={[
                                    'this field is required',
                                    'email is not valid'
                                ]}
                                className="w-100 mb-3"
                                type="email"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img alt="email" src={IcEmail} />
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <label
                                htmlFor="password"
                                className="label text-white"
                            >
                                Password
                            </label>
                            <TextValidator
                                variant="outlined"
                                placeholder="Password"
                                onChange={(e) =>
                                    setstate((params) => ({
                                        ...params,
                                        password: e.target.value
                                    }))
                                }
                                name="password"
                                id="password"
                                value={state.password}
                                validators={[
                                    'required',
                                    'minStringLength: 8',
                                    'maxStringLength: 15'
                                ]}
                                errorMessages={[
                                    'this field is required',
                                    'Min length password is 8 character',
                                    'Max character for password is 15 character'
                                ]}
                                className="w-100 mb-3"
                                type={showPass ? 'text' : 'password'}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img alt="password" src={IcPass} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    setshowPass(!showPass)
                                                }
                                            >
                                                <img
                                                    alt="password"
                                                    src={
                                                        showPass
                                                            ? IcEyeClose
                                                            : IcEyeOpen
                                                    }
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <label
                                htmlFor="password_confirmation"
                                className="label text-white"
                            >
                                Confirm Password
                            </label>
                            <TextValidator
                                variant="outlined"
                                placeholder="Re-type your password"
                                onChange={(e) =>
                                    setstate((params) => ({
                                        ...params,
                                        password_confirmation: e.target.value
                                    }))
                                }
                                name="password_confirmation"
                                id="password_confirmation"
                                value={state.password_confirmation}
                                validators={['required', 'isPassMatch']}
                                errorMessages={[
                                    'this field is required',
                                    'Password not match'
                                ]}
                                className="w-100 mb-3"
                                type={showPassConf ? 'text' : 'password'}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <img alt="password" src={IcPass} />
                                        </InputAdornment>
                                    ),
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() =>
                                                    setshowPassConf(
                                                        !showPassConf
                                                    )
                                                }
                                            >
                                                <img
                                                    alt="password"
                                                    src={
                                                        showPassConf
                                                            ? IcEyeClose
                                                            : IcEyeOpen
                                                    }
                                                />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                            <div className="d-flex mb-5 align-items-center"></div>
                            <Button
                                variant="contained"
                                className="mb-4 w-100 font-weight-bold py-2 btn-auth"
                                type="submit"
                                disabled={submit || !state.agree}
                            >
                                {submit ? (
                                    <CircularProgress
                                        size={24}
                                        className="text-salmon"
                                    />
                                ) : (
                                    'Sign Up'
                                )}
                            </Button>
                        </ValidatorForm>
                        <p className="text-center text-forgot mb-100">
                            Already have an account?{' '}
                            <Link to="/login" className="text-white">
                                <b>Login here</b>
                            </Link>
                        </p>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        signup: (params) => dispatch(signup(params)),
        loginRegisterGoogle: (params) => dispatch(loginRegisterGoogle(params)),
        getFooter: () => dispatch(getFooter())
    };
};

export default connect(null, mapDispatchToProps)(Signup);
