import { Button, CircularProgress, Hidden, Table, TableBody, TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { IcChat, IcModalLogout, IcUnfollow } from '../../assets';
import { Sidebar } from '../../components';
import { logout } from '../../store/actions/authAction';
import { addMatchFriend, cancelMatchFriend } from '../../store/actions/matchAction';
import { getDetailUser, getDetailUsername } from '../../store/actions/userAction';
import { getAge } from '../../utils/getAge';

const Profile = ({
    match,
    getDetailUsername,
    userLogin,
    addMatchFriend,
    cancelMatchFriend,
    logout
}) => {
    let code = match.params.username;
    const history = useHistory();
    const [submit, setSubmit] = useState(false);
    const [detailUser, setDetailUser] = useState(null);

    const updatePageData = useCallback(() => {
        getDetailUsername(code).then(({ value }) => {
            setDetailUser(value);
            setSubmit(false);
        });
    }, [code, getDetailUsername]);

    const handleMatchUser = () => {
        setSubmit(true);
        addMatchFriend({ users_code: detailUser?.users_code })
            .then(() => {
                updatePageData();
            })
            .catch((error) => {
                setSubmit(false);
                console.log(error.message);
            });
    };

    const handleCancleMatch = () => {
        setSubmit(true);
        cancelMatchFriend({ matched_code: detailUser?.matched_code, status: 0 })
            .then(() => {
                updatePageData();
            })
            .catch((error) => {
                console.log(error.message);
                setSubmit(false);
            });
    };

    const handleLogout = () => {
        Swal.fire({
            text: 'Are you sure want to logout?',
            imageUrl: IcModalLogout,
            imageWidth: 92,
            imageHeight: 92,
            imageAlt: 'icon logout',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            confirmButtonColor: '#EBEAEA',
            cancelButtonColor: '#f09856'
        }).then((res) => {
            if (res.isConfirmed) {
                logout().then(() => history.push('/login'));
            }
        });
    };

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'BuddyMed | Profile';
        updatePageData();
    }, [code, updatePageData]);

    return (
        <Sidebar>
            <div className="mt-3">
                <Card className="radius-12 card-profile border-0 mb-3">
                    <Container className="container-info py-3">
                        <Row className="align-items-center container-row">
                            <Col lg="2" md="3" sm="4" xs="12">
                                {detailUser ? (
                                    <img
                                        alt={detailUser?.fullname}
                                        className="img-profile"
                                        src={detailUser?.profile_pict}
                                    />
                                ) : (
                                    <div className="mb-3 skeleteon-wave">
                                        <Skeleton
                                            animation="wave"
                                            variant="circle"
                                            width={120}
                                            height={120}
                                        />
                                    </div>
                                )}
                            </Col>
                            <Col lg="5" md="4" sm="8" xs="12" className="mt-2">
                                {detailUser ? (
                                    <Fragment>
                                        <h5 className="text-nama m-0 font-weight-bold mb-1">
                                            {detailUser?.fullname}
                                        </h5>
                                        {detailUser?.users_code ===
                                        userLogin?.users_code ? (
                                            <p className="text-username m-0">
                                                {detailUser?.email}
                                            </p>
                                        ) : null}
                                        <p className="text-lokasi">
                                            {`@${
                                                detailUser?.username
                                            } | ${getAge(
                                                detailUser?.birthdate
                                            )} ${
                                                getAge(detailUser?.birthdate) >
                                                2
                                                    ? 'years'
                                                    : 'year'
                                            } | ${
                                                detailUser?.gender === 1
                                                    ? 'Male'
                                                    : 'Female'
                                            }`}
                                        </p>
                                    </Fragment>
                                ) : (
                                    <div className="skeleteon-wave">
                                        <Skeleton
                                            animation="wave"
                                            width={150}
                                            height={18}
                                        />
                                        <Skeleton
                                            animation="wave"
                                            width={100}
                                            height={13}
                                        />
                                        <Skeleton
                                            animation="wave"
                                            width={200}
                                            height={13}
                                        />
                                    </div>
                                )}
                            </Col>
                            <Col
                                lg="5"
                                md="5"
                                sm="12"
                                xs="12"
                                className="my-3 mb-0"
                            >
                                {detailUser?.matched_status === 1 ? (
                                    <div className="d-flex align-items-center justify-content-center">
                                        <button
                                            className="radius-8 shadow-none text-white btn-card btn-unfollow"
                                            onClick={handleCancleMatch}
                                        >
                                            <img
                                                src={IcUnfollow}
                                                alt="icon unfollow"
                                            />
                                        </button>
                                        <Link
                                            to={`/chat/${detailUser?.users_code}`}
                                        >
                                            <button
                                                className="radius-8 shadow-none text-white btn-card btn-chat ml-1"
                                                disabled={submit}
                                            >
                                                <img
                                                    src={IcChat}
                                                    alt="icon chat"
                                                    width={45}
                                                    height={45}
                                                />
                                            </button>
                                        </Link>
                                    </div>
                                ) : detailUser?.matched_status === 2 ? (
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        className="radius-8 btn-edit-profile border-error text-error btn-outline-error"
                                        onClick={handleCancleMatch}
                                        disabled={submit}
                                    >
                                        {submit ? (
                                            <CircularProgress
                                                size={24}
                                                color="#eb5757"
                                            />
                                        ) : (
                                            'Cancel'
                                        )}
                                    </Button>
                                ) : detailUser &&
                                  detailUser?.users_code ===
                                      userLogin?.users_code ? (
                                    <Row>
                                        <Col lg="6" md="12" sm="12" xs="12">
                                            <Button
                                                variant="contained"
                                                className="btn-edit-profile text-white rounded-silinder mb-2 btn_buddy"
                                                onClick={() =>
                                                    history.push(
                                                        '/edit_profile'
                                                    )
                                                }
                                            >
                                                Edit Profile
                                            </Button>
                                        </Col>
                                        <Col lg="6" md="12" sm="12" xs="12">
                                            <Button
                                                variant="contained"
                                                className="btn-edit-profile text-white rounded-silinder mb-2 btn_buddy"
                                                onClick={() =>
                                                    history.push(
                                                        '/edit_password'
                                                    )
                                                }
                                            >
                                                Edit Password
                                            </Button>
                                        </Col>
                                    </Row>
                                ) : detailUser?.matched_status === 0 ? (
                                    <Button
                                        variant="contained"
                                        className="btn-edit-profile text-white rounded-silinder"
                                        color="primary"
                                        onClick={handleMatchUser}
                                        disabled={submit}
                                    >
                                        {submit ? (
                                            <CircularProgress size={24} />
                                        ) : (
                                            'Add Buddy'
                                        )}
                                    </Button>
                                ) : null}
                            </Col>
                        </Row>
                    </Container>
                </Card>
                {detailUser && (
                    <Row className="mb-5">
                        <Col lg="12" md="12" sm="12" xs="12" className="mt-4">
                            <Card className="p-3 radius-12 h-100 border-0">
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell className="border-0 py-2 pl-0">
                                                <p className="font-weight-normal m-0">
                                                    University
                                                </p>
                                            </TableCell>
                                            <TableCell className="border-0 py-2">
                                                <h6 className="font-weight-bold m-0">
                                                    {detailUser?.university}
                                                </h6>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="border-0 py-2 pl-0">
                                                <p className="font-weight-normal m-0">
                                                    Major
                                                </p>
                                            </TableCell>
                                            <TableCell className="border-0 py-2">
                                                <h6 className="font-weight-bold m-0">
                                                    {detailUser?.academic_year}
                                                </h6>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="border-0 py-2 pl-0">
                                                <p className="font-weight-normal m-0">
                                                    Country
                                                </p>
                                            </TableCell>
                                            <TableCell className="border-0 py-2">
                                                <h6 className="font-weight-bold m-0">
                                                    {detailUser?.country}
                                                </h6>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="border-0 py-2 pl-0">
                                                <p className="font-weight-normal m-0">
                                                    City
                                                </p>
                                            </TableCell>
                                            <TableCell className="border-0 py-2">
                                                <h6 className="font-weight-bold m-0">
                                                    {detailUser?.city}
                                                </h6>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="border-0 py-2 pl-0">
                                                <p className="font-weight-normal m-0">
                                                    Type of learner
                                                </p>
                                            </TableCell>
                                            <TableCell className="border-0 py-2">
                                                <h6 className="font-weight-bold m-0">
                                                    {detailUser?.learner_type}
                                                </h6>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="border-0 py-2 pl-0">
                                                <p className="font-weight-normal m-0">
                                                    Hobby
                                                </p>
                                            </TableCell>
                                            <TableCell className="border-0 py-2 d-flex">
                                                <h6 className="font-weight-bold m-0">
                                                    {detailUser?.hobbies
                                                        ?.map(
                                                            (item) =>
                                                                item?.hobbies_name
                                                        )
                                                        .join(', ')}
                                                </h6>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell className="border-0 py-2 pl-0">
                                                <p className="font-weight-normal m-0">
                                                    Movie genre
                                                </p>
                                            </TableCell>
                                            <TableCell className="border-0 py-2 d-flex">
                                                <h6 className="font-weight-bold m-0">
                                                    {detailUser?.movie_genre
                                                        ?.map(
                                                            (item, index) =>
                                                                item.genre_name
                                                        )
                                                        .join(', ')}
                                                </h6>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </Card>
                        </Col>
                    </Row>
                )}
                <Hidden mdUp>
                    <button className="btn-logout-sm" onClick={handleLogout}>
                        Logout
                    </button>
                </Hidden>
            </div>
        </Sidebar>
    );
};

const mapStateToProps = (state) => {
    return {
        userLogin: state.user.user,
        detailUsers: state.user.user_detail_code,
        matched: state.match.matched_friends,
        request_matched_friends: state.match.request_matched_friends
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDetailUsername: (params) => dispatch(getDetailUsername(params)),
        getDetailUser: () => dispatch(getDetailUser()),
        addMatchFriend: (params) => dispatch(addMatchFriend(params)),
        cancelMatchFriend: (params) => dispatch(cancelMatchFriend(params)),
        logout: () => dispatch(logout())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
