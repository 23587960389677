import { connectRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';

import app from './appReducer';
import auth from './authReducer';
import chat from './chatGroupReducer';
import conf from './confReducer';
import confs from './confsReducer';
import match from './matchReducer';
import user from './userReducer';
import findBuddy from './buddyReducer';
import footer from './footerReducer';

export const history = createBrowserHistory();
export default combineReducers({
    router: connectRouter(history),
    auth,
    user,
    conf,
    confs,
    match,
    chat,
    app,
    findBuddy,
    footer
});
