import { Button, CircularProgress, IconButton, InputAdornment } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import { IcConfirmEdit, IcEyeClose, IcEyeOpen, IcSuccessModal } from '../../assets';
import { Sidebar } from '../../components';
import { getDetailUser, sendEditPass } from '../../store/actions/userAction';

const EditPassword = ({ sendEditPass, getDetailUser }) => {
    const [params, setParams] = useState({
        register_via: '',
        password_old: '',
        password: '',
        password_confirmation: ''
    });
    const [submit, setSubmit] = useState(false);
    const [showPassword, setShowPassword] = useState({
        current: false,
        new: false,
        confirm: false
    });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'BuddyMed | Edit Password';
        getDetailUser().then(({ value: { user } }) => {
            setParams({
                ...params,
                register_via: user?.register_via
            });
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleConfirmSubmit = () => {
        Swal.fire({
            text: 'Are you sure you want to save changes?',
            imageUrl: IcConfirmEdit,
            imageWidth: 92,
            imageHeight: 92,
            imageAlt: 'icon edit',
            showCancelButton: true,
            cancelButtonText: 'No',
            confirmButtonText: 'Yes',
            confirmButtonColor: '#f09856',
            cancelButtonColor: '#EBEAEA'
        }).then((res) => {
            if (res.isConfirmed) {
                handleSubmit();
            }
        });
    };

    const handleSubmit = () => {
        setSubmit(true);
        sendEditPass(params)
            .then(() => {
                Swal.fire({
                    text: 'Password changed successfully!',
                    imageUrl: IcSuccessModal,
                    imageWidth: 92,
                    imageHeight: 92,
                    imageAlt: 'icon success',
                    timer: 2000,
                    showConfirmButton: false
                }).then(() => {
                    getDetailUser();
                    setSubmit(false);
                });
            })
            .catch((error) => {
                const err = error?.response?.data;
                if (err.code === 1) {
                    console.log('err', err.data);
                    Swal.fire({
                        title: 'Error',
                        text: err.data[0],
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                } else {
                    Swal.fire({
                        title: 'Error',
                        text: 'Failed, try again',
                        icon: 'error',
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
                setSubmit(false);
            });
    };

    const handleChange = ({ target }) => {
        setParams((pref) => ({
            ...pref,
            [target.name]: target.value
        }));
    };

    ValidatorForm.addValidationRule('isPassMatch', (value) => {
        if (value !== params.password) {
            return false;
        }
        return true;
    });

    return (
        <Sidebar>
            <div className="mt-3">
                <Row>
                    <Col xl="12" lg="12" md="12" sm="12" xs="12">
                        <h4 className="font-weight-bold title-page">
                            Edit Password
                        </h4>
                    </Col>
                    <Col xl="12" lg="12" md="12" sm="12" xs="12">
                        <Card className="py-3 card-complete-profile">
                            <Container>
                                <ValidatorForm
                                    onSubmit={handleConfirmSubmit}
                                    className="form"
                                >
                                    <Row>
                                        {params.register_via === 'email' ? (
                                            <Col
                                                xs="12"
                                                sm="6"
                                                md="6"
                                                lg="6"
                                                xl="6"
                                            >
                                                <label
                                                    htmlFor="password_old"
                                                    className="label"
                                                >
                                                    Current Password
                                                </label>
                                                <TextValidator
                                                    variant="outlined"
                                                    placeholder="Password"
                                                    onChange={handleChange}
                                                    name="password_old"
                                                    id="password_old"
                                                    value={params.password_old}
                                                    validators={['required']}
                                                    errorMessages={[
                                                        'this field is required'
                                                    ]}
                                                    className="w-100 mb-3"
                                                    type={
                                                        showPassword.current
                                                            ? 'text'
                                                            : 'password'
                                                    }
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <IconButton
                                                                    onClick={() => {
                                                                        setShowPassword(
                                                                            (
                                                                                pref
                                                                            ) => ({
                                                                                ...pref,
                                                                                current:
                                                                                    !pref.current
                                                                            })
                                                                        );
                                                                    }}
                                                                >
                                                                    <img
                                                                        alt="password"
                                                                        src={
                                                                            showPassword.current
                                                                                ? IcEyeClose
                                                                                : IcEyeOpen
                                                                        }
                                                                    />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        )
                                                    }}
                                                />
                                            </Col>
                                        ) : null}
                                        <Col
                                            xs="12"
                                            sm="6"
                                            md="6"
                                            lg="6"
                                            xl="6"
                                        >
                                            <label
                                                htmlFor="password"
                                                className="label"
                                            >
                                                New Password
                                            </label>
                                            <TextValidator
                                                variant="outlined"
                                                placeholder="Type your new password"
                                                onChange={handleChange}
                                                name="password"
                                                id="password"
                                                value={params.password}
                                                validators={[
                                                    'required',
                                                    'minStringLength: 8',
                                                    'maxStringLength: 15'
                                                ]}
                                                errorMessages={[
                                                    'this field is required',
                                                    'Min length password is 8 character',
                                                    'Max character for password is 15 character'
                                                ]}
                                                className="w-100 mb-3"
                                                type={
                                                    showPassword.new
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => {
                                                                    setShowPassword(
                                                                        (
                                                                            pref
                                                                        ) => ({
                                                                            ...pref,
                                                                            new: !pref.new
                                                                        })
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    alt="password"
                                                                    src={
                                                                        showPassword.new
                                                                            ? IcEyeClose
                                                                            : IcEyeOpen
                                                                    }
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Col>
                                        <Col
                                            xs="12"
                                            sm="6"
                                            md="6"
                                            lg="6"
                                            xl="6"
                                        >
                                            <label
                                                htmlFor="password_confirmation"
                                                className="label"
                                            >
                                                Confirm Password
                                            </label>
                                            <TextValidator
                                                variant="outlined"
                                                placeholder="Confirm new password"
                                                onChange={handleChange}
                                                name="password_confirmation"
                                                id="password_confirmation"
                                                value={
                                                    params.password_confirmation
                                                }
                                                validators={[
                                                    'required',
                                                    'isPassMatch'
                                                ]}
                                                errorMessages={[
                                                    'this field is required',
                                                    'Password not match'
                                                ]}
                                                className="w-100 mb-3"
                                                type={
                                                    showPassword.confirm
                                                        ? 'text'
                                                        : 'password'
                                                }
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                onClick={() => {
                                                                    setShowPassword(
                                                                        (
                                                                            pref
                                                                        ) => ({
                                                                            ...pref,
                                                                            confirm:
                                                                                !pref.confirm
                                                                        })
                                                                    );
                                                                }}
                                                            >
                                                                <img
                                                                    alt="password"
                                                                    src={
                                                                        showPassword.confirm
                                                                            ? IcEyeClose
                                                                            : IcEyeOpen
                                                                    }
                                                                />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    )
                                                }}
                                            />
                                        </Col>
                                    </Row>
                                    <div className="d-flex justify-content-end mt-4">
                                        <Button
                                            disableElevation
                                            disabled={submit}
                                            variant="contained"
                                            className="px-5 text-white btn_buddy"
                                            type="submit"
                                        >
                                            {submit ? (
                                                <CircularProgress size={24} />
                                            ) : (
                                                'Save'
                                            )}
                                        </Button>
                                    </div>
                                </ValidatorForm>
                            </Container>
                        </Card>
                    </Col>
                </Row>
            </div>
        </Sidebar>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendEditPass: (params) => dispatch(sendEditPass(params)),
        getDetailUser: () => dispatch(getDetailUser())
    };
};

export default connect(null, mapDispatchToProps)(EditPassword);
