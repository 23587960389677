import { Button, CircularProgress, InputAdornment } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { IcEmail, IlForgot } from '../../assets';
import Footer from '../../components/footer/footer';
import { sendForgot } from '../../store/actions/authAction';

const Forgot = ({ history, sendForgot }) => {
    const [email, setEmail] = useState('');
    const [submit, setSubmit] = useState(false);
    useEffect(() => {
        document.title = 'BuddyMed | Forgot Password';
    }, []);

    const handleSubmit = () => {
        setSubmit(!submit);
        sendForgot({ email })
            .then(() => {
                Swal.fire({
                    title: 'Success',
                    text: 'Email sent successfully, check your email to reset password',
                    timer: 2000,
                    icon: 'success',
                    iconColor: '#f09856',
                    showConfirmButton: false
                }).then(async () => {
                    setEmail('');
                    setSubmit(submit);
                    localStorage.setItem('sendForget', true);
                    history.push('/check_email');
                });
            })
            .catch((err) => {
                const error = err.response.data;
                console.log('error', error);
                if (error.code === 1) {
                    Swal.fire({
                        title: 'Failed',
                        text: error.data,
                        timer: 2000,
                        icon: 'error',
                        showConfirmButton: false
                    });
                } else {
                    Swal.fire({
                        title: 'Failed',
                        text: 'email failed to send, please try again',
                        timer: 2000,
                        icon: 'error'
                    });
                }
                setSubmit(false);
            });
    };

    return (
        <>
            <section className="container-auth">
                <Container>
                    <Row className="justify-content-between">
                        <Col lg="5" md="5" sm="12" xs="12">
                            <div className="container-ilustration bg-transparent">
                                <img
                                    alt="signup"
                                    src={IlForgot}
                                    className="text-hallo d-none d-md-block"
                                />
                            </div>
                        </Col>
                        <Col lg="6" md="6" sm="12" xs="12">
                            <h4 className="text-hallo mb-3">Forgot Password</h4>
                            <p className="text-desc mb-4">
                                Enter the email associated with your account and
                                we’ll send an email with instruction to reset
                                your password.
                            </p>

                            <ValidatorForm
                                onSubmit={handleSubmit}
                                className="form"
                            >
                                <label htmlFor="email" className="label">
                                    Email
                                </label>
                                <TextValidator
                                    variant="outlined"
                                    placeholder="Email address"
                                    type="email"
                                    onChange={(e) =>
                                        setEmail(e.currentTarget.value)
                                    }
                                    name="email"
                                    id="email"
                                    value={email}
                                    validators={['required']}
                                    errorMessages={[
                                        'this field is required',
                                        'email is not valid'
                                    ]}
                                    className="w-100 mb-3"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <img
                                                    alt="icon_email"
                                                    src={IcEmail}
                                                />
                                            </InputAdornment>
                                        )
                                    }}
                                />

                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="mb-4 w-100 text-white font-weight-bold py-2 btn-auth btn_buddy"
                                    disabled={submit}
                                >
                                    {submit ? (
                                        <CircularProgress
                                            size={24}
                                            className="text-white"
                                        />
                                    ) : (
                                        'Send Instruction'
                                    )}
                                </Button>
                            </ValidatorForm>
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer noMargin />
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        sendForgot: (params) => dispatch(sendForgot(params))
    };
};
export default connect(null, mapDispatchToProps)(Forgot);
