import { API, setAuthToken } from '../../config/Api';
import {
    ACCEPT_MATCH,
    ADD_MATCH,
    CANCEL_MATCH,
    DISCOVER_FRIENDS,
    GET_RECENT_MATCH,
    MATCHED_FRIENDS,
    REQUEST_MATCHED_FRIENDS
} from '../constant';

export const addMatchFriend = (params) => {
    return {
        type: ADD_MATCH,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.post('user/matched', params).catch((error) =>
                Promise.reject(error)
            );
            return res.data;
        }
    };
};

export const acceptMatchFriend = (params) => {
    return {
        type: ACCEPT_MATCH,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.post('user/matched/update', params).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};

export const cancelMatchFriend = (params) => {
    return {
        type: CANCEL_MATCH,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.post('user/matched/delete', params).catch(
                (error) => Promise.reject(error)
            );
            return res.data;
        }
    };
};

export const getDiscoverFriends = () => {
    return {
        type: DISCOVER_FRIENDS,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get('user/discover').catch((error) =>
                Promise.reject(error)
            );

            return res.data;
        }
    };
};

export const getMatchedFriends = () => {
    return {
        type: MATCHED_FRIENDS,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get('user/matched').catch((error) =>
                Promise.reject(error)
            );

            return res.data;
        }
    };
};

export const getListRequestMatchFriend = () => {
    return {
        type: REQUEST_MATCHED_FRIENDS,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);

            const res = await API.get('user/matched/request').catch((error) =>
                Promise.reject(error)
            );

            return res.data;
        }
    };
};

export const getRecentMatch = () => {
    return {
        type: GET_RECENT_MATCH,
        payload: async () => {
            const token = localStorage.getItem('token');
            setAuthToken(token);
            const res = await API.get('user/matched/recent').catch((error) =>
                Promise.reject(error)
            );
            return res.data;
        }
    };
};
