import { Button, CircularProgress } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import { IlConfirmEmail } from '../../assets';
import Footer from '../../components/footer/footer';
import { sendVerifyEmail } from '../../store/actions/authAction';
import { getDetailUser } from '../../store/actions/userAction';

const VerifyEmail = ({ history, getDetailUser, match, sendVerifyEmail }) => {
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState({
        status: false,
        message: ''
    });
    const [UserStatus, setUserStatus] = useState(null);

    const handleCheck = useCallback(() => {
        getDetailUser()
            .then(({ value: { user } }) => {
                let status = user?.verification_status;
                if (status === 1) {
                    setUserStatus(status);
                } else {
                    const { email, token, expires } = match?.params;
                    sendVerifyEmail(email, token, expires)
                        .then(() => {
                            setLoading(false);
                            setUserStatus(1);
                            getDetailUser();
                        })
                        .catch((err) => {
                            let error = err.response;
                            setLoading(false);
                            setIsError({
                                status: true,
                                message: error?.data?.data.msg
                            });
                        });
                }
            })
            .catch((err) => {
                console.log('error', err);
                setLoading(false);
                history.push('/');
            });
    }, [getDetailUser, history, match, sendVerifyEmail]);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'BuddyMed | Verify Email';

        window.scrollTo(0, 0);
        handleCheck();
    }, [handleCheck]);

    return (
        <>
            <section className="container-auth">
                <Container className="container-form">
                    <Row className="justify-content-between h-100">
                        <Col lg="5" md="5" sm="12" xs="12">
                            <div className="bg-transparent">
                                <img
                                    alt="signup"
                                    src={IlConfirmEmail}
                                    className="w-100 text-hallo"
                                />
                            </div>
                        </Col>
                        <Col
                            lg="6"
                            md="6"
                            sm="12"
                            xs="12"
                            className="d-flex flex-column justify-content-center"
                        >
                            {loading && UserStatus === null ? (
                                <CircularProgress />
                            ) : UserStatus === 1 ? (
                                <Fragment>
                                    <h4 className="text-hallo mb-3">
                                        Congratulations
                                    </h4>
                                    <p className="text-desc mb-4">
                                        your account has been verified
                                    </p>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        className="btn-auth text-white rounded-silinder btn_buddy"
                                        href="/"
                                    >
                                        Dashboard
                                    </Button>
                                </Fragment>
                            ) : isError?.status ? (
                                <Fragment>
                                    <h4 className="text-hallo mb-3">Failed</h4>
                                    <p className="text-desc mb-4">
                                        {isError?.message}
                                    </p>
                                </Fragment>
                            ) : null}
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDetailUser: () => dispatch(getDetailUser()),
        sendVerifyEmail: (email, token, expires) =>
            dispatch(sendVerifyEmail(email, token, expires))
    };
};

export default connect(null, mapDispatchToProps)(VerifyEmail);
