import { Button } from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';

import { IlSearchLoad, IlSuccessFind } from '../../assets';
import { Sidebar } from '../../components';
import LoadingFindBuddy from '../../components/loading/LoadingFindBuddy';
import { getExamType } from '../../store/actions/appAction';
import { findBuddy, matchedBuddy } from '../../store/actions/buddyAction';

const FindBuddy = ({ findBuddy, getExamType }) => {
    const users_code = localStorage.getItem('users_code');
    const [state, setState] = useState({
        medical: '',
        clinical: '',
        basic_medical: '',
        basic_science: ''
    });
    const [submit, setSubmit] = useState(false);
    const [detailUser, setDetailUser] = useState(null);
    const history = useHistory();
    const [dataMedical, setDataMedical] = useState(null);
    const [dataClinical, setDataClinical] = useState(null);
    const [dataBasicMedical, setDataBasicMedical] = useState(null);
    const [dataBasicScience, setDataBasicScience] = useState(null);
    const [limitData, setLimitData] = useState({
        medical: 4,
        clinical: 4,
        basic_med: 4,
        basic_science: 4
    });

    const handleFindBuddy = () => {
        setSubmit(true);
        findBuddy(state)
            .then((res) => {
                const dataResult = res.action.payload.data;
                if (dataResult.code === 204) {
                    setSubmit(false);
                    Swal.fire({
                        title: 'Success',
                        text: `${dataResult.msg}`,
                        icon: 'success',
                        iconColor: '#f09856',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f09856'
                    }).then(() => {
                        history.push('/');
                    });
                } else if (dataResult.code === 500) {
                    setSubmit(false);
                    Swal.fire({
                        title: 'Warning',
                        text: `${dataResult.msg}`,
                        icon: 'warning',
                        confirmButtonText: 'OK',
                        confirmButtonColor: '#f09856'
                    });
                } else {
                    setSubmit(false);
                    setDetailUser(dataResult.data);
                }
            })
            .catch((err) => err.message);
    };

    const handleShowMore = (name, val) => {
        setLimitData((pref) => ({
            ...pref,
            [name]: val
        }));
    };

    const handleChange = (name, value) => {
        setState((pref) => ({
            ...pref,
            [name]: pref[name] === value ? '' : value
        }));
    };

    useEffect(() => {
        getExamType(1).then(({ value }) => setDataMedical(value));
        getExamType(2).then(({ value }) => setDataClinical(value));
        getExamType(3).then(({ value }) => setDataBasicMedical(value));
        getExamType(4).then(({ value }) => setDataBasicScience(value));
        document.title = 'BuddyMed | Find Buddy';
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    }, [getExamType]);

    return (
        <Sidebar>
            <div className="mt-3">
                <h4 className="font-weight-bold text-black">Find a Buddy</h4>
                <hr />
                {!submit && detailUser === null ? (
                    <Fragment>
                        <h5 className="mb-3 font-weight-bold mt-4">
                            Medical license exam
                        </h5>
                        <Row className="mb-3">
                            {dataMedical === null ? (
                                <LoadingFindBuddy jml={4} />
                            ) : (
                                dataMedical
                                    ?.slice(0, limitData.medical)
                                    ?.map((item) => (
                                        <Col
                                            xs="6"
                                            sm="6"
                                            md="4"
                                            lg="3"
                                            xl="3"
                                            className="mb-3"
                                            key={item.code}
                                        >
                                            <button
                                                className={`btn-interest ${
                                                    state.medical ===
                                                        item.code && 'active'
                                                } px-1 h-100`}
                                                onClick={() =>
                                                    handleChange(
                                                        'medical',
                                                        item.code
                                                    )
                                                }
                                            >
                                                {item.name}
                                            </button>
                                        </Col>
                                    ))
                            )}
                            {dataMedical?.length > 4 &&
                            limitData?.medical < dataMedical?.length ? (
                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <Button
                                        className="text-salmon"
                                        onClick={() =>
                                            handleShowMore(
                                                'medical',
                                                dataMedical.length
                                            )
                                        }
                                    >
                                        Show all
                                    </Button>
                                </Col>
                            ) : null}
                        </Row>
                        <h5 className="mb-3 font-weight-bold">Clinical</h5>
                        <Row className="mb-3">
                            {dataClinical === null ? (
                                <LoadingFindBuddy jml={4} />
                            ) : (
                                dataClinical
                                    ?.slice(0, limitData?.clinical)
                                    ?.map((item) => (
                                        <Col
                                            xs="6"
                                            sm="6"
                                            md="4"
                                            lg="3"
                                            xl="3"
                                            className="mb-3"
                                            key={item.code}
                                        >
                                            <button
                                                className={`btn-interest ${
                                                    state.clinical ===
                                                        item.code && 'active'
                                                } px-1 h-100`}
                                                onClick={() =>
                                                    handleChange(
                                                        'clinical',
                                                        item.code
                                                    )
                                                }
                                            >
                                                {item.name}
                                            </button>
                                        </Col>
                                    ))
                            )}
                            {dataClinical?.length > 4 &&
                            limitData?.clinical < dataClinical?.length ? (
                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <Button
                                        className="text-salmon"
                                        onClick={() =>
                                            handleShowMore(
                                                'clinical',
                                                dataClinical.length
                                            )
                                        }
                                    >
                                        Show all
                                    </Button>
                                </Col>
                            ) : null}
                        </Row>
                        <h5 className="mb-3 font-weight-bold">Basic medical</h5>
                        <Row className="mb-3">
                            {dataBasicMedical === null ? (
                                <LoadingFindBuddy jml={4} />
                            ) : (
                                dataBasicMedical
                                    ?.slice(0, limitData?.basic_med)
                                    ?.map((item) => (
                                        <Col
                                            xs="6"
                                            sm="6"
                                            md="4"
                                            lg="3"
                                            xl="3"
                                            className="mb-3"
                                            key={item.code}
                                        >
                                            <button
                                                className={`btn-interest ${
                                                    state.basic_medical ===
                                                        item.code && 'active'
                                                } px-1 h-100`}
                                                onClick={() =>
                                                    handleChange(
                                                        'basic_medical',
                                                        item.code
                                                    )
                                                }
                                            >
                                                {item.name}
                                            </button>
                                        </Col>
                                    ))
                            )}
                            {dataBasicMedical?.length > 4 &&
                            limitData?.basic_med < dataBasicMedical?.length ? (
                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <Button
                                        className="text-salmon"
                                        onClick={() =>
                                            handleShowMore(
                                                'basic_med',
                                                dataBasicMedical.length
                                            )
                                        }
                                    >
                                        Show all
                                    </Button>
                                </Col>
                            ) : null}
                        </Row>
                        <h5 className="mb-3 font-weight-bold">Basic science</h5>
                        <Row className="mb-3">
                            {dataBasicScience === null ? (
                                <LoadingFindBuddy jml={4} />
                            ) : (
                                dataBasicScience
                                    ?.slice(0, limitData?.basic_science)
                                    ?.map((item) => (
                                        <Col
                                            xs="6"
                                            sm="6"
                                            md="4"
                                            lg="3"
                                            xl="3"
                                            className="mb-3"
                                            key={item.code}
                                        >
                                            <button
                                                className={`btn-interest ${
                                                    state.basic_science ===
                                                        item.code && 'active'
                                                } px-1 h-100`}
                                                onClick={() =>
                                                    handleChange(
                                                        'basic_science',
                                                        item.code
                                                    )
                                                }
                                            >
                                                {item.name}
                                            </button>
                                        </Col>
                                    ))
                            )}
                            {dataBasicScience?.length > 4 &&
                            limitData?.basic_science <
                                dataBasicScience?.length ? (
                                <Col xs="12" sm="12" md="12" lg="12" xl="12">
                                    <Button
                                        className="text-salmon"
                                        onClick={() =>
                                            handleShowMore(
                                                'basic_science',
                                                dataBasicScience.length
                                            )
                                        }
                                    >
                                        Show all
                                    </Button>
                                </Col>
                            ) : null}
                        </Row>
                        <Row className="justify-content-end mb-5">
                            <Col xs="12" sm="6" md="3" lg="3" xl="3">
                                <Button
                                    variant="contained"
                                    className="rounded-silinder px-5 w-100 btn_buddy"
                                    disableElevation
                                    onClick={handleFindBuddy}
                                >
                                    Find a Buddy
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                ) : !submit && detailUser ? (
                    <div className="h-100 d-flex flex-column align-items-center justify-content-center container-home">
                        <img
                            src={IlSuccessFind}
                            alt="find friend"
                            className="my-3 il-success-find"
                        />
                        <h5 class="font-weight-bold m-0 mb-1 name">
                            You found a buddy
                        </h5>
                        <p class="mb-3 university">
                            You matched with{' '}
                            {detailUser?.detail_group_member
                                ?.filter(
                                    (item) => item.users_code !== users_code
                                )
                                .map((item) => item.fullname)
                                .join(', ')}
                        </p>
                        <Button
                            variant="contained"
                            color="primary"
                            className="text-white rounded-silinder px-5 mb-5"
                            disableElevation
                            onClick={() =>
                                history.push(`/group/${detailUser.group_code}`)
                            }
                        >
                            Chat
                        </Button>
                    </div>
                ) : (
                    <div className="h-100 d-flex flex-column align-items-center justify-content-center container-home">
                        <img
                            src={IlSearchLoad}
                            alt="find friend"
                            className="my-3 il-search-load"
                        />
                        <div className={'container-loading'}>
                            <h5
                                className="font-weight-bold my-5"
                                id={submit ? 'teks-loading' : ''}
                            >
                                Search Matching Buddy . . .
                            </h5>
                        </div>
                    </div>
                )}
            </div>
        </Sidebar>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        findBuddy: (params) => dispatch(findBuddy(params)),
        matchedBuddy: (params) => dispatch(matchedBuddy(params)),
        getExamType: (type) => dispatch(getExamType(type))
    };
};

export default connect(null, mapDispatchToProps)(FindBuddy);
