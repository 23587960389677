import { Button, CircularProgress } from '@material-ui/core';
import React, { useCallback, useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';

import { IlConfirmEmail } from '../../assets';
import Footer from '../../components/footer/footer';
import { getDetailUser } from '../../store/actions/userAction';

const ConfirmEmail = ({ history, getDetailUser }) => {
    const [loading, setLoading] = useState(false);
    const [submitCheck, setSubmitCheck] = useState(false);
    const [UserStatus, setUserStatus] = useState(null);

    const handleCheck = useCallback(() => {
        setSubmitCheck(true);
        getDetailUser()
            .then(({ value: { user } }) => {
                if (user?.verification_status === 0) {
                    Swal.fire({
                        title: 'Info',
                        text: 'Your account not verified, check your email for verify your account.',
                        icon: 'info',
                        iconColor: '#F09856',
                        timer: 2000,
                        showConfirmButton: false
                    });
                }
                setUserStatus(user?.verification_status);
                setLoading(false);
                setSubmitCheck(false);
            })
            .catch(() => {
                setSubmitCheck(false);
                history.push('/');
            });
    }, [getDetailUser, history]);

    const handleCheckBack = () => {
       localStorage.clear();
       history.push('/login');

    }

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'BuddyMed | Confirm Email';
        window.scrollTo(0, 0);
        handleCheck();
    }, [handleCheck]);

    return (
        <>
            <section className="container-auth">
                <Container className="container-form">
                    <Row className="justify-content-between">
                        <Col lg="5" md="5" sm="12" xs="12">
                            <div className="bg-transparent">
                                <img
                                    alt="signup"
                                    src={IlConfirmEmail}
                                    className="w-100 text-hallo"
                                />
                            </div>
                        </Col>
                        <Col
                            lg="6"
                            md="6"
                            sm="12"
                            xs="12"
                            className="d-flex flex-column justify-content-center"
                        >
                            {loading && UserStatus === null ? (
                                <CircularProgress />
                            ) : UserStatus === 0 ? (
                                <Fragment>
                                    <h4 className="text-hallo mb-3">
                                        Email Verification
                                    </h4>
                                    <p className="text-desc mb-4">
                                        We sent email verification to your
                                        email. Please check your (inbox or spam)
                                        email to verify your email account. You
                                        can use our apps after verify the email
                                    </p>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className="mb-4 w-100 text-white font-weight-bold py-2 btn-auth rounded-silinder btn_buddy"
                                        onClick={handleCheck}
                                        disabled={submitCheck}
                                    >
                                        {submitCheck ? (
                                            <CircularProgress size={25} />
                                        ) : (
                                            'Done'
                                        )}
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className="mb-4 w-100 text-white font-weight-bold py-2 btn-auth text-white rounded-silinder btn_back"
                                        onClick={handleCheckBack}
                                    >
                                        Back to Home
                                    </Button>
                                </Fragment>
                            ) : UserStatus === 1 ? (
                                <Fragment>
                                    <h4 className="text-hallo mb-3">
                                        Success Confirm Email
                                    </h4>
                                    <p className="text-desc mb-4">
                                        your email has been verified
                                        successfully.
                                    </p>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        className="mb-4 w-100 text-white font-weight-bold py-2 btn-auth text-white rounded-silinder"
                                        href="/"
                                    >
                                        Dashboard
                                    </Button>
                                </Fragment>
                            ) : null}
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getDetailUser: () => dispatch(getDetailUser())
    };
};

export default connect(null, mapDispatchToProps)(ConfirmEmail);
