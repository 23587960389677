import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import { CardNotif, Sidebar } from '../../components';
import { responseInvite } from '../../store/actions/chatGroupAction';
import { acceptMatchFriend } from '../../store/actions/matchAction';
import { getNotif, readNotif } from '../../store/actions/userAction';
import { convertToLocal } from '../../utils/formatDate';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box className="box-custom">
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

const Notification = ({
    notif,
    acceptMatchFriend,
    getNotif,
    readNotif,
    responseInviteGroup
}) => {
    const [value, setValue] = useState(0);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'BuddyMed | Notification';
        readNotif();
    }, [readNotif]);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleAccept = (matched_code) => {
        acceptMatchFriend({ matched_code, status: 1 }).then(() => getNotif());
    };

    const handleGroup = (group_code, status) => {
        responseInviteGroup({ group_code, status }).then(() => getNotif());
    };

    return (
        <Sidebar>
            <div>
                <Row>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <AppBar position="static" className="bar-custom">
                            <Tabs
                                value={value}
                                onChange={handleChange}
                                className="container-tab-bar"
                                indicatorColor="primary"
                            >
                                <Tab label="Notifications" />
                                <Tab label="Buddy Requests" />
                            </Tabs>
                        </AppBar>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="12" xl="12">
                        <TabPanel value={value} index={0}>
                            {notif?.data
                                ?.filter((item) => item.notification_type !== 2)
                                ?.map((item, idx) => (
                                    <CardNotif
                                        key={idx + 1}
                                        img={item.from_pict}
                                        notif={item.notification_desc}
                                        time={convertToLocal(
                                            item.created_at_origin,
                                            'YYYY MMM DD, LT'
                                        )}
                                        status={item.notification_type}
                                        handleGroup={handleGroup}
                                        group_code={item.group_code}
                                    />
                                ))}
                        </TabPanel>
                        <TabPanel value={value} index={1}>
                            {notif?.data
                                ?.filter((item) => {
                                    return (
                                        item.notification_type === 2 &&
                                        item.matched_code !== '-'
                                    );
                                })
                                ?.map((item, idx) => (
                                    <CardNotif
                                        key={idx + 1}
                                        img={item.from_pict}
                                        notif={item.notification_desc}
                                        time={convertToLocal(
                                            item.created_at_origin,
                                            'YYYY MMM DD, LT'
                                        )}
                                        status={item.notification_type}
                                        matched_code={item.matched_code}
                                        handleAccept={handleAccept}
                                    />
                                ))}
                        </TabPanel>
                    </Col>
                </Row>
            </div>
        </Sidebar>
    );
};
const mapStateToProps = (state) => {
    return {
        notif: state.user.notif
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        acceptMatchFriend: (params) => dispatch(acceptMatchFriend(params)),
        getNotif: () => dispatch(getNotif()),
        readNotif: () => dispatch(readNotif()),
        responseInviteGroup: (params) => dispatch(responseInvite(params))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notification);
