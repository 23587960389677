import { CircularProgress, FormControl, FormHelperText, IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { IcChatSalmon, IcBreakTheIce } from '../../assets';
import { convertToLocal } from '../../utils/formatDate';

const CardChatDetail = ({
    icon,
    icon_send,
    picture,
    name,
    username,
    handleClick,
    value,
    onChange,
    conversation,
    onSubmit,
    onKeyPress,
    isPersonalChat,
    isGroupChat,
    submit,
    canChat,
    handleConference,
    submitConf,
    isConfCreated,
    handleBreak,
    groupConferences,
    users_code
}) => {
    const urlify = (text) => {
        var urlRegex =
            // eslint-disable-next-line no-useless-escape
            /((http|https|ftp|ftps)\:\/\/[a-zA-Z0-9\-\.]+\.[a-zA-Z]{2,3}(\/\S*)?)/g;
        return text.replace(urlRegex, function (url) {
            return (
                '<a href="' +
                url +
                '" target="_blank" class="link-preview">' +
                url +
                '</a>'
            );
        });
    };

    return (
        <>
            {isPersonalChat && (
                <Card className="border-0 card-detail-chat radius-8 h-100 pb-0">
                    <div className="d-flex flex-row align-items-center justify-content-between header">
                        <div className="d-flex flex-row align-items-center flex-1">
                            <Link to="/friends">
                                <img src={icon} alt="icon-back" />
                            </Link>
                            {picture ? (
                                <Link to={`/profile/${username}`}>
                                    <img
                                        src={picture}
                                        alt={name}
                                        width={80}
                                        height={80}
                                        className="ml-2 mr-3 profile rounded-circle"
                                    />
                                </Link>
                            ) : (
                                <Skeleton
                                    animation="wave"
                                    variant="circle"
                                    width={50}
                                    height={50}
                                />
                            )}
                            {name && username ? (
                                <div>
                                    <Link
                                        to={`/profile/${username}`}
                                        className="text-decoration-none"
                                    >
                                        <h5 className="mb-0 font-weight-bold text-black nama">
                                            {name}
                                        </h5>
                                    </Link>
                                    <p className="m-0 username">@{username}</p>
                                </div>
                            ) : (
                                <div className="d-flex flex-column ml-3">
                                    <Skeleton
                                        animation="wave"
                                        width={150}
                                        height={15}
                                    />
                                    <Skeleton
                                        animation="wave"
                                        width={200}
                                        height={13}
                                    />
                                </div>
                            )}
                        </div>
                       <div>
                            {name && username ? (
                                <button
                                    className="radius-8 shadow-none text-white btn-card btn-chat ml-1 d-flex align-items-center justify-content-center p-3"
                                    onClick={handleBreak}
                                >
                                    <img
                                        src={IcBreakTheIce}
                                        alt="icon share"
                                        width={25}
                                    />
                                </button>
                            ) : null}
                        </div>
                        <div>
                            {name && username ? (
                                <button
                                    className="radius-8 shadow-none text-white btn-card btn-chat ml-1 d-flex align-items-center justify-content-center"
                                    onClick={handleConference}
                                    disabled={submitConf}
                                >
                                    {submitConf ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={25}
                                        />
                                    ) : groupConferences?.conf_link?.length > 0 && groupConferences.created_by !== users_code ? (
                                        <h6 className="m-0 font-weight-bold">
                                            Join
                                        </h6>
                                    ) : (
                                        <img
                                            src={IcChatSalmon}
                                            alt="icon share"
                                            width={25}
                                        />
                                    )}
                                </button>
                            ) : null}
                        </div>
                    </div>
                    <div
                        className="chat-area overflow-auto flex-1"
                        id="chat-area"
                    >
                        {conversation?.map((conversation) => {
                            return conversation?.messages_type ===
                                3 ? null : !conversation.from_me ? ( // </div> //     </a> //         <VideocamRounded /> //         Join Conference //     > //         className="chat-conference" //         href={`/conference/live/${conversation?.conference?.conf_id}/${conversation?.conference?.password}`} //     <a // > //     key={conversation.messages_code} //     className="d-flex justify-content-center align-items-center my-3" // <div
                                <div
                                    className="d-flex justify-content-start my-3"
                                    key={conversation.messages_code}
                                >
                                    <div className="send-chat d-flex flex-row justify-content-between align-items-end">
                                        <p
                                            className="m-0"
                                            dangerouslySetInnerHTML={{
                                                __html: urlify(
                                                    conversation.messages_text
                                                )
                                            }}
                                        ></p>
                                        <span className="m-0">
                                            {convertToLocal(
                                                conversation.created_at,
                                                'HH:mm'
                                            )}
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="d-flex justify-content-end my-3"
                                    key={conversation.messages_code}
                                >
                                    <div className="content-chat d-flex flex-row justify-content-between align-items-end">
                                        <p
                                            className="m-0"
                                            dangerouslySetInnerHTML={{
                                                __html: urlify(
                                                    conversation.messages_text
                                                )
                                            }}
                                        ></p>
                                        <span className="m-0">
                                            {convertToLocal(
                                                conversation.created_at,
                                                'HH:mm'
                                            )}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <FormControl className="w-100 my-4 form-chat">
                        <TextField
                            placeholder="Type a message"
                            variant="outlined"
                            type="text"
                            name="messages"
                            value={value}
                            onChange={onChange}
                            onKeyPress={onKeyPress}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={onSubmit}
                                            disabled={value.length === 0}
                                            type="submit"
                                        >
                                            {submit ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={24}
                                                />
                                            ) : (
                                                <img
                                                    src={icon_send}
                                                    alt="icon-send"
                                                    width={24}
                                                    height={24}
                                                />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            multiline
                            maxRows={3}
                        />
                    </FormControl>
                </Card>
            )}

            {isGroupChat && (
                <Card className="border-0 card-detail-chat radius-8 h-100 pb-0">
                    <div className="d-flex flex-row align-items-center header">
                        <div className="d-flex flex-row align-items-center flex-1">
                            <Link to="/friends">
                                <img src={icon} alt="icon-back" />
                            </Link>
                            {picture ? (
                                <img
                                    src={picture}
                                    alt={name}
                                    width={80}
                                    height={80}
                                    className="ml-2 mr-3 profile rounded-circle cursor-pointer"
                                    onClick={handleClick}
                                />
                            ) : (
                                <Skeleton
                                    animation="wave"
                                    variant="circle"
                                    width={50}
                                    height={50}
                                />
                            )}
                            {name && username ? (
                                <div>
                                    <button
                                        className="btn-title"
                                        onClick={handleClick}
                                    >
                                        <h5 className="mb-0 font-weight-bold nama text-left">
                                            {name}
                                        </h5>
                                        <p className="mb-0 text-muted text-left">
                                            {username}
                                        </p>
                                    </button>
                                </div>
                            ) : (
                                <div className="d-flex flex-column ml-3">
                                    <Skeleton
                                        animation="wave"
                                        width={150}
                                        height={15}
                                    />
                                    <Skeleton
                                        animation="wave"
                                        width={200}
                                        height={13}
                                    />
                                </div>
                            )}
                        </div>

                        <div>
                            {name && username ? (
                                <button
                                    className="radius-8 shadow-none text-white btn-card btn-chat ml-1 d-flex align-items-center justify-content-center p-3"
                                    onClick={handleBreak}
                                >
                                    <img
                                        src={IcBreakTheIce}
                                        alt="icon share"
                                        width={25}
                                    />
                                </button>
                            ) : null}
                        </div>
                        <div>
                            {name && username && canChat ? (
                                <button
                                    className="radius-8 shadow-none text-white btn-card btn-chat ml-1 d-flex align-items-center justify-content-center"
                                    disabled={submitConf}
                                    onClick={handleConference}
                                >
                                    {submitConf ? (
                                        <CircularProgress
                                            color="inherit"
                                            size={25}
                                        />
                                    ) : groupConferences?.conf_link?.length > 0 && groupConferences.created_by !== users_code ? (
                                        <h6 className="m-0 font-weight-bold">
                                            Join
                                        </h6>
                                    ) : (
                                        <img
                                            src={IcChatSalmon}
                                            alt="icon share"
                                            width={25}
                                        />
                                    )}
                                </button>
                            ) : null}
                        </div>
                    </div>

                    <div
                        className="chat-area overflow-auto flex-1"
                        id="chat-area"
                    >
                        {conversation?.map((conversation) => {
                            return conversation?.gm_type ===
                                4 ?
                                <p
                                    className="text-muted text-center p-3 m-0"
                                    key={conversation.gm_code}
                                >
                                    {conversation.gm_text === 'Meeting stopped' ? 'Meeting stopped' : <>Meeting started: <a href={conversation.gm_text}>Join here</a></>}
                                </p>
                                : conversation?.gm_type === 3 ? ( // </div> //     </a> //         <VideocamRounded /> //         Join Conference //     > //         className="chat-conference" //         href={conversation?.gm_text} //     <a // > //     key={conversation.gm_code} //     className="d-flex justify-content-center align-items-center my-3" // <div
                                <p
                                    className="text-muted text-center p-3 m-0"
                                    key={conversation.gm_code}
                                    dangerouslySetInnerHTML={{
                                        __html: urlify(conversation?.gm_text)
                                    }}
                                ></p>
                            ) : !conversation.from_me ? (
                                <div
                                    className="d-flex justify-content-start my-3"
                                    key={conversation.gm_code}
                                >
                                    <div className="send-chat">
                                        <div>
                                            {conversation.user_deleted ? (
                                                <i className="text-dark font-weight-bold">
                                                    {conversation?.fullname}
                                                </i>
                                            ) : (
                                                <p className="m-0 text-white font-weight-bold">
                                                    {conversation?.fullname}
                                                </p>
                                            )}
                                        </div>

                                        <div className="d-flex flex-row justify-content-between align-items-end">
                                            <p
                                                className="m-0"
                                                dangerouslySetInnerHTML={{
                                                    __html: urlify(
                                                        conversation.gm_text
                                                    )
                                                }}
                                            ></p>
                                            <span className="m-0">
                                                {convertToLocal(
                                                    conversation.created_at,
                                                    'HH:mm'
                                                )}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className="d-flex justify-content-end my-3"
                                    key={conversation.gm_code}
                                >
                                    <div className="content-chat d-flex flex-row justify-content-between align-items-end">
                                        <p
                                            className="m-0 text-dark font-weight-normal"
                                            dangerouslySetInnerHTML={{
                                                __html: urlify(
                                                    conversation.gm_text
                                                )
                                            }}
                                        ></p>
                                        <span className="m-0">
                                            {convertToLocal(
                                                conversation.created_at,
                                                'HH:mm'
                                            )}
                                        </span>
                                    </div>
                                </div>
                            );
                        })}
                    </div>

                    <FormControl className="w-100 my-4 form-chat">
                        <TextField
                            placeholder="Type a message"
                            variant="outlined"
                            type="text"
                            name="messages"
                            value={value}
                            onChange={onChange}
                            onKeyPress={onKeyPress}
                            disabled={!canChat}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            onClick={onSubmit}
                                            disabled={value.length === 0}
                                            type="submit"
                                        >
                                            {submit ? (
                                                <CircularProgress
                                                    color="inherit"
                                                    size={24}
                                                />
                                            ) : (
                                                <img
                                                    src={icon_send}
                                                    alt="icon-send"
                                                    width={24}
                                                    height={24}
                                                />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                            multiline
                            maxRows={3}
                        />
                        {!canChat && (
                            <FormHelperText>
                                You can't send message until group member accept
                                you as a member
                            </FormHelperText>
                        )}
                    </FormControl>
                </Card>
            )}
        </>
    );
};

export default CardChatDetail;
