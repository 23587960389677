import { Button, CircularProgress, MenuItem } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SelectValidator, TextValidator, ValidatorForm } from 'react-material-ui-form-validator';

import { ModalEditAvatar } from '..';

const CardInputProfile = ({
    handleChange,
    data,
    handleNext,
    submit,
    dataAvatar,
    handleChangeProfile,
    setOpenAvatar,
    openAvatar
}) => {
    const [maxData, setMaxData] = useState('');
    useEffect(() => {
        const tgl = new Date();
        let month =
            tgl.getMonth() + 1 <= 9
                ? '0' + (tgl.getMonth() + 1)
                : tgl.getMonth() + 1;
        let tanggal = tgl.getDate() <= 9 ? '0' + tgl.getDate() : tgl.getDate();
        setMaxData(tgl.getFullYear() + '-' + month + '-' + tanggal);
    }, []);
    return (
        <ValidatorForm onSubmit={handleNext} className="form">
            <Row data-step="3">
                <Col xs="12" sm="6" md="6" lg="6" xl="6" className="mb-3">
                    <label className="label">Your Interest</label>
                    <div className="d-flex align-items-center mb-3">
                        <img
                            src={data.profile_preview}
                            className="input-avatar"
                            alt={data.fullname}
                        />
                        <Button
                            variant="contained"
                            color="default"
                            className="ml-2"
                            onClick={() => setOpenAvatar(true)}
                            size="small"
                            disableElevation
                        >
                            Select Your Interest
                        </Button>
                    </div>
                </Col>
                <Col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label className="label">Full Name</label>
                    <TextValidator
                        variant="outlined"
                        placeholder="Type your full name"
                        type="text"
                        onChange={handleChange}
                        name="fullname"
                        value={data.fullname}
                        validators={['required']}
                        errorMessages={['this field is required']}
                        className="w-100 mb-3"
                        color="primary"
                    />
                </Col>
                <Col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label className="label">Email</label>
                    <TextValidator
                        variant="outlined"
                        placeholder="Type your email"
                        type="email"
                        onChange={handleChange}
                        name="email"
                        value={data.email}
                        validators={['required']}
                        errorMessages={['this field is required']}
                        className="w-100 mb-3"
                        color="primary"
                        disabled
                    />
                </Col>
                <Col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label className="label">Username</label>
                    <TextValidator
                        variant="outlined"
                        placeholder="Type your username"
                        type="text"
                        onChange={handleChange}
                        name="username"
                        value={data.username}
                        validators={['required', 'minStringLength: 3']}
                        errorMessages={[
                            'this field is required',
                            'Min length username is 3 character'
                        ]}
                        className="w-100 mb-3"
                        color="primary"
                    />
                </Col>
                <Col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label className="label">Birth Date</label>
                    <TextValidator
                        variant="outlined"
                        type="date"
                        onChange={handleChange}
                        name="birthdate"
                        value={data.birthdate}
                        className="w-100 mb-3"
                        color="primary"
                        InputProps={{
                            inputProps: {
                                max: maxData
                            }
                        }}
                        validators={['required']}
                        errorMessages={['this field is required']}
                    />
                </Col>
                <Col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label className="label">Gender</label>
                    <SelectValidator
                        value={data.gender}
                        onChange={handleChange}
                        className="w-100 mb-3"
                        variant="outlined"
                        validators={['required']}
                        errorMessages={['this field is required']}
                        name="gender"
                    >
                        <MenuItem value="1">Male</MenuItem>
                        <MenuItem value="0">Female</MenuItem>
                    </SelectValidator>
                </Col>
            </Row>
            <div className="d-flex mt-5 container-btn pb-4">
                <Button
                    disableElevation
                    disabled={submit}
                    variant="contained"
                    className="px-5 text-white rounded-silinder btn-full btn_buddy"
                    type="submit"
                    data-step="4"
                >
                    {submit ? (
                        <CircularProgress color="#fff" size={24} />
                    ) : (
                        'Next'
                    )}
                </Button>
            </div>
            {openAvatar && (
                <ModalEditAvatar
                    open={openAvatar}
                    handleClose={() => setOpenAvatar(false)}
                    data={dataAvatar}
                    value={data.profile_code}
                    handleChange={handleChangeProfile}
                />
            )}
        </ValidatorForm>
    );
};
export default CardInputProfile;
