import {
    Button,
    CircularProgress,
    IconButton,
    InputAdornment
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

import { IcEyeClose, IcEyeOpen, IcPass, IlCreatePass } from '../../assets';
import Footer from '../../components/footer/footer';
import { checkTokenReset, resetPassword } from '../../store/actions/authAction';

const CreatePass = ({ history, resetPassword, match, checkTokenReset }) => {
    const [state, setState] = useState({
        email: '',
        password: '',
        password_confirmation: '',
        token: ''
    });
    const [showPass, setshowPass] = useState({
        pass: false,
        confirm: false
    });
    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleSubmit = () => {
        setSubmit(true);
        resetPassword(state)
            .then(() => {
                Swal.fire({
                    title: 'Success',
                    text: 'Password reset successfully',
                    timer: 2000,
                    icon: 'success',
                    iconColor: '#f09856',
                    showConfirmButton: false
                }).then(() => {
                    history.push('/');
                    setSubmit(false);
                });
            })
            .catch((error) => {
                const err = error.response.data;
                console.log('error', err);
                Swal.fire({
                    title: 'Failed',
                    text: 'Password reset failed',
                    timer: 2000,
                    icon: 'error',
                    showConfirmButton: false
                }).then(() => {
                    setSubmit(false);
                });
            });
    };

    const handleChange = (event) => {
        setState((pref) => ({
            ...pref,
            [event.target.name]: event.target.value
        }));
    };

    const checkToken = useCallback(() => {
        setLoading(true);
        const { token, email } = match.params;
        checkTokenReset({ token, email })
            .then((res) => {
                setError(res.value);
                setLoading(false);
            })
            .catch((err) => {
                setError(err.response?.data);
                setLoading(false);
            });
    }, [checkTokenReset, match]);

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        document.title = 'BuddyMed | Reset Password';
        const { token, email } = match.params;
        checkToken();
        setState((pref) => ({
            ...pref,
            email,
            token
        }));

        // cleanup leak memory
        return () => {
            setState({});
        };
    }, [checkToken, match]);

    ValidatorForm.addValidationRule('isPassMatch', (value) => {
        if (value !== state.password) {
            return false;
        }
        return true;
    });

    return (
        <>
            <div className="container-auth">
                <Container className="container-form">
                    <Row className="justify-content-between">
                        <Col lg="5" md="5" sm="12" xs="12">
                            <div className="container-ilustration bg-transparent">
                                <img
                                    alt="signup"
                                    src={IlCreatePass}
                                    className="text-hallo d-none d-md-block"
                                />
                            </div>
                        </Col>
                        <Col lg="6" md="6" sm="12" xs="12">
                            <h4 className="text-hallo mb-3">
                                CREATE YOUR PASSWORD
                            </h4>
                            <p className="text-desc mb-4">
                                Type your new password and confirm it to reset
                                your password
                            </p>
                            {loading ? (
                                <div className="d-flex align-items-center justify-content-center">
                                    <CircularProgress />
                                </div>
                            ) : !error?.success ? (
                                <Alert severity="error">
                                    {error?.message}{' '}
                                    <strong>
                                        <Link to="/forgot_password">
                                            Try again
                                        </Link>
                                    </strong>
                                </Alert>
                            ) : (
                                <ValidatorForm
                                    onSubmit={handleSubmit}
                                    className="form"
                                >
                                    <label htmlFor="password" className="label">
                                        New Password
                                    </label>
                                    <TextValidator
                                        variant="outlined"
                                        placeholder="Password"
                                        onChange={handleChange}
                                        name="password"
                                        id="password"
                                        value={state.password}
                                        validators={[
                                            'required',
                                            'minStringLength: 8',
                                            'maxStringLength: 15'
                                        ]}
                                        errorMessages={[
                                            'this field is required',
                                            'Min length password is 8 character',
                                            'Max character for password is 15 character'
                                        ]}
                                        className="w-100 mb-3"
                                        type={
                                            showPass.pass ? 'text' : 'password'
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <img
                                                        alt="password"
                                                        src={IcPass}
                                                    />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() =>
                                                            setshowPass(
                                                                (pref) => ({
                                                                    ...pref,
                                                                    pass: !pref.pass
                                                                })
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            alt="password"
                                                            src={
                                                                showPass.pass
                                                                    ? IcEyeClose
                                                                    : IcEyeOpen
                                                            }
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />
                                    <label
                                        htmlFor="password_confirmation"
                                        className="label"
                                    >
                                        Confirm New Password
                                    </label>
                                    <TextValidator
                                        variant="outlined"
                                        placeholder="Password"
                                        onChange={handleChange}
                                        id="password_confirmation"
                                        name="password_confirmation"
                                        value={state.password_confirmation}
                                        validators={['required', 'isPassMatch']}
                                        errorMessages={[
                                            'this field is required',
                                            'Password not match'
                                        ]}
                                        className="w-100 mb-3"
                                        type={
                                            showPass.confirm
                                                ? 'text'
                                                : 'password'
                                        }
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <img
                                                        alt="password"
                                                        src={IcPass}
                                                    />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        onClick={() =>
                                                            setshowPass(
                                                                (pref) => ({
                                                                    ...pref,
                                                                    confirm:
                                                                        !pref.confirm
                                                                })
                                                            )
                                                        }
                                                    >
                                                        <img
                                                            alt="password"
                                                            src={
                                                                showPass.confirm
                                                                    ? IcEyeClose
                                                                    : IcEyeOpen
                                                            }
                                                        />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }}
                                    />

                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className="mb-4 w-100 text-white font-weight-bold py-2 btn-auth mt-4 btn_buddy"
                                        disabled={submit}
                                    >
                                        {submit ? (
                                            <CircularProgress
                                                size={24}
                                                className="text-white"
                                            />
                                        ) : (
                                            'Reset Password'
                                        )}
                                    </Button>
                                </ValidatorForm>
                            )}
                        </Col>
                    </Row>
                </Container>
            </div>
            <Footer />
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        resetPassword: (params) => dispatch(resetPassword(params)),
        checkTokenReset: (params) => dispatch(checkTokenReset(params))
    };
};

export default connect(null, mapDispatchToProps)(CreatePass);
