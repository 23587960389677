import { Button, IconButton } from '@material-ui/core';
import { CheckCircleOutlineRounded, HighlightOffRounded } from '@material-ui/icons';
import React from 'react';
import { Card } from 'react-bootstrap';

const CardNotif = ({
    img,
    notif,
    time,
    status,
    matched_code,
    handleAccept,
    group_code,
    handleGroup
}) => {
    return (
        <Card className="py-3 d-flex flex-row border-0 radius-8 card-notif px-3 align-items-center position-relative mb-3 justify-content-between">
            <div className="d-flex align-items-center item-notif mr-2">
                <img src={img} alt="img profile" className="img-profile" />
                <p className="message flex-1 ml-2 mb-0">{notif}</p>
            </div>
            {status === 2 && matched_code !== '-' ? (
                <Button
                    size="small"
                    disableElevation
                    variant="contained"
                    color="primary"
                    className="text-white"
                    onClick={() => handleAccept(matched_code)}
                >
                    Accept
                </Button>
            ) : status === 3 && group_code !== '-' ? (
                <div>
                    <IconButton
                        size="medium"
                        disableElevation
                        variant="contained"
                        color="default"
                        className="text-white"
                        onClick={() => handleGroup(group_code, 0)}
                    >
                        <HighlightOffRounded fontSize="medium" color="error" />
                    </IconButton>
                    <IconButton
                        size="medium"
                        disableElevation
                        variant="contained"
                        color="primary"
                        className="text-white"
                        onClick={() => handleGroup(group_code, 1)}
                    >
                        <CheckCircleOutlineRounded
                            fontSize="medium"
                            color="primary"
                        />
                    </IconButton>
                </div>
            ) : null}
            <p className="time mb-0">{time}</p>
        </Card>
    );
};

export default CardNotif;
