/* eslint-disable react-hooks/exhaustive-deps */
import { Button, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import { IlConfirmEmail } from '../../assets';
import Footer from '../../components/footer/footer';

const CheckEmail = ({ history }) => {
    const [loading, setLoading] = useState(true);

    const checkCondition = async () => {
        const sendForget = await localStorage.getItem('sendForget');
        if (sendForget) {
            await setLoading(!loading);
            localStorage.removeItem('sendForget');
            return;
        } else {
            history.push('/');
            return;
        }
    };

    useEffect(() => {
        document.title = 'BuddyMed | Check Email';
        checkCondition();
    }, []);

    return (
        <>
            <section className="container-auth">
                <Container>
                    <Row className="justify-content-between">
                        <Col lg="5" md="5" sm="12" xs="12">
                            <div className="bg-transparent">
                                <img
                                    alt="signup"
                                    src={IlConfirmEmail}
                                    className="text-hallo w-100"
                                />
                            </div>
                        </Col>
                        <Col
                            lg="6"
                            md="6"
                            sm="12"
                            xs="12"
                            className="d-flex flex-column justify-content-center"
                        >
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <div>
                                    <h4 className="text-hallo mb-3">
                                        Reset Password
                                    </h4>
                                    <p className="text-desc mb-4">
                                        We have sent email instruction to reset
                                        your password.
                                    </p>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        className="mb-4 w-100 text-white font-weight-bold py-2 btn-auth btn_buddy"
                                        href="/"
                                    >
                                        Done
                                    </Button>
                                </div>
                            )}
                        </Col>
                    </Row>
                </Container>
            </section>
            <Footer />
        </>
    );
};

export default CheckEmail;
