import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import {
    IcHome,
    IcHomeActive,
    IcMatched,
    IcMatchedActive,
    IcProfile,
    IcProfileActive
} from '../../assets';

const MobileNavigation = ({ user }) => {
    const [path, setPath] = useState('');

    useEffect(() => {
        const path = window.location.pathname;
        setPath(path);
    }, []);

    return (
        <div className="position-fixed menu-mobile border py-2">
            <Container className="d-flex align-items-center justify-content-between px-4">
                <Link
                    to={`/profile/${user?.username}`}
                    className="d-flex flex-column align-items-center justify-content-center item-menu"
                >
                    <img
                        alt="icon menu"
                        src={
                            path === `${`/profile/${user?.username}`}`
                                ? IcProfileActive
                                : IcProfile
                        }
                    />
                    <p
                        className={`m-0 ${
                            path === '/profile/' + user?.username
                                ? 'active'
                                : ''
                        }`}
                    >
                        Profile
                    </p>
                </Link>
                <Link
                    to="/"
                    className="d-flex flex-column align-items-center justify-content-center item-menu"
                >
                    <img
                        alt="icon menu"
                        src={path === '/' ? IcHomeActive : IcHome}
                    />
                    <p className={`m-0 ${path === '/' ? 'active' : ''}`}>
                        Home
                    </p>
                </Link>

                <Link
                    to="/friends"
                    className="d-flex flex-column align-items-center justify-content-center item-menu "
                >
                    <img
                        alt="icon menu"
                        src={path === '/friends' ? IcMatchedActive : IcMatched}
                    />
                    <p className={`m-0 ${path === '/friends' ? 'active' : ''}`}>
                        Friends
                    </p>
                </Link>
            </Container>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.user.user
    };
};

export default connect(mapStateToProps, null)(MobileNavigation);
