import { Button, Card } from '@material-ui/core';
import React from 'react';
import { Link } from 'react-router-dom';

import { IcChat } from '../../assets';

const CardFriend = ({
    name,
    picture,
    isRecent,
    isFriend,
    student_status,
    university,
    country,
    toChat,
    cancel,
    accept,
    to,
    isMatched,
    fromUser
}) => {
    return (
        <Card
            className={`radius-8 d-flex align-items-center card-friend p-3 mb-3 mt-1`}
            elevation={0}
        >
            <Link
                to={to}
                className="container-profile text-decoration-none text-muted"
            >
                <img src={picture} alt={name} className="img-profile" />
            </Link>
            <div
                className={`d-flex w-100 justify-content-between container-content align-items-center flex-1${
                    isFriend ? 'match-home' : ''
                }`}
            >
                <div className={`user-info ${isFriend ? 'w-100' : ''}`}>
                    <Link to={to} className="text-decoration-none">
                        <p className="font-weight-bold m-0 name elipse-2">
                            {name}
                        </p>
                    </Link>
                    <p className="m-0 subtext">{student_status}</p>
                    {university || country ? (
                        <p className="m-0 subtext">{`${university} | ${country}`}</p>
                    ) : null}
                </div>
                {isFriend ? (
                    <div className="d-flex align-items-center justify-content-center">
                        <Link to={toChat}>
                            <button className="radius-8 shadow-none text-white btn-card btn-chat ml-1">
                                <img src={IcChat} alt="icon chat" />
                            </button>
                        </Link>
                    </div>
                ) : isRecent ? (
                    <div className="d-flex justify-content-center">
                        <Link to={toChat}>
                            <button className="radius-8 shadow-none text-white btn-card btn-chat mx-2">
                                <img src={IcChat} alt="icon chat" />
                            </button>
                        </Link>
                        {fromUser ? null : isMatched ? (
                            <Button
                                variant="outlined"
                                className="radius-8 shadow-none border-error text-error btn-outline-error btn-card-friend ml-1"
                                onClick={cancel}
                            >
                                Cancel
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                className="radius-8 shadow-none text-white btn-card-friend ml-1"
                                color="primary"
                                onClick={accept}
                            >
                                Match
                            </Button>
                        )}
                    </div>
                ) : (
                    <Button
                        variant="contained"
                        className="radius-8 shadow-none text-white btn-card-friend ml-1"
                        onClick={accept}
                        color="primary"
                    >
                        Accept
                    </Button>
                )}
            </div>
        </Card>
    );
};

export default CardFriend;
