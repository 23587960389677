import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { IcBrand, IcCallOrange, IcMailOrange } from '../../assets';
import { getFooter } from '../../store/actions/footerAction';

const Footer = ({ noMargin, getFooter }) => {
    const [footer, setFooter] = useState(null);

    useEffect(() => {
        getFooter().then((res) => setFooter(res?.value));
    }, [getFooter]);

    return (
        <footer className={`footer-budy ${noMargin ? '' : 'mt-100'}  py-3`}>
            <Container>
                <Row>
                    <Col
                        xs="12"
                        sm="12"
                        md="10"
                        lg="10"
                        xl="10"
                        className="d-flex align-items-center"
                    >
                        <Link to="/" className="text-decoration-none">
                            <figure className="m-0">
                                <img
                                    src={IcBrand}
                                    className="buddy-brand"
                                    alt="icon-brand"
                                />
                            </figure>
                        </Link>
                        <p className="m-0 about-app ml-3 text-white">
                            {footer?.about_text}
                        </p>
                    </Col>
                    <Col xs="12" sm="12" md="12" lg="12">
                        <div className="border-top my-2" />
                        <div className="d-flex align-items-center justify-content-between">
                            <p className="m-0 copy text-white">
                                &copy; 2021 BuddyMed. All rights reserved.
                            </p>
                            <div className="d-flex align-items-center justify-content-between">
                                {footer?.phone ? (
                                    <a
                                        target="_blank"
                                        href={footer?.phone}
                                        className="item-footer-link d-block d-flex align-items-center justify-content-center"
                                    >
                                        <img
                                            src={IcCallOrange}
                                            alt="icon call"
                                            width="20px"
                                        />
                                    </a>
                                ) : null}
                                {footer?.email ? (
                                    <a
                                        target="_blank"
                                        href={footer?.email}
                                        className="item-footer-link d-block d-flex align-items-center justify-content-center ml-2"
                                    >
                                        <img
                                            src={IcMailOrange}
                                            alt="icon mail"
                                            width="20px"
                                        />
                                    </a>
                                ) : null}
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getFooter: () => dispatch(getFooter())
    };
};

export default connect(null, mapDispatchToProps)(Footer);
