import { createBrowserHistory } from 'history';
import React from 'react';
import { Route, Switch } from 'react-router-dom';

import {
    Chat,
    CheckEmail,
    CompleteProfile,
    ConfirmEmail,
    CreatePass,
    EditPassword,
    EditProfile,
    FindBuddy,
    Forgot,
    Friends,
    GroupChat,
    Home,
    Kebijakan,
    Login,
    NotFound,
    Notification,
    Profile,
    Signup,
    VerifyEmail,
} from '../pages';
import LiveStreaming from '../pages/Conference/LiveStreaming';
import PrivateRoutes from '../utils/PrivateRoutes';

export const history = createBrowserHistory();

const Routes = () => {
    return (
        <Switch>
            <PrivateRoutes.UserVerifiedCompleted
                exact
                path="/"
                component={Home}
            />
            <PrivateRoutes.UserVerifiedCompleted
                path="/find_buddy"
                component={FindBuddy}
            />
            <PrivateRoutes.UserVerifiedCompleted
                path="/friends"
                component={Friends}
            />
            <PrivateRoutes.UserVerifiedCompleted
                path="/conference/live/:meet_number/:meet_pass"
                component={LiveStreaming}
            />
            <PrivateRoutes.UserVerifiedCompleted
                path="/chat/:user_code"
                component={Chat}
            />
            <PrivateRoutes.UserVerifiedCompleted
                path="/group/:user_code"
                component={GroupChat}
            />
            <PrivateRoutes.UserVerifiedCompleted
                path="/profile/:username"
                component={Profile}
            />
            <PrivateRoutes.UserVerifiedCompleted
                path="/edit_profile"
                component={EditProfile}
            />
            <PrivateRoutes.UserVerifiedCompleted
                path="/edit_password"
                component={EditPassword}
            />
            <PrivateRoutes.UserVerifiedCompleted
                path="/notification"
                component={Notification}
            />
            <PrivateRoutes.UserNotCompleted
                path="/complete_profile"
                component={CompleteProfile}
            />
            <PrivateRoutes.UserLogin
                exact
                path="/confirm_email"
                component={ConfirmEmail}
            />
            <Route
                exact
                path="/verify_email/:email/:token/:expires"
                component={VerifyEmail}
            />
            <PrivateRoutes.UserNotLogin path="/login" component={Login} />
            <PrivateRoutes.UserNotLogin path="/signup" component={Signup} />
            <PrivateRoutes.UserNotLogin
                exact
                path="/forgot_password"
                component={Forgot}
            />
            <Route exact path="/check_email" component={CheckEmail} />
            <Route
                exact
                path="/reset_password/:token/:email"
                component={CreatePass}
            />
            <Route exact path="/kebijakan" component={Kebijakan} />
            <Route component={NotFound} />
        </Switch>
    );
};

export default Routes;
