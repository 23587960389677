import { Button, CircularProgress } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ValidatorForm } from 'react-material-ui-form-validator';
import Select from 'react-select';
import introJs from "intro.js";

const CardInputTopic = ({
    data,
    handleBack,
    dataLearnType,
    dataHobby,
    dataMusicType,
    dataMovieGenre,
    handleChangeLearn,
    handleChangeHobby,
    handleChangeMusic,
    handleChangeMovie,
    submit,
    handleSubmit
}) => {
    console.log('dataHobby', dataHobby);

    useEffect(() => {
        const tourString = window.localStorage.getItem("tour");
        const tour = tourString ? JSON.parse(tourString) : {};
        const email = window.localStorage.getItem("email");
        const myTour = tour[email];

        if (!myTour || !Object.keys(myTour).length) {
            window.localStorage.setItem("tour", JSON.stringify({
                ...tour,
                [email] :  {
                    profile: false,
                    location: false,
                    topic: true
                }
            }));
        }

        const intro = introJs();

        intro.setOptions({
            steps: [
                {
                    element: document.querySelector('[data-step="2"]'),
                    title: 'Step 3 (Topic)',
                    intro: "This form is for your topic information",
                    position: "top",
                    scrollTo: "element"
                },
                {
                    element: document.querySelector('[data-step="8"]'),
                    title: 'Step 3 (Topic)',
                    intro: "Fill in your topic information",
                    position: "top",
                    scrollTo: "element"
                },
                {
                    element: document.querySelector('[data-step="9"]'),
                    title: 'Step 3 (Topic)',
                    intro: "Press back if you mistakenly fill in your location information",
                    position: "top",
                    scrollTo: "element"
                },
                {
                    element: document.querySelector('[data-step="10"]'),
                    title: 'Step 3 (Topic)',
                    intro: "Press finish to complete all of your profile",
                    position: "top",
                    scrollTo: "element"
                }
            ]
        });

        if (tour[email].topic) {
            intro.start();
        }

        return () => {
            if (tour[email].topic) {
                intro.exit();
            }
        }
    }, [])

    return (
        <ValidatorForm onSubmit={handleSubmit} className="form">
            <Row data-step="8">
                <Col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label className="label">Type of Learner</label>
                    <Select
                        isClearable
                        isSearchable
                        name="learner_type"
                        options={dataLearnType}
                        value={data.learner_type_val}
                        onChange={(e) => handleChangeLearn(e?.lt_code || '')}
                        placeholder="Type of Learner"
                        className="w-100"
                        getOptionValue={(item) => item.lt_code}
                        getOptionLabel={(item) => item.lt_name}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#fff',
                                primary: '#F09856'
                            }
                        })}
                    />
                    <p className="mb-3 mt-1 text-helper">
                        if you don't know it yet, please{' '}
                        <a
                            href="http://www.educationplanner.org/students/self-assessments/learning-styles-quiz.shtml"
                            rel="noreferrer"
                            target="_blank"
                            className="text-primaryy font-weight-bold"
                        >
                            check this out
                        </a>
                    </p>
                </Col>
                <Col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label className="label">Hobbies</label>
                    <Select
                        isClearable
                        isSearchable
                        isMulti
                        name="hobbies"
                        options={dataHobby}
                        defaultValue={data.hobbies_val}
                        onChange={(e) => handleChangeHobby(e || '')}
                        placeholder="Hobbies"
                        className="w-100 mb-3"
                        getOptionValue={(item) => item.hobbies_code}
                        getOptionLabel={(item) => item.hobbies_name}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#fff',
                                primary: '#f09856'
                            }
                        })}
                    />
                </Col>
                {/* <Col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label className="label">Type of Music</label>
                    <Select
                        isClearable
                        isSearchable
                        name="music_type"
                        options={dataMusicType}
                        value={data.music_type_val}
                        onChange={(e) => handleChangeMusic(e?.music_code || '')}
                        placeholder="Type of Music"
                        className="w-100 mb-3"
                        getOptionValue={(item) => item.music_code}
                        getOptionLabel={(item) => item.music_name}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#fff',
                                primary: '#3FC8D4'
                            }
                        })}
                    />
                </Col> */}
                <Col xs="12" sm="6" md="6" lg="6" xl="6">
                    <label className="label">Movie Genre</label>
                    <Select
                        isClearable
                        isSearchable
                        isMulti
                        name="movie_genre"
                        options={dataMovieGenre}
                        defaultValue={data.movie_genre_val}
                        onChange={(e) => handleChangeMovie(e || '')}
                        placeholder="Movie Genre"
                        className="w-100 mb-3"
                        getOptionValue={(item) => item.genre_code}
                        getOptionLabel={(item) => item.genre_name}
                        theme={(theme) => ({
                            ...theme,
                            colors: {
                                ...theme.colors,
                                primary25: '#fff',
                                primary: '#f09856'
                            }
                        })}
                    />
                </Col>
            </Row>
            <div className="d-flex mt-5 align-items-center container-btn">
                <Button
                    disableElevation
                    variant="contained"
                    color="default"
                    className="px-5 mr-2 py-2 rounded-silinder btn-half"
                    onClick={handleBack}
                    data-step="9"
                >
                    Back
                </Button>
                <Button
                    variant="contained"
                    disableElevation
                    disabled={submit}
                    className="px-5 m-2 rounded-silinder btn-half text-white btn_buddy"
                    onClick={handleSubmit}
                    data-step="10"
                >
                    {submit ? (
                        <CircularProgress color="#fff" size={24} />
                    ) : (
                        'Finish'
                    )}
                </Button>
            </div>
        </ValidatorForm>
    );
};

export default CardInputTopic;
