import { Button } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';

import { IcBuddy, IcBuddyActive, IcRecent, IcRecentActive, IcWaiting, IcWaitingActive } from '../../assets';
import { CardFriend, CardMessages, LoadingMessage, Sidebar } from '../../components';
import { getListGroup } from '../../store/actions/chatGroupAction';
import {
    acceptMatchFriend,
    addMatchFriend,
    cancelMatchFriend,
    getListRequestMatchFriend,
    getMatchedFriends,
    getRecentMatch,
} from '../../store/actions/matchAction';
import { convertToLocal } from '../../utils/formatDate';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div role="tabpanel" hidden={value !== index} {...other}>
            {value === index ? children : null}
        </div>
    );
}

const Friends = ({
    getMatchedFriends,
    getListRequestMatchFriend,
    cancelMatchFriend,
    getRecentMatch,
    addMatchFriend,
    acceptMatchFriend,
    getListGroup
}) => {
    const [value, setValue] = useState(0);

    const [dataRequest, setDataRequest] = useState(null);
    const [dataFriend, setDataFriend] = useState(null);
    const [dataRecent, setDataRecent] = useState(null);
    const usersCode = localStorage.getItem('users_code');

    const handleCancel = async (matched_code) => {
        await cancelMatchFriend({ matched_code, status: 0 })
            .then(() => getData())
            .catch((error) => error.message);
    };

    const handleAccept = (matched_code) => {
        acceptMatchFriend({ matched_code, status: 1 })
            .then(() => getData())
            .catch((error) => error.message);
    };

    const getData = () => {
        getListGroup().then((res) => setDataRecent(res?.value?.data?.data));
        getMatchedFriends().then((res) => setDataFriend(res?.value?.data));
        getListRequestMatchFriend().then((res) =>
            setDataRequest(
                res?.value?.data?.filter((item) => item.from_user !== usersCode)
            )
        );
        // getRecentMatch().then((res) => setDataRecent(res?.value?.data));
    };

    useEffect(() => {
        document.title = 'BuddyMed | Friends';
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Sidebar>
                <div className="mt-3">
                    <Row>
                        <Col xs="12" sm="12" md="12" lg="12" xl="12">
                            <div className="tab-friends d-flex align-items-center justify-content-between">
                                <Button
                                    variant="contained"
                                    className={`w-100 btn-menu-friend px-0 py-2 ${
                                        value === 0 ? 'active' : ''
                                    }`}
                                    disableElevation
                                    onClick={() => setValue(0)}
                                >
                                    <span className="btn-icon">
                                        <img
                                            src={`${
                                                value === 0
                                                    ? IcRecentActive
                                                    : IcRecent
                                            }`}
                                            alt=""
                                            className="w-100"
                                        />
                                    </span>
                                    <p className="m-0 ml-1">Recent Match</p>
                                </Button>
                                <Button
                                    variant="contained"
                                    className={`w-100 btn-menu-friend px-0 py-2 ${
                                        value === 1 ? 'active' : ''
                                    }`}
                                    disableElevation
                                    onClick={() => setValue(1)}
                                >
                                    <span className="btn-icon">
                                        <img
                                            src={`${
                                                value === 1
                                                    ? IcBuddyActive
                                                    : IcBuddy
                                            }`}
                                            alt=""
                                            className="w-100"
                                        />
                                    </span>
                                    <p className="m-0 ml-1">My Friends</p>
                                </Button>
                                <Button
                                    variant="contained"
                                    className={`w-100 btn-menu-friend px-0 py-2 ${
                                        value === 2 ? 'active' : ''
                                    }`}
                                    disableElevation
                                    onClick={() => setValue(2)}
                                >
                                    <span className="btn-icon">
                                        <img
                                            src={`${
                                                value === 2
                                                    ? IcWaitingActive
                                                    : IcWaiting
                                            }`}
                                            alt=""
                                            className="w-100"
                                        />
                                    </span>
                                    <p className="m-0 ml-1">Requests</p>
                                </Button>
                            </div>
                        </Col>
                        <Col
                            xs="12"
                            sm="12"
                            md="12"
                            lg="12"
                            xl="12"
                            className="mt-4"
                        >
                            <TabPanel value={value} index={0}>
                                {dataRecent?.length === 0 ? (
                                    <p className="text-muted text-center mt-4 text-notfound">
                                        Recent Match is Empty
                                    </p>
                                ) : dataRecent?.length >= 0 ? (
                                    dataRecent?.map((item, idx) => (
                                        <div className="my-3" key={idx + 1}>
                                            <CardMessages
                                                key={idx}
                                                name={item.group_name}
                                                message={
                                                    item.last_message ||
                                                    item.member
                                                }
                                                time={convertToLocal(
                                                    item.created_at,
                                                    'HH:mm'
                                                )}
                                                picture={item.group_pict}
                                                notif={
                                                    item.unread_messages === 0
                                                        ? ''
                                                        : item.unread_messages
                                                }
                                                to={`/group/${item.group_code}`}
                                                isNotif
                                                isRecentMatch
                                            />
                                        </div>
                                    ))
                                ) : (
                                    <LoadingMessage jml={4} />
                                )}
                            </TabPanel>
                            <TabPanel value={value} index={1}>
                                {dataFriend?.length === 0 ? (
                                    <p className="text-muted text-center mt-4 text-notfound">
                                        Matched Buddy is Empty
                                    </p>
                                ) : dataFriend?.length >= 0 ? (
                                    dataFriend?.map((friend, idx) => (
                                        <CardFriend
                                            key={idx + 1}
                                            name={friend.fullname}
                                            picture={friend.profile_pict}
                                            student_status={
                                                friend.academic_year
                                            }
                                            university={friend.university}
                                            country={friend.country}
                                            toChat={`/chat/${friend?.users_code}`}
                                            to={`profile/${friend?.username}`}
                                            cancel={() =>
                                                handleCancel(
                                                    friend?.matched_code
                                                )
                                            }
                                            isFriend
                                        />
                                    ))
                                ) : (
                                    <LoadingMessage jml={4} />
                                )}
                            </TabPanel>
                            <TabPanel value={value} index={2}>
                                {dataRequest?.length === 0 ? (
                                    <p className="text-muted text-center mt-4 text-notfound">
                                        Requests Buddy is Empty
                                    </p>
                                ) : dataRequest?.length > 0 ? (
                                    dataRequest?.map((request, idx) => (
                                        <CardFriend
                                            key={idx + 1}
                                            name={request.fullname}
                                            picture={request.profile_pict}
                                            student_status={
                                                request.academic_year
                                            }
                                            university={request.university}
                                            country={request.country}
                                            toChat={`/chat/${request?.users_code}`}
                                            to={`profile/${request?.username}`}
                                            accept={() =>
                                                handleAccept(
                                                    request.matched_code
                                                )
                                            }
                                        />
                                    ))
                                ) : (
                                    <LoadingMessage jml={4} />
                                )}
                            </TabPanel>
                        </Col>
                    </Row>
                </div>
            </Sidebar>
        </>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMatchedFriends: () => dispatch(getMatchedFriends()),
        getListRequestMatchFriend: () => dispatch(getListRequestMatchFriend()),
        cancelMatchFriend: (params) => dispatch(cancelMatchFriend(params)),
        acceptMatchFriend: (params) => dispatch(acceptMatchFriend(params)),
        getRecentMatch: () => dispatch(getRecentMatch()),
        addMatchFriend: (params) => dispatch(addMatchFriend(params)),
        getListGroup: () => dispatch(getListGroup())
    };
};

export default connect(null, mapDispatchToProps)(Friends);
