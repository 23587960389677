export const setSurfix = (val) => {
    let j = val % 10,
        k = val % 100;
    if (j === 1 && k !== 11) {
        return val + 'st';
    }
    if (j === 2 && k !== 12) {
        return val + 'nd';
    }
    if (j === 3 && k !== 13) {
        return val + 'rd';
    }
    return val + 'th';
};
export const getAcademicYear = (length) => {
    let option = [];
    for (let i = 0; i < length; i++) {
        let val = setSurfix(i + 1);
        option = [...option, { value: val, label: val }];
    }
    return option;
};
